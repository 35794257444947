const React = require("react");
const axios = require("axios");
const urls = require("../env");
const Button = require("../components/button");
const { Link } = require("react-router-dom");
const { getRounds } = require("bcryptjs");

const INITIAL_SHOW_TRAINEES_NUM = 100;
class Records extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gyms: [],
      trainees: [],
      initialTrainees: [],
      searchByGym: "",
      searchByName: "",
      searchByPlan: "",
      showNumber: INITIAL_SHOW_TRAINEES_NUM,
      data: [],
    };
  }
  componentDidMount() {
    this.get_gyms();
    this.get_trainees();
  }

  get_gyms = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
        if (localStorage.getItem("authority") != "manager") {
          this.setState({
            gyms: [
              results.data.gyms.find(gym => gym.id == Number(localStorage.getItem("user_gym_ids"))),
            ],
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  get_trainees = () => {
    if (localStorage.getItem("authority") === "manager") {
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "POST",
        url: `${urls.api_server}/controller/trainees`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(results => {
          if (results.status === 404) {
            console.log("404");
            return;
          }
          this.setState(results["data"]);
          this.setState({ initialTrainees: this.state.trainees });
          this.setState({
            data: [
              {
                月4回プラン: this.state.trainees.filter(
                  trainee => trainee.item_name.indexOf("月4回プラン") != -1
                ).length,
              },
              {
                月8回プラン: this.state.trainees.filter(
                  trainee => trainee.item_name.indexOf("月8回プラン") != -1
                ).length,
              },
              {
                月4回ペアプラン: this.state.trainees.filter(
                  trainee => trainee.item_name.indexOf("月4回ペアプラン") != -1
                ).length,
              },
              {
                月8回ペアプラン: this.state.trainees.filter(
                  trainee => trainee.item_name.indexOf("月8回ペアプラン") != -1
                ).length,
              },
              {
                休塾: this.state.trainees.filter(trainee => trainee.item_name.indexOf("休塾") != -1)
                  .length,
              },
              {
                プラン契約なし: this.state.trainees.filter(
                  trainee => trainee.item_name.indexOf("プラン契約なし") != -1
                ).length,
              },
              { トータル: this.state.trainees.length },
            ],
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${urls.api_server}/controller/gyms/${localStorage.getItem("user_gym_ids")}/trainees`,
        method: "GET",
      })
        .then(results => {
          if (results.status === 404) {
            console.log("404");
            return;
          }
          this.setState(results["data"]);
          this.setState({ initialTrainees: this.state.trainees });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  filterList = () => {
    const { searchByGym, searchByName, searchByPlan } = this.state;
    let searchedTrainees = this.state.initialTrainees;

    if (!!searchByGym && searchByGym != "全店舗") {
      searchedTrainees = searchedTrainees.filter(
        trainee =>
          this.state.gyms.find(
            gym =>
              gym.id ==
              (trainee.gymusers?.length == 0
                ? 0
                : trainee.gymusers == undefined
                ? 0
                : Number(trainee.gymusers[0].gym_id))
          )?.name == searchByGym
      );
    }
    if (searchByName != "") {
      searchedTrainees = searchedTrainees.filter(
        trainee => trainee.name.indexOf(searchByName) != -1
      );
    }
    if (searchByPlan != "") {
      searchedTrainees = searchedTrainees.filter(
        trainee => trainee.item_name.indexOf(searchByPlan) != -1
      );
    }

    this.setState({ trainees: searchedTrainees });
    this.setState({
      data: [
        {
          月4回プラン: this.state.trainees.filter(
            trainee => trainee.item_name.indexOf("月4回プラン") != -1
          ).length,
        },
        {
          月8回プラン: this.state.trainees.filter(
            trainee => trainee.item_name.indexOf("月8回プラン") != -1
          ).length,
        },
        {
          月4回ペアプラン: this.state.trainees.filter(
            trainee => trainee.item_name.indexOf("月4回ペアプラン") != -1
          ).length,
        },
        {
          月8回ペアプラン: this.state.trainees.filter(
            trainee => trainee.item_name.indexOf("月8回ペアプラン") != -1
          ).length,
        },
        {
          休塾: this.state.trainees.filter(trainee => trainee.item_name.indexOf("休塾") != -1)
            .length,
        },
        {
          プラン契約なし: this.state.trainees.filter(
            trainee => trainee.item_name.indexOf("プラン契約なし") != -1
          ).length,
        },
        { トータル: this.state.trainees.length },
      ],
    });
  };

  onPressDeleteBtn = (id, name) => {
    if (localStorage.getItem("authority") != "manager") return alert("権限がありません。");

    if (
      window.confirm(
        `${name} さんのデータを削除しますか？\n「OK」を選択した場合、ユーザーに紐づく予約履歴やトレーニング記録などのすべてのデータが失われます。`
      )
    ) {
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${urls.api_server}/controller/users/${id}`,
        method: "DELETE",
      })
        .then(results => {
          if (results.status === 500) {
            alert(results.data.error);
            return;
          }
          alert(results.data.message, this.get_trainees());
        })
        .catch(error => {
          alert("＊ユーザーの削除が失敗しました。");
          console.log(error);
        });
    } else {
      alert("ユーザーの削除をキャンセルしました。");
    }
  };

  onClickDeleteSubscription = async id => {
    if (window.confirm("本当に削除しますか？")) {
      axios(`${urls.api_server}/controller/subscriptions/${id}/delete`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      })
        .then(results => {
          if (results.status === 404) {
            console.log("404");
            return;
          }
          console.log(results);
          alert("削除が完了しました。");
          return;
        })
        .catch(error => {
          alert("削除が失敗しました。");
          console.log(error);
        });
    }
  };

  onPressMoreBtn = () => {
    this.setState({ showNumber: Infinity });
  };

  render() {
    return (
      <>
        <h2 className="mb-10">ユーザー新規追加</h2>
        <p className="text-center mb-10 -mt-4">※ マネージャーは追加後に権限を変更できます</p>
        <Link to={{ pathname: `/trainee_add` }}>
          <Button name="新規追加" />
        </Link>
        {localStorage.getItem("authority") != "manager" ? null : (
          <>
            <h2 className="mt-12">
              {this.state.searchByGym === "" ? "全塾生" : this.state.searchByGym + "の塾生"}
              のプラン契約状況
            </h2>
            <br />
            <p className="text-center">
              {`
  ${new Date().getFullYear()}/
  ${(new Date().getMonth() + 1).toString().padStart(2, "0")}/
  ${new Date().getDate().toString().padStart(2, "0")}
  `.replace(/\n|\r/g, "")}
              現在
            </p>
            <div className="centering -mt-4">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>月4回プラン</th>
                    <th>月8回プラン</th>
                    <th>月4回ペアプラン</th>
                    <th>月8回ペアプラン</th>
                    <th>休塾</th>
                    <th>プラン契約なし</th>
                    <th>全塾生アカウント数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>件数</th>
                    <td>
                      {this.state.data.length != 0 ? this.state.data[0]["月4回プラン"] : ""}件
                    </td>
                    <td>
                      {this.state.data.length != 0 ? this.state.data[1]["月8回プラン"] : ""}件
                    </td>
                    <td>
                      {this.state.data.length != 0 ? this.state.data[2]["月4回ペアプラン"] : ""}件
                    </td>
                    <td>
                      {this.state.data.length != 0 ? this.state.data[3]["月8回ペアプラン"] : ""}件
                    </td>
                    <td>{this.state.data.length != 0 ? this.state.data[4]["休塾"] : ""}件</td>
                    <td>
                      {this.state.data.length != 0 ? this.state.data[5]["プラン契約なし"] : ""}件
                    </td>
                    <td>{this.state.data.length != 0 ? this.state.data[6]["トータル"] : ""}件</td>
                  </tr>
                  <tr>
                    <th>割合</th>
                    <td>
                      {this.state.data.length != 0
                        ? (
                            (this.state.data[0]["月4回プラン"] / this.state.data[6]["トータル"]) *
                            100
                          ).toFixed(2)
                        : ""}
                      %
                    </td>
                    <td>
                      {this.state.data.length != 0
                        ? (
                            (this.state.data[1]["月8回プラン"] / this.state.data[6]["トータル"]) *
                            100
                          ).toFixed(2)
                        : ""}
                      %
                    </td>
                    <td>
                      {this.state.data.length != 0
                        ? (
                            (this.state.data[2]["月4回ペアプラン"] /
                              this.state.data[6]["トータル"]) *
                            100
                          ).toFixed(2)
                        : ""}
                      %
                    </td>
                    <td>
                      {this.state.data.length != 0
                        ? (
                            (this.state.data[3]["月8回ペアプラン"] /
                              this.state.data[6]["トータル"]) *
                            100
                          ).toFixed(2)
                        : ""}
                      %
                    </td>
                    <td>
                      {this.state.data.length != 0
                        ? Math.round(
                            (this.state.data[4]["休塾"] / this.state.data[6]["トータル"]) * 100,
                            2
                          )
                        : ""}
                      %
                    </td>
                    <td>
                      {this.state.data.length != 0
                        ? (
                            (this.state.data[5]["プラン契約なし"] /
                              this.state.data[6]["トータル"]) *
                            100
                          ).toFixed(2)
                        : ""}
                      %
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        <h2 className="mt-12">塾生の一覧</h2>
        <br />
        {this.renderContents()}
      </>
    );
  }

  renderContents = () => {
    if (localStorage.getItem("user_gym_ids") == null) {
      return <div className="centering">ジムの所属を設定してください。</div>;
    }
    return (
      <>
        {this.renderSearchForm()}
        {this.renderTableOfUserData()}
        {this.renderMoreBtn()}
      </>
    );
  };

  renderSearchForm = () => {
    if (localStorage.getItem("authority") != "manager") return null;

    let { gyms } = this.state;

    return (
      <div className="centering">
        <label>
          店舗
          <select
            className="select-btn"
            onChange={async event => {
              const { value } = event.target;
              await this.setState({ searchByGym: value });
              return this.filterList();
            }}
          >
            <option className="center-text">全店舗</option>
            {gyms.map(gym => (
              <option key={gym.id}>{gym.name}</option>
            ))}
          </select>
        </label>
        <label>
          名前
          <input
            placeholder="片桐 太郎"
            value={this.state.searchByName}
            onChange={async event => {
              const { value } = event.target;
              await this.setState({ searchByName: value });
              return this.filterList();
            }}
          />
        </label>
        　
        <label>
          プラン
          <input
            placeholder="月4回プラン"
            value={this.state.searchByPlan}
            onChange={async event => {
              const { value } = event.target;
              await this.setState({ searchByPlan: value });
              return this.filterList();
            }}
          />
        </label>
      </div>
    );
  };

  renderTraineeRows = trainees => {
    let { gyms } = this.state;

    return trainees.map(trainee => (
      <tr>
        <td className="text-center">{trainee.email}</td>
        <td className="text-center">{trainee.name}</td>
        <td className="text-center">
          {
            gyms.find(
              gym =>
                gym.id ==
                (trainee.gymusers?.length == 0
                  ? 0
                  : trainee.gymusers == undefined
                  ? 0
                  : Number(trainee.gymusers[0].gym_id))
            )?.name
          }
        </td>
        <td className="text-center">{trainee.plan_ticket}</td>
        <td className="text-center">{trainee.omni_ticket}</td>
        <td className="text-center">{trainee.item_name}</td>
        <td className="text-center">
          {trainee.subscription_id === 0 ? (
            ""
          ) : (
            <button
              className="bg-orange-600 w-20 p-3 rounded mx-auto text-white text-center"
              onClick={() => this.onClickDeleteSubscription(trainee.subscription_id)}
            >
              <div className="text-white">解除</div>
            </button>
          )}
        </td>
        <td>
          <Link to={{ pathname: `/trainee_show/${trainee.id}`, aboutProps: { trainee } }}>
            <Button name="詳細 >" type={"secondary"} />
          </Link>
        </td>
        <td>
          <Button
            name="削除する"
            type={"primary"}
            method={() => this.onPressDeleteBtn(trainee.id, trainee.name)}
          />
        </td>
      </tr>
    ));
  };

  renderTableOfUserData = () => {
    let { showNumber, trainees } = this.state;

    if (localStorage.getItem("authority") === "manager") {
      return (
        <table className="table-auto" style={{ position: "relative" }}>
          <thead>
            <tr>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                  width: "10%",
                }}
              >
                {"Eメール"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {"氏名"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {"所属店舗"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {"失効チケ"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {"回数券"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {"登録プラン"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {"解除ボタン"}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {""}
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#565656",
                  color: "white",
                }}
              >
                {""}
              </th>
            </tr>
          </thead>
          <tbody>{this.renderTraineeRows(trainees.slice(0, showNumber))}</tbody>
        </table>
      );
    }

    return (
      <table style={{ position: "relative" }}>
        <thead>
          <tr>
            {/* <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
                width: "20%",
              }}
            >
              {"アクティブ会員の継続月数"}
            </th> */}
            <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
                width: "20%",
              }}
            >
              {"氏名"}
            </th>
            <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
              }}
            >
              {"月末失効チケット"}
            </th>
            <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
              }}
            >
              {"無期限のチケット"}
            </th>
            <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
              }}
            >
              {"登録プラン"}
            </th>
            <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
              }}
            >
              {"ステータス"}
            </th>
            <th
              style={{
                position: "sticky",
                top: 0,
                background: "#565656",
                color: "white",
              }}
            >
              {""}
            </th>
          </tr>
        </thead>
        <tbody>
          {trainees.map(trainee => (
            <tr
              key={trainee.id}
              style={{
                color:
                  // new Date().getTime() - new Date(trainee.created_at).getTime() < 2678400000
                  //   ? "red"
                  //   : trainee.plan_ticket + trainee.omni_ticket === 0 &&
                  //     trainee.item_name === "プラン契約なし"
                  //   ? "gray"
                  //   : "black",
                  (trainee.plan_ticket + trainee.omni_ticket > 0 &&
                    trainee.item_name != "プラン契約なし") ||
                  (trainee.omni_ticket > 0 && trainee.item_name == "プラン契約なし") ||
                  trainee.item_name == "月4回プラン" ||
                  trainee.item_name == "月8回プラン" ||
                  trainee.item_name == "月4回ペアプラン" ||
                  trainee.item_name == "月8回ペアプラン"
                    ? "black"
                    : new Date().getTime() - new Date(trainee.created_at).getTime() < 2678400000
                    ? "red"
                    : "gray",
              }}
            >
              {/* <td className="text-center">
                {(trainee.plan_ticket + trainee.omni_ticket > 0 &&
                  trainee.item_name != "プラン契約なし") ||
                (trainee.omni_ticket > 0 && trainee.item_name == "プラン契約なし") ||
                trainee.item_name == "月4回プラン" ||
                trainee.item_name == "月8回プラン" ||
                trainee.item_name == "月4回ペアプラン" ||
                trainee.item_name == "月8回ペアプラン"
                  ? trainee.continued
                  : "-"}
              </td> */}
              <td className="text-center">{trainee.name}</td>
              <td className="text-center">{trainee.plan_ticket}</td>
              <td className="text-center">{trainee.omni_ticket}</td>
              <td className="text-center">{trainee.item_name}</td>
              <td className="text-center">
                {trainee.subscription_id === 0 ? (
                  ""
                ) : (
                  <button
                    className="bg-orange-600 w-20 p-3 rounded mx-auto text-white text-center"
                    onClick={() => this.onClickDeleteSubscription(trainee.subscription_id)}
                  >
                    <div className="text-white">解除</div>
                  </button>
                )}
              </td>
              <td>
                <Link to={{ pathname: `/trainee_show/${trainee.id}`, aboutProps: { trainee } }}>
                  <Button name="詳細 >" type={"secondary"} />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderMoreBtn = () => {
    const { showNumber, trainees } = this.state;
    if (
      localStorage.getItem("authority") != "manager" ||
      showNumber == Infinity ||
      showNumber >= trainees.length
    )
      return null;
    return (
      <div style={{ width: "100%", marginTop: 40, alignItems: "center" }}>
        <Button name="もっと見る" method={this.onPressMoreBtn} />
      </div>
    );
  };
}
module.exports = Records;
