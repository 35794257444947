const React = require("react");
const Input = require("./input");
const Button = require("./button");
class Form extends React.Component {
  render() {
    const inputs = this.props.form.inputs.map((input) =>
      input.name === "textarea" ? (
        <p key={input.name}>
          <br />
          <label>{input.label}</label>
          <br />
          <textarea
            cols="40"
            row="20"
            placeholder={input.placeholder}
          ></textarea>
          <br />
        </p>
      ) : (
        <Input
          key={input.name}
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
        />
      )
    );
    return (
      <>
        <h2>{this.props.form.title}</h2>
        <div className="form-card">
          <form>
            {inputs}
            <br />
            <Button
              method={this.props.form.button.method}
              name={this.props.form.button.value}
            />
          </form>
        </div>
      </>
    );
  }
}

module.exports = Form;
