const React = require("react");
const axios = require("axios");
const urls = require("../../env");
const Button = require("../../components/button");
const { Link } = require("react-router-dom");

class GymFeaturesIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gymFeaturesData: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = () => {
    const gym_id = this.props.match.params.id;
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/controller/gyms/${gym_id}/gym_features`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status != 200) {
          return;
        }
        this.setState({ gymFeaturesData: results.data.gym_features });
        return;
      })
      .catch(error => {
        return;
      });
  };

  render() {
    const gym_id = this.props.match.params.id;
    const { gymFeaturesData } = this.state;
    return (
      <>
        <h2 className="mt-12">おすすめポイントの一覧</h2>

        <table className="w-2/3">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>{"ID"}</th>
              <th style={{ textAlign: "left" }}>{"タイトル"}</th>
              <th style={{ textAlign: "left" }}>{"スラグ"}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {gymFeaturesData.map(gf => (
              <tr key={gf.id}>
                <td>{gf.id}</td>
                <td>{gf.title}</td>
                <td>{gf.slug}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/gym_features_edit/${gf.id}`,
                    }}
                  >
                    <Button name="編集する" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <br />
        <Link
          to={{
            pathname: `/gyms/${gym_id}/gym_features_new`,
          }}
        >
          <Button name="新規追加する" />
        </Link>
      </>
    );
  }
}

module.exports = GymFeaturesIndex;
