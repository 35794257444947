const React = require("react");
const { Redirect } = require("react-router-dom");
const Form = require("../components/form");
const User = require("../axios");
const user = new User();
const { Link } = require("react-router-dom");
const io = require("socket.io-client");
// io.connect("https://socket.katagirijuku.work");

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      login: false,
    };
  }
  componentDidMount() {
    this.setState({ login: false });
    localStorage.removeItem("reload");
  }

  renderRedirect = () => {
    if (this.state.login) {
      return <Redirect to="/" />;
    }
  };

  render() {
    return (
      <div className="w-64 mx-auto">
        {this.renderRedirect()}
        <h2 className="mb-10">ログイン</h2>
        <form>
          <div className="mt-4">
            <label>
              メールアドレス
              <input
                className="w-auto m-0"
                value={this.state.email}
                placeholder="test@example.com"
                onChange={e => this.onChangeEmail(e)}
              />
            </label>
          </div>
          <div className="mt-4">
            <label>
              パスワード
              <input
                className="w-auto"
                value={this.state.password}
                onChange={e => this.onChangePassword(e)}
              />
            </label>
          </div>
          <div className="mt-12 flex justify-center">
            <button className="btn" onClick={this.execute_login}>
              ログイン
            </button>
          </div>
        </form>
      </div>
    );
  }

  onChangeEmail = async e => {
    this.setState({ email: e.target.value });
  };

  onChangePassword = async e => {
    this.setState({ password: e.target.value });
  };

  execute_login = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    // console.log("login実行");
    // const email = "taro@example.com";
    // const password = "password";
    const isLoggedIn = await user.login(email, password);
    await this.setState({ login: isLoggedIn });
    console.log(this.state.login);
    // setTimeout(() => window.location.reload(), 100);
    window.open("https://katagirijuku.work", "_self");
  };
}

module.exports = Login;
