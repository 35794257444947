const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Link } = require("react-router-dom");

class ECProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    return (
      <div className="w-4/5 mx-auto mb-10">
        <h2 className="my-16 text-2xl">編集可能な商品一覧</h2>
        {this.state.products.map((p) => (
          <div class="flex justify-center mb-4">
            <Link to={{ pathname: `/ec/product/${p.id}/edit` }}>
              <button class="w-128 py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none edit-btn block">
                {p.name}
                <span class="block text-xs font-hairline font-mono italic">
                  {"/" + p.slug}
                </span>
              </button>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  fetchData = async () => {
    axios(`${urls.api_server}/ec/items`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status != 200) {
          alert("何らかの不具合が生じています。");
          return;
        }
        this.setState({ products: results.data.items });
      })
      .catch((error) => {
        console.log(error);
        alert("何らかの不具合が生じています。");
      });
  };
}

module.exports = ECProducts;
