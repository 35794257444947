const React = require("react");
const { Link } = require("react-router-dom");

class CvRoutes extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mx-auto mb-10">
        <h2 className="my-16 text-2xl">塾生・流入経路の確認</h2>
        <div class="flex justify-center">
          <Link to={{ pathname: "/cv_routes/all" }}>
            <button class="w-full py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              全店舗
              <span class="block text-xs font-hairline font-mono italic">the whole gyms</span>
            </button>
          </Link>
        </div>
        <div class="flex justify-center mt-8">
          <Link to={{ pathname: "/cv_routes/gyms" }}>
            <button class="w-full py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              個別店舗
              <span class="block text-xs font-hairline font-mono italic">individual gym</span>
            </button>
          </Link>
        </div>
        <h2 className="my-16 text-2xl">コンバージョンの確認</h2>
        <div class="flex justify-center">
          <Link to={{ pathname: "/advertisements/" }}>
            <button class="w-full py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              全店舗のコンバージョン経路
            </button>
          </Link>
        </div>
        <div class="flex justify-center mt-8">
          <Link to={{ pathname: "/gyms/advertisements/" }}>
            <button class="w-full py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              各店舗のコンバージョン経路
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

module.exports = CvRoutes;
