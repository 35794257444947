const React = require("react");

class Notifications extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <h2>全体連絡</h2>
        <div className="form-card">
          <div>チャット機能削除により機能を停止しています。</div>
        </div>
      </>
    );
  }
}

module.exports = Notifications;
