const React = require('react');

class Input extends React.Component {
    render() {
        return (
            <div className="mt-4">
                <label>{this.props.label}
                <input placeholder={this.props.placeholder} name={this.props.name} />
                </label>
            </div>
        )
    }
};

module.exports = Input;