const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Link } = require("react-router-dom");

const returnModifiedDate = (date) => {
  if (date == "") return "";
  const dates = date.split("-");
  return `${dates[0]}年${dates[1]}月${dates[2]}日`;
};

class TraineeMenus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
    };
  }

  componentDidMount() {
    this.fetchMenus();
  }

  render() {
    let traineeId;
    if (this.props.location.aboutProps != undefined) {
      traineeId = this.props.location.aboutProps.traineeId
      localStorage.setItem("traineeId", traineeId);
    }
    traineeId = localStorage.getItem("traineeId")
    return (
      <>
        <h2>トレーニング記録</h2>
        <div className="w-64 mx-auto mt-8">
          {this.state.menus.map((menu) => (
            <Link
              to={{
                pathname: `/trainee_show/${traineeId}/trainee_menu/${menu.id}`,
              }}
            >
              <button
                className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-blue-100 rounded shadow-md"
                onClick={() => {
                  console.log("あらら");
                }}
              >
                <div className="ml-2">{returnModifiedDate(menu.date)}</div>
                <div className="text-xs text-gray-600 mr-1">{`担当：${menu.trainer}`}</div>
              </button>
            </Link>
          ))}
        </div>
      </>
    );
  }

  fetchMenus = async () => {
    const userId = this.props.match.params.id;
    axios(`${urls.api_server}/api/users/${userId}/training_records`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%");
        console.log(results);
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        this.setState({ menus: results.data.training_records });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
module.exports = TraineeMenus;
