const React = require("react");
const axios = require("axios");
const urls = require("../env");
const Button = require("../components/button");
const { Link } = require("react-router-dom");

class ReviewsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewDatas: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = () => {
    const gym_id = this.props.match.params.id;
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/controller/gyms/${gym_id}/reviews`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status != 200) {
          return;
        }
        this.setState({ reviewDatas: results.data.reviews });
        return;
      })
      .catch(error => {
        return;
      });
  };

  onClickDelete = id => {
    if (window.confirm("本当に削除しますか？")) {
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "DELETE",
        url: `${urls.api_server}/controller/reviews/${id}/delete`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(results => {
          if (results.status != 204) {
            alert(
              "削除が失敗しました。もう一度お試しいただきうまくいかなければ、開発者にご連絡ください。"
            );
            return;
          }
          alert("削除が完了しました。");
          return;
        })
        .catch(error => {
          return;
        });
    }
  };

  render() {
    const gym_id = this.props.match.params.id;
    const { reviewDatas } = this.state;
    return (
      <>
        <h2 className="mt-12">口コミ・評判の一覧</h2>

        <table className="w-6/7">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>{"ID"}</th>
              <th style={{ textAlign: "left" }}>{"テキスト"}</th>
              <th style={{ textAlign: "left" }}>{"表示順番"}</th>
              <th style={{ textAlign: "left" }}>{"名前"}</th>
              <th style={{ textAlign: "left" }}>{"年代"}</th>
              <th>編集</th>
              <th>削除</th>
            </tr>
          </thead>
          <tbody>
            {reviewDatas.map(reviewData => (
              <tr key={reviewData.id}>
                <td>{reviewData.id}</td>
                <td>{reviewData.text}</td>
                <td>{reviewData.order}</td>
                <td>{reviewData.name}</td>
                <td>{reviewData.age}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/reviews_edit/${reviewData.id}`,
                    }}
                  >
                    <Button name="編集する" />
                  </Link>
                </td>
                <td>
                  {localStorage.getItem("authority") === "manager" ||
                  localStorage.getItem("authority") === "owner" ? (
                    <Button name="削除" method={() => this.onClickDelete(reviewData.id)} />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <br />
        <Link
          to={{
            pathname: `/gyms/${gym_id}/reviews_new`,
          }}
        >
          <Button name="新規追加する" />
        </Link>
      </>
    );
  }
}

module.exports = ReviewsIndex;
