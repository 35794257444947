const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Link } = require("react-router-dom");

class Blogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      initialBlogs: []
    };
  }
  componentDidMount() {
    this.get_blogs();
    this.setState({blogs: this.state.initialBlogs})
  }
  render() {
    let blogs = localStorage.getItem('authority')==="manager" ? this.state.blogs : this.state.blogs.filter(blog=>String(blog.author) ==localStorage.getItem("user_id"));
    return (
      <>
        <h2 className="mb-10">記事の新規追加</h2>
        <div className="centering">
          <Link
            to={{
              pathname: `/blog_add`,
              aboutProps: {
                slugs: this.state.slugs,
              },
            }}
            className="btn"
          >
            新規追加
          </Link>
        </div>
        <h2 className="mt-12">記事の一覧</h2><br />
        <div className="centering">
          <input
            className="blog-search"
            type="text"
            placeholder="検索キーワードを入力してエンターキーを押しましょう"
            onKeyPress={event => {
              if (event.keyCode === 13 || event.which === 13 || event.keyCode === 52 || event.which === 52) {
                console.log(event.target.value)
                return this.filterList(event.target.value)
              }
            }}
            onKeyUp={this.refleshCheck}
          />
          　
          <select
            className="blog-category blog-select-btn"
            defaultValue=""
            onChange={event => {
              console.log(event.target.value)
              return this.filterListByCategory(event.target.value)
            }}
          >
            <option value="-" className="text-center">-</option>
            <option value="workout" className="text-center">workout</option>
            <option value="diet" className="text-center">diet</option>
            <option value="personal-training" className="text-center">personal-training</option>
            <option value="stretch" className="text-center">stretch</option>
            <option value="health" className="text-center">health</option>
          </select>
        </div>
        <table className="blog-table">
          <thead>
            <tr>
              <th>{"ID"}</th>
              <th>{"記事タイトル"}</th>
              <th>{"カテゴリ"}</th>
              <th>{"スラグ"}</th>
              <th>{""}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog,index) => (
              <tr key={blog.id} className={index%2==0?"odd-tr":""}>
                <td>{blog.id}</td>
                <td><a href={`https://media.katagirijuku.jp/${blog.category}/${blog.slug}`} className=" items-star" target="_blank">{blog.title}</a></td>
                <td>{blog.category}</td>
                <td>{blog.slug}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/blog_edit/${blog.id}`,
                      aboutProps: {
                        blog: blog,
                        slugs: this.state.slugs
                      },
                    }}
                  >
                    <button className="edit-btn">編集</button>
                  </Link>
                </td>
                <td><button className="delete-btn">削除</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
  get_blogs = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass
      },
      method: "POST",
      url: `${urls.api_server}/api/blogs`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState(results["data"]);
        console.log(this.state);
        this.setState({initialBlogs: this.state.blogs});
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterList = value => {
    const searchedBlogs = this.state.blogs.filter(blog => blog.title.toString().indexOf(value) >= 0 || blog.content.toString().indexOf(value) >= 0);
    this.setState({blogs: searchedBlogs});
  }

  filterListByCategory = async value => {
    await this.setState({blogs: this.state.initialBlogs});
    if (value.length==1) return;
    const searchedBlogs = this.state.blogs.filter(blog => blog.category == value);
    this.setState({blogs: searchedBlogs});
  }

  refleshCheck = e => {
    if (e.target.value.length==0) {
      this.setState({blogs: this.state.initialBlogs});
    }
  }
}

module.exports = Blogs;
