const React = require("react");
const { Link } = require("react-router-dom");
const { isMobile } = require("mobile-device-detect");
const Panel = require("../components/panel");

class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (localStorage.getItem("reload") === null) this.reload();
  }
  reload() {
    localStorage.setItem("reload", "done");
    window.location.reload();
  }
  render() {
    return (
      <div>
        <div className="lg:flex mr-4">
          {/* {(() => {
            if (
              !isMobile &&
              !(localStorage.getItem("authority") === "trainer") &&
              !(localStorage.getItem("authority") === "owner")
            )
              return (
                <Link to="/management">
                  <Panel
                    title="総合管理"
                    description="各種経営情報"
                    image="image/settings.png"
                  />
                </Link>
              );
          })()} */}
          <Link to="/records">
            <Panel
              title="塾生の情報管理"
              description="塾生の閲覧・編集"
              image="image/records.png"
            />
          </Link>
          <Link to="/reservations">
            <Panel
              title="塾生の予約一覧"
              description="塾生の予約を確認"
              image="image/reservations.png"
            />
          </Link>
          {(() => {
            if (!isMobile && !(localStorage.getItem("authority") === "trainer"))
              return (
                <Link to="/trainers">
                  <Panel
                    title="トレーナー名簿"
                    description="トレーナーの一覧確認"
                    image="image/trainers.png"
                  />
                </Link>
              );
          })()}
          {/* {(() => {
            if (!isMobile && !(localStorage.getItem("authority") === "trainer"))
              return (
                <Link to="/trainer_add">
                  <Panel
                    title="トレーナー登録"
                    description="トレーナーの新規登録"
                    image="image/registrations.png"
                  />
                </Link>
              );
          })()} */}
          <Link to="/shifts">
            <Panel
              title="予約のブロック"
              description="予約不可の時間設定"
              image="image/shifts.png"
            />
          </Link>
          {(() => {
            if (!isMobile)
              return (
                <Link to="/payments">
                  <Panel
                    title="塾生の決済管理"
                    description="塾生の決済の確認"
                    image="image/payments.png"
                  />
                </Link>
              );
          })()}
          {(() => {
            if (
              !isMobile &&
              !(localStorage.getItem("authority") === "trainer") &&
              !(localStorage.getItem("authority") === "owner")
            )
              return (
                <Link to="/cv_routes">
                  <Panel
                    title="塾生の流入経路"
                    description="塾生の流入経路"
                    image="image/inflow.png"
                  />
                </Link>
              );
          })()}
        </div>
        <div className="lg:flex mr-4 x_scroll">
          <Link to="/trials">
            <Panel title="初回体験の管理" description="初回体験の管理" image="image/trials.png" />
          </Link>
          {(() => {
            if (!isMobile)
              return (
                <Link to="/sales">
                  <Panel
                    title="売り上げの確認"
                    description="売り上げデータの確認"
                    image="image/sales.png"
                  />
                </Link>
              );
          })()}
          <Link to="/accountings">
            <Panel
              title="現金の収支登録"
              description="現金会計の帳簿"
              image="image/accountings.png"
            />
          </Link>
          {/* <Link to="/notifications">
            <Panel
              title="公式アナウンス"
              description="アプリへの公式連絡"
              image="image/notifications.png"
            />
          </Link> */}
          {(() => {
            if (!isMobile && !(localStorage.getItem("authority") === "trainer"))
              return (
                <Link to="/settings">
                  <Panel title="各種設定" description="設定項目の変更" image="image/settings.png" />
                </Link>
              );
          })()}
          {/* <Link to="/manuals">
            <Panel
              title="マニュアル"
              description="マニュアルの確認"
              image="image/manual.png"
            />
          </Link> */}
          {(() => {
            if (!isMobile && !(localStorage.getItem("authority") === "trainer"))
              return (
                <Link to="/blogs">
                  <Panel title="ブログ執筆" description="ブログ記事の管理" image="image/blog.png" />
                </Link>
              );
          })()}
          {(() => {
            if (
              !isMobile &&
              !(localStorage.getItem("authority") === "trainer") &&
              !(localStorage.getItem("authority") === "owner")
            )
              return (
                <Link to="/ec">
                  <Panel title="ショップ" description="ショップの管理" image="image/shop.png" />
                </Link>
              );
          })()}
        </div>
      </div>
    );
  }
}

module.exports = Top;
