const React = require("react");
const urls = require("../env");
const axios = require("axios");
const Button = require("../components/button");
const { Link } = require("react-router-dom");

class GymShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gymData: null,
      nearStationsData: null,
      workingTimesData: null,
      showAddGymPhoto: false,
      gymPhotosData: null,
      deleteGymPhotoIds: [],
      trainerProfilesData: [],
      newTrainerProfileData: {},
      showAddTrainer: false,
      appealPoints: [],
      newAppealPoint: {},
      showAddAppelPoint: false,
      waysData: null,
      showAddWay: false,
      deleteWayIds: [],
      imageFile: null,
      isUpdating: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const gymId = this.props.match.params.id;
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/controller/gyms/${gymId}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        const gym = results.data?.gym;
        // 新規追加用の最寄り駅データを追加
        gym.near_stations.push({ id: "000" });
        this.setState({
          gymData: gym,
          nearStationsData: gym.near_stations,
          workingTimesData: gym.working_times,
          gymPhotosData: gym.gym_photos,
          trainerProfilesData: gym.trainer_profiles,
          appealPoints: gym.appeal_points,
          waysData: gym.ways,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { gymData, isUpdating } = this.state;

    if (!gymData) return null;
    return (
      <>
        <h2 className="centering mt-12 mb-6">{gymData.name}の編集</h2>
        <a className="blog-link centering" href={`/gyms/${gymData.id}/gym_features`}>
          おすすめポイント（GymFeatures）一覧画面へ >
        </a>
        <a className="blog-link centering" href={`/gyms/${gymData.id}/reviews`}>
          口こみ・評判（Reviews）一覧画面へ >
        </a>
        <div className="centering" style={{ width: "90%", margin: "auto" }}>
          <br />
          <br />
          <br />
          <div className="form-card centering">
            <form>
              <div className="mt-4">
                <label>
                  名前
                  <input
                    className="blog-title"
                    placeholder="例:武蔵小山本店"
                    value={gymData.name}
                    onChange={event => this.onChangeGym(event.target.value, "name")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  キー *ホームページのドメインに相当します (https://katagirijuku.jp/gyms/XXX)
                  <input
                    className="blog-title"
                    placeholder="musashikoyama"
                    value={gymData.slug}
                    onChange={event => this.onChangeGym(event.target.value, "slug")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  ホームページの表示状態
                  <input
                    type="checkbox"
                    checked={!!gymData.hp_visible}
                    value={!!gymData.hp_visible}
                    onChange={e => this.onChangeGym(!gymData.hp_visible, "hp_visible")}
                  />
                  {gymData.hp_visible ? (
                    <div className="mt-4 flex">
                      <div className="btn-on">非表示にする</div>
                    </div>
                  ) : (
                    <div className="mt-4 flex">
                      <div className="btn-off">表示にする</div>
                    </div>
                  )}
                </label>
              </div>
              <div className="mt-4">
                <label>
                  郵便番号
                  <input
                    className="blog-title"
                    placeholder="152-0002"
                    value={gymData.post_number}
                    onChange={event => this.onChangeGym(event.target.value, "post_number")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  住所
                  <input
                    className="blog-title"
                    placeholder="東京都目黒区目黒本町4-2-8"
                    value={gymData.address_one}
                    onChange={event => this.onChangeGym(event.target.value, "address_one")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  住所（建物名・階）
                  <input
                    className="blog-title"
                    placeholder="クーカイテラス 1階"
                    value={gymData.address_two}
                    onChange={event => this.onChangeGym(event.target.value, "address_two")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  電話番号
                  <input
                    className="blog-title"
                    placeholder="03-6451-3959"
                    value={gymData.phone_number}
                    onChange={event => this.onChangeGym(event.target.value, "phone_number")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  Google Map Link
                  <input
                    className="blog-title"
                    placeholder="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.968294415999!2d139.6955001822847!3d35.622219318434176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b1688bf3283%3A0xd24dc614d5632ac3!2z44GL44Gf44GO44KK5aG-IOatpuiUteWwj-WxseacrOW6lw!5e0!3m2!1sja!2sjp!4v1576396600136!5m2!1sja!2sjp"
                    value={gymData.map_link}
                    onChange={event => this.onChangeGym(event.target.value, "map_link")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  Line URL
                  <input
                    className="blog-title"
                    placeholder="https://lin.ee/uekQJ6W"
                    value={gymData.line_link}
                    onChange={event => this.onChangeGym(event.target.value, "line_link")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  Instagram URL
                  <input
                    className="blog-title"
                    placeholder="katagirijuku_akasaka (アカウント名を入力してください)"
                    value={gymData.insta_link}
                    onChange={event => this.onChangeGym(event.target.value, "insta_link")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  Line Token（予約通知グループ）
                  <input
                    className="blog-title"
                    placeholder="発行したTokenをコピペしてください"
                    value={gymData.line_token}
                    onChange={event => this.onChangeGym(event.target.value, "line_token")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  GoogleカレンダーID
                  <input
                    className="blog-title"
                    placeholder="XXXX@group.calendar.google.com"
                    value={gymData.calendar_id}
                    onChange={event => this.onChangeGym(event.target.value, "calendar_id")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  体験予約状態
                  <br />
                  <select
                    className="blog-title"
                    style={{ borderWidth: 1, padding: 4 }}
                    value={gymData.reservation_status}
                    onChange={event => this.onChangeGym(event.target.value, "reservation_status")}
                  >
                    <option value="preparing">オープン準備中</option>
                    <option value="open">予約受付中</option>
                    <option value="stopping">停止中</option>
                  </select>
                </label>
              </div>
              <div className="mt-4">
                <label>
                  オープン予定日
                  <input
                    className="blog-title"
                    placeholder="2021年9月上旬"
                    value={gymData.opening_day}
                    onChange={event => this.onChangeGym(event.target.value, "opening_day")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  予約フォームリンク
                  <input
                    className="blog-title"
                    placeholder="https://lin.ee/XXX"
                    value={gymData.form_link}
                    onChange={event => this.onChangeGym(event.target.value, "form_link")}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  スニペット
                  <br />
                  <textarea
                    className="mt-4  blog-title"
                    placeholder="【完全個室・体験無料】パーソナルトレーニングジムかたぎり塾｜"
                    value={gymData.snippet}
                    onChange={event => this.onChangeGym(event.target.value, "snippet")}
                  />
                </label>
              </div>

              <div className="mt-4">
                <label>
                  ヘッダー画像
                  {gymData.hp_header_image ? (
                    <img style={{ width: "20%" }} src={gymData.hp_header_image} />
                  ) : (
                    <h4>登録されていません</h4>
                  )}
                  <input
                    className="mt-4"
                    type="file"
                    onChange={e => this.onChangeUploadImage(e, "hp_header_image")}
                  />
                  <button
                    className="btn mt-4 centering"
                    onClick={e => this.onImageUpload(e, "hp_header_image")}
                    type="button"
                  >
                    アップロード
                  </button>
                  <br />
                  <br />
                </label>
              </div>
              <div className="mt-4">
                <label>
                  ヘッダー画像（スマートフォン向け）
                  {gymData.hp_header_sm_image ? (
                    <img style={{ width: "20%" }} src={gymData.hp_header_sm_image} />
                  ) : (
                    <h4>登録されていません</h4>
                  )}
                  <input
                    className="mt-4"
                    type="file"
                    onChange={e => this.onChangeUploadImage(e, "hp_header_sm_image")}
                  />
                  <button
                    className="btn mt-4 centering"
                    onClick={e => this.onImageUpload(e, "hp_header_sm_image")}
                    type="button"
                  >
                    アップロード
                  </button>
                  <br />
                  <br />
                </label>
              </div>

              {this.renderInputsForWorkingTimes()}
              {this.renderInputsForNearStations()}
              {this.renderInputsForGymPhotos()}
              {this.renderInputsForTrainerProfile()}
              {this.renderInputsForAppealPoints()}
              {this.renderInputsForWays()}

              <div className="mt-12 flex justify-center">
                <button
                  disabled={isUpdating}
                  className={isUpdating ? "btn-off" : "btn"}
                  onClick={e => this.onClickBtn(e)}
                >
                  更新
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  renderInputsForWorkingTimes = () => {
    const { workingTimesData } = this.state;
    return (
      <>
        <h2>営業時間</h2>
        <div>
          　
          {["月", "火", "水", "木", "金", "土", "日"].map((day, idx) => {
            const wt = workingTimesData?.filter(time => time.day_of_week == day)[0] || {
              id: `wt_${idx}`,
              day_of_week: day,
            };
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                {day}
                <br />
                <label>
                  開始時間
                  <input
                    className="blog-title"
                    placeholder="9"
                    value={wt.open_time}
                    onChange={event =>
                      this.onChangeWorkingTime(event.target.value, wt.id, "open_time", day)
                    }
                  />
                </label>
                <br />
                <label>
                  終了時間
                  <input
                    className="blog-title"
                    placeholder="20"
                    value={wt.close_time}
                    onChange={event =>
                      this.onChangeWorkingTime(event.target.value, wt.id, "close_time", day)
                    }
                  />
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  renderInputsForNearStations = () => {
    const { nearStationsData } = this.state;
    return (
      <>
        <h2>最寄り駅</h2>
        <div>
          {nearStationsData?.map((ns, idx) => {
            const byCar = ns.transfer_type == "car";
            const checkStyle = !!byCar
              ? {
                  width: 10,
                  height: 10,
                  borderColor: "#0171bd",
                  borderWidth: 3,
                }
              : { width: 10, height: 10 };
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                {ns.id == "000" ? <h3>新規で追加</h3> : null}
                <label>
                  駅名
                  <input
                    className="blog-title"
                    placeholder="武蔵小山駅"
                    value={ns.name}
                    onChange={event => this.onChangeNearStation(event.target.value, ns.id, "name")}
                  />
                </label>

                <label>
                  時間(分)
                  <input
                    className="blog-title"
                    placeholder="4"
                    value={ns.minites}
                    onChange={event =>
                      this.onChangeNearStation(event.target.value, ns.id, "minites")
                    }
                  />
                </label>
                <label>
                  <input
                    type="checkbox"
                    style={checkStyle}
                    checked={!!byCar}
                    value={!!byCar}
                    onChange={e =>
                      this.onChangeNearStation(!!byCar ? "walk" : "car", ns.id, "transfer_type")
                    }
                  />
                  移動手段を車にする(デフォルトは徒歩)
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  renderInputsForGymPhotos = () => {
    const { gymPhotosData, showAddGymPhoto } = this.state;
    return (
      <>
        <h2>写真</h2>
        <div>
          {gymPhotosData?.map((gp, idx) => {
            const checkStyle = !!gp.top_page
              ? {
                  width: 10,
                  height: 10,
                  borderColor: "#0171bd",
                  borderWidth: 3,
                }
              : { width: 10, height: 10 };
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                <label>
                  画像
                  {gp.url ? (
                    <div style={{ position: "relative" }}>
                      <img style={{ width: "20%" }} src={gp.url} />
                      <button
                        style={{
                          width: 18,
                          height: 18,
                          position: "absolute",
                          left: "19%",
                          top: -5,
                          border: "0.1px solid #999",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        }}
                        onClick={() => this.onRemoveGymPhoto(gp.id)}
                        type="button"
                      >
                        <img
                          style={{ width: "60%", margin: "auto" }}
                          src={"/image/mark_batsu.png"}
                        />
                      </button>
                    </div>
                  ) : (
                    <h4>登録されていません</h4>
                  )}
                  <input
                    className="mt-4"
                    type="file"
                    onChange={e => this.onChangeUploadImage(e, "gym_photos")}
                  />
                  <button
                    className="btn mt-4 centering"
                    onClick={e => this.onImageUpload(e, "gym_photos", gp.id)}
                    type="button"
                  >
                    アップロード
                  </button>
                  <br />
                  <br />
                </label>
                <label>
                  トップページに表示する
                  <input
                    type="checkbox"
                    style={checkStyle}
                    checked={!!gp.top_page}
                    value={!!gp.top_page}
                    onChange={e => this.onChangeGymPhoto(!gp.top_page, gp.id, "top_page")}
                  />
                </label>
              </div>
            );
          })}
          {showAddGymPhoto ? null : (
            <button
              style={{ marginBottom: 50 }}
              className="btn mt-4 centering"
              onClick={this.onAddGymPhotoBtn}
              type="button"
            >
              ＋ 写真を追加する
            </button>
          )}
        </div>
      </>
    );
  };

  renderInputsForTrainerProfile = () => {
    const { trainerProfilesData, newTrainerProfileData, showAddTrainer } = this.state;

    return (
      <>
        <h2>トレーナー</h2>
        {!!trainerProfilesData.length
          ? trainerProfilesData.map(trainerProfile => {
              return (
                <div className="mt-4">
                  <label>
                    名前
                    <input
                      className="mt-4 blog-title"
                      placeholder="片桐太郎"
                      value={trainerProfile.name}
                      onChange={event =>
                        this.onChangeTrainerProfile(event.target.value, "name", trainerProfile.id)
                      }
                    />
                  </label>
                  <br />
                  <label>
                    メッセージ
                    <br />
                    <textarea
                      className="mt-4  blog-title"
                      placeholder="初めまして、片桐太郎です。"
                      value={trainerProfile.introduction}
                      onChange={event =>
                        this.onChangeTrainerProfile(
                          event.target.value,
                          "introduction",
                          trainerProfile.id
                        )
                      }
                    />
                  </label>
                  <br />
                  {/* <label>
                          ユーザーID
                          <input
                            className="mt-4 blog-title"
                            placeholder="1"
                            value={trainerProfile.user_id}
                            onChange={(event) =>
                              this.onChangeTrainerProfile(
                                event.target.value,
                                "user_id",
                                trainerProfile.id,
                              )
                            }
                          />
                        </label> */}
                  <label>
                    画像
                    {trainerProfile.img ? (
                      <img style={{ width: "20%" }} src={trainerProfile.img} />
                    ) : (
                      <h4>登録されていません</h4>
                    )}
                    <input
                      className="mt-4"
                      type="file"
                      onChange={e => this.onChangeUploadImage(e, "trainer_profile")}
                    />
                    <button
                      className="btn mt-4 centering"
                      onClick={e => this.onImageUpload(e, "trainer_profile", trainerProfile.id)}
                      type="button"
                    >
                      アップロード
                    </button>
                    <br />
                    <br />
                  </label>
                  <div
                    style={{
                      flexDirection: "row",
                      marginBottom: 80,
                    }}
                  >
                    <button
                      style={{
                        padding: 6,
                        backgroundColor: "#fff",
                        color: "#718096",
                        borderRadius: 3,
                        borderColor: "#718096",
                        borderWidth: 2,
                        marginRight: 30,
                      }}
                      onClick={e => this.onRemoveTrainer(trainerProfile.name, trainerProfile.id)}
                      type="button"
                    >
                      ジムから外す
                    </button>
                    <Link
                      style={{
                        padding: 10,
                        backgroundColor: "#48bb78",
                        color: "#fff",
                        borderRadius: 3,
                      }}
                      to={{
                        pathname: `/trainer_edit/${trainerProfile.id}`,
                      }}
                    >
                      インタビュー記事作成・編集 >
                    </Link>
                  </div>
                </div>
              );
            })
          : null}

        {showAddTrainer ? (
          <div className="mt-4">
            <label>
              名前
              <input
                className="mt-4 blog-title"
                placeholder="片桐太郎"
                value={newTrainerProfileData.name}
                onChange={event => this.onChangeNewTrainerProfile(event.target.value, "name")}
              />
            </label>
            <br />
            <label>
              メッセージ
              <br />
              <textarea
                className="mt-4  blog-title"
                placeholder="初めまして、片桐太郎です。"
                value={newTrainerProfileData.introduction}
                onChange={event =>
                  this.onChangeNewTrainerProfile(event.target.value, "introduction")
                }
              />
            </label>
            <br />
            {/* <label>
                          ユーザーID
                          <input
                            className="mt-4 blog-title"
                            placeholder="1"
                            value={newTrainerProfileData.user_id}
                            onChange={(event) =>
                              this.onChangeNewTrainerProfile(
                                event.target.value,
                                "user_id",
                              )
                            }
                          />
                        </label> */}
            <label>
              画像
              {newTrainerProfileData.img ? (
                <img style={{ width: "20%" }} src={newTrainerProfileData.img} />
              ) : (
                <h4>登録されていません</h4>
              )}
              <input
                className="mt-4"
                type="file"
                onChange={e => this.onChangeUploadImage(e, "new_trainer_profile")}
              />
              <button
                className="btn mt-4 centering"
                onClick={e => this.onImageUpload(e, "new_trainer_profile")}
                type="button"
              >
                アップロード
              </button>
              <br />
              <br />
            </label>
          </div>
        ) : (
          <button
            style={{ marginBottom: 50 }}
            className="btn mt-4 centering"
            onClick={this.onAddTrainerBtn}
            type="button"
          >
            ＋ トレーナーを追加する
          </button>
        )}
      </>
    );
  };

  renderInputsForAppealPoints = () => {
    const { appealPoints, newAppealPoint, showAddAppelPoint } = this.state;
    return (
      <>
        <h2>おすすめポイント</h2>
        {!!appealPoints.length
          ? appealPoints.map(appealPoint => {
              return (
                <div className="mt-4">
                  <label>
                    タイトル
                    <input
                      className="mt-4 blog-title"
                      placeholder="完全個室だから人目を気にせずトレーニングできる！"
                      value={appealPoint.title}
                      onChange={event =>
                        this.onChangeAppealPoint(event.target.value, "title", appealPoint.id)
                      }
                    />
                  </label>
                  <br />
                  <label>
                    本文
                    <br />
                    <textarea
                      className="mt-4  blog-title"
                      placeholder="かたぎり塾大塚店は、完全個室・貸切のパーソナルトレーニングジムです。"
                      value={appealPoint.text}
                      onChange={event =>
                        this.onChangeAppealPoint(event.target.value, "text", appealPoint.id)
                      }
                    />
                  </label>
                  <br />
                  <label>
                    画像
                    {appealPoint.img ? (
                      <img style={{ width: "20%" }} src={appealPoint.img} />
                    ) : (
                      <h4>登録されていません</h4>
                    )}
                    <input
                      className="mt-4"
                      type="file"
                      onChange={e => this.onChangeUploadImage(e, "appeal_point")}
                    />
                    <button
                      className="btn mt-4 centering"
                      onClick={e => this.onImageUpload(e, "appeal_point", appealPoint.id)}
                      type="button"
                    >
                      アップロード
                    </button>
                    <br />
                    <br />
                  </label>
                </div>
              );
            })
          : null}

        {showAddAppelPoint ? (
          <div className="mt-4">
            <label>
              タイトル
              <input
                className="mt-4 blog-title"
                placeholder="完全個室だから人目を気にせずトレーニングできる！"
                value={newAppealPoint.title}
                onChange={event => this.onChangeNewAppealPoint(event.target.value, "title")}
              />
            </label>
            <br />
            <label>
              本文
              <br />
              <textarea
                className="mt-4  blog-title"
                placeholder="かたぎり塾大塚店は、完全個室・貸切のパーソナルトレーニングジムです。"
                value={newAppealPoint.text}
                onChange={event => this.onChangeNewAppealPoint(event.target.value, "text")}
              />
            </label>
            <br />
            <label>
              画像
              {newAppealPoint.img ? (
                <img style={{ width: "20%" }} src={newAppealPoint.img} />
              ) : (
                <h4>登録されていません</h4>
              )}
              <input
                className="mt-4"
                type="file"
                onChange={e => this.onChangeUploadImage(e, "new_appeal_point")}
              />
              <button
                className="btn mt-4 centering"
                onClick={e => this.onImageUpload(e, "new_appeal_point")}
                type="button"
              >
                アップロード
              </button>
              <br />
              <br />
            </label>
          </div>
        ) : (
          <button
            style={{ marginBottom: 50 }}
            className="btn mt-4 centering"
            onClick={this.onAddAppealPointBtn}
            type="button"
          >
            ＋ おすすめポイントを追加する
          </button>
        )}
      </>
    );
  };

  renderInputsForWays = () => {
    const { waysData, showAddWay } = this.state;
    return (
      <>
        <h2>道順</h2>
        <div>
          {waysData?.map((way, idx) => {
            return (
              <div
                className="mt-4"
                key={idx}
                style={{
                  padding: 20,
                  marginTop: 30,
                  border: "1px solid #999",
                  borderRadius: 10,
                  width: 600,
                  position: "relative",
                }}
              >
                <label>
                  画像
                  {way.image ? (
                    <img style={{ width: "70%" }} src={way.image} />
                  ) : (
                    <h4>登録されていません</h4>
                  )}
                  <input
                    className="mt-4"
                    type="file"
                    onChange={e => this.onChangeUploadImage(e, "way")}
                  />
                  <button
                    className="btn mt-4 centering"
                    onClick={e => this.onImageUpload(e, "way", way.id)}
                    type="button"
                  >
                    アップロード
                  </button>
                  <br />
                  <br />
                </label>
                <label>
                  表示順
                  <input
                    className="blog-title"
                    placeholder="1"
                    value={way.number}
                    onChange={event => this.onChangeWay(event.target.value, way.id, "number")}
                  />
                </label>
                <label>
                  説明文
                  <input
                    className="blog-title"
                    placeholder="武蔵小山駅　西口を出ます。"
                    value={way.text}
                    onChange={event => this.onChangeWay(event.target.value, way.id, "text")}
                  />
                </label>
                <button
                  style={{
                    width: 18,
                    height: 18,
                    position: "absolute",
                    right: -5,
                    top: -5,
                    border: "0.1px solid #999",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                  }}
                  onClick={() => this.onRemoveWay(way.id)}
                  type="button"
                >
                  <img style={{ width: "60%", margin: "auto" }} src={"/image/mark_batsu.png"} />
                </button>
              </div>
            );
          })}
          {showAddWay ? null : (
            <button
              style={{ marginBottom: 50 }}
              className="btn mt-4 centering"
              onClick={this.onAddWayBtn}
              type="button"
            >
              ＋ 道順を追加する
            </button>
          )}
        </div>
      </>
    );
  };

  onClickBtn = e => {
    e.preventDefault();

    const {
      gymData,
      nearStationsData,
      workingTimesData,
      gymPhotosData,
      trainerProfilesData,
      newTrainerProfileData,
      appealPoints,
      newAppealPoint,
      waysData,
      deleteGymPhotoIds,
      deleteWayIds,
    } = this.state;

    if (!gymData) return;

    this.setState({
      isUpdating: true,
    });

    axios(`${urls.api_server}/controller/gyms/${gymData.id}`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({
        gym: gymData,
        near_stations: nearStationsData,
        working_times: workingTimesData,
        gym_photos: gymPhotosData,
        delete_gym_photo_ids: deleteGymPhotoIds,
        delete_way_ids: deleteWayIds,
        trainer_profiles: trainerProfilesData,
        new_trainer_profile: newTrainerProfileData,
        appeal_points: appealPoints,
        new_appeal_point: newAppealPoint,
        ways: waysData,
      }),
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        if (results.status == 201) {
          alert("更新が完了しました");
        } else {
          alert("サーバー側でエラーが発生しました");
        }
        this.setState({
          isUpdating: false,
        });
        setTimeout(() => this.props.history.goBack(), 1000);
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  onImageUpload = (e, type, ref_id = null) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(type, this.state.imageFile);
    const config = {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${urls.api_server}/controller/gyms/image_upload/${type}`, formData, config)
      .then(response => {
        alert("アップロードが完了しました  url: " + response.data.image_url);
        this.onUploaedImage(response.data.image_url, type, ref_id);
      })
      .catch(error => {
        console.log(error);
      });
  };

  onUploaedImage = (val, type, ref_id = null) => {
    switch (type) {
      case "hp_header_image":
        this.onChangeGym(val, "hp_header_image");
        break;
      case "hp_header_sm_image":
        this.onChangeGym(val, "hp_header_sm_image");
        break;
      case "gym_photos":
        this.onChangeGymPhoto(val, ref_id, "url");
        break;
      case "trainer_profile":
        this.onChangeTrainerProfile(val, "img", ref_id);
        break;
      case "new_trainer_profile":
        this.onChangeNewTrainerProfile(val, "img");
        break;
      case "appeal_point":
        this.onChangeAppealPoint(val, "img", ref_id);
        break;
      case "new_appeal_point":
        this.onChangeNewAppealPoint(val, "img");
        break;
      case "way":
        this.onChangeWay(val, ref_id, "image");
        break;
      default:
    }

    this.setState({
      file: null,
    });
  };

  onChangeUploadImage = (e, type) => {
    this.setState({ imageFile: e.target.files[0] });
  };

  onChangeGym = (val, field) => {
    const { gymData } = this.state;
    gymData[field] = val;
    this.setState({ gymData });
  };

  onChangeGymPhoto = (val, id, field) => {
    if (!id) return;
    const { gymPhotosData } = this.state;
    let updateGymPhotosData = gymPhotosData.map(gp => {
      if (gp.id == id) {
        gp[field] = val;
      }
      return gp;
    });

    this.setState({ gymPhotosData: updateGymPhotosData });
  };

  onChangeNearStation = (val, id, field) => {
    if (!id) return;
    const { nearStationsData } = this.state;
    let updateNearStationsData = nearStationsData.map(ns => {
      if (ns.id == id) {
        ns[field] = val;
      }
      return ns;
    });

    this.setState({ nearStationsData: updateNearStationsData });
  };

  onChangeWorkingTime = (val, id, field, ref_day) => {
    if (!id) return;
    const { workingTimesData } = this.state;

    let target = workingTimesData.filter(wt => wt.id == id)[0];
    let updateWorkingTimesData;
    if (target) {
      updateWorkingTimesData = workingTimesData.map(wt => {
        if (wt.id == id) {
          wt[field] = val;
        }
        return wt;
      });
    } else {
      target = { id, day_of_week: ref_day };
      target[field] = val;
      workingTimesData.push(target);
      updateWorkingTimesData = workingTimesData;
    }
    this.setState({ workingTimesData: updateWorkingTimesData });
  };

  onChangeTrainerProfile = (val, field, id) => {
    const { trainerProfilesData } = this.state;
    // Shallow copy
    const targetTrainerProfile = trainerProfilesData.filter(tp => {
      return tp.id == id;
    })[0];
    targetTrainerProfile[field] = val;

    this.setState({ trainerProfilesData });
  };

  onChangeNewTrainerProfile = (val, field) => {
    const { newTrainerProfileData } = this.state;
    newTrainerProfileData[field] = val;

    this.setState({ newTrainerProfileData });
  };

  onChangeAppealPoint = (val, field, id) => {
    const { appealPoints } = this.state;
    // Shallow copy
    const targetAppealPoint = appealPoints.filter(ap => {
      return ap.id == id;
    })[0];
    targetAppealPoint[field] = val;

    this.setState({ appealPoints });
  };

  onChangeNewAppealPoint = (val, field) => {
    const { newAppealPoint } = this.state;
    newAppealPoint[field] = val;

    this.setState({ newAppealPoint });
  };

  onChangeWay = (val, id, field) => {
    if (!id) return;
    const { waysData } = this.state;
    let updateWaysData = waysData.map(way => {
      if (way.id == id) {
        way[field] = val;
      }
      return way;
    });

    this.setState({ waysData: updateWaysData });
  };

  onAddGymPhotoBtn = () => {
    const { gymPhotosData } = this.state;
    gymPhotosData.push({ id: "000" });
    this.setState({ gymPhotosData, showAddGymPhoto: true });
  };

  onAddTrainerBtn = () => {
    this.setState({ showAddTrainer: true });
  };

  onAddAppealPointBtn = () => {
    this.setState({ showAddAppelPoint: true });
  };

  onAddWayBtn = () => {
    const { waysData } = this.state;
    waysData.push({ id: "000" });
    this.setState({ showAddWay: true });
  };

  onRemoveGymPhoto = id => {
    let { gymPhotosData, deleteGymPhotoIds } = this.state;
    gymPhotosData = gymPhotosData.filter(gp => gp.id != id);
    if (id == "000") {
      this.setState({ gymPhotosData, showAddGymPhoto: false });
    } else {
      deleteGymPhotoIds.push(id);
      this.setState({ gymPhotosData, deleteGymPhotoIds });
    }
  };

  onRemoveWay = id => {
    let { waysData, deleteWayIds } = this.state;
    waysData = waysData.filter(way => way.id != id);
    if (id == "000") {
      this.setState({ waysData, showAddWay: false });
    } else {
      deleteWayIds.push(id);
      this.setState({ waysData, deleteWayIds });
    }
  };

  onRemoveTrainer = (name, id) => {
    const { gymData } = this.state;
    const result = window.confirm(
      `${name}をジムのトレーナーから外しますか？\nOKを押すと変更が実行されます`
    );

    if (result) {
      axios(`${urls.api_server}/controller/trainer_profiles/${id}/remove_from_gym`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "PATCH",
        mode: "cors",
        data: JSON.stringify({
          gym_id: gymData.id,
        }),
      })
        .then(results => {
          if (results.status == 201) {
            alert("変更が完了しました。");
            this.loadData();
          }
        })
        .catch(error => {
          alert("サーバー側でエラーになりました");
        });
    }
  };
}

module.exports = GymShow;
