const React = require("react");
const { Link } = require("react-router-dom");

class EC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
    };
  }

  render() {
    return (
      <div className="w-64 mx-auto mb-10">
        <h2 className="my-16 text-2xl">ショップ</h2>
        <div class="flex justify-center">
          <Link to={{ pathname: "/ec/product_new" }}>
            <button class="py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              商品追加
              <span class="block text-xs font-hairline font-mono italic">
                new product
              </span>
            </button>
          </Link>
        </div>
        <div class="flex justify-center mt-8">
          <Link to={{ pathname: "/ec/products" }}>
            <button class="py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              商品編集
              <span class="block text-xs font-hairline font-mono italic">
                edit product
              </span>
            </button>
          </Link>
        </div>
        <div class="flex justify-center mt-8">
          <Link to={{ pathname: "/ec/sales" }}>
            <button class="py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              売上確認
              <span class="block text-xs font-hairline font-mono italic">
                show sales
              </span>
            </button>
          </Link>
        </div>
        <div class="flex justify-center mt-8">
          <Link to={{ pathname: "/ec/users" }}>
            <button class="py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
              顧客一覧
              <span class="block text-xs font-hairline font-mono italic">
                show users
              </span>
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

module.exports = EC;
