const React = require('react');

class Footer extends React.Component {
    render() {
        return(
            <footer className="footer">©︎ 2020 かたぎり塾</footer>
        )
    }
};

module.exports = Footer;