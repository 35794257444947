const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeReceiveCash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passes: [],
    };
  }

  componentDidMount() {
    this.fetchPasses();
  }

  render() {
    return (
      <>
        <h2>現金を受け取ったら<br />次のパスコードを共有</h2>
        <div className=
"w-64 mx-auto mt-8">
          {this.state.passes?.map((pass) => {
            return (
              <div>
                <div className=
"text-center mt-8">{`${pass.item.name}`}</div>
                <div className=
"flex flex-row justify-between items-center w-32 h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md mx-auto">
                  <div className=
"mx-auto">{`${pass.passcord}`}</div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  fetchPasses = () => {
    const userId = this.props.match.params.id;
    axios(
      `${urls.api_server}/controller/users/${userId}/onetime_passes`,
      {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      }
    ).then((results) => {
      if (results.status === 404) {
        console.log("404");
        return;
      }
      console.log(results)
      this.setState({ passes: results.data.onetime_passes });
      return;
    })
    .catch((error) => {
      console.log(error);
    });
  };
}
module.exports = TraineeReceiveCash;
