const React = require("react");
const axios = require("axios");
const urls = require("../env");

class Reservations extends React.Component {
  constructor(props) {
    const now = new Date();
    let presentMonth = now.getMonth() + 1;
    if (presentMonth < 10) {
      presentMonth = `0` + presentMonth;
    }
    let presentDay = now.getDate();
    if (presentDay < 10) {
      presentDay = `0` + presentDay;
    }
    super(props);
    this.state = {
      reservations: [],
      gyms: [],
      selectedGym: "",
      date: `${now.getFullYear()}/${presentMonth}/${presentDay}`,
    };
  }

  async componentDidMount() {
    this.fetchGyms();
  }

  render() {
    return (
      <>
        <h2>予約検索</h2>
        <div className="w-64 mx-auto mt-8">
          <div>
            <form className="">
              <div className="mt-4">
                <label>
                  店舗
                  <select
                    className="blog-select-btn w-48"
                    defaultValue={this.state.selectedGym}
                    onChange={this.onChangeSelectedGym}
                  >
                    <option value="-">-</option>
                    {this.state.gyms?.map((gym) => (
                      <option key={gym.id} value={`${gym.id}`}>
                        {gym.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  日付
                  <input
                    className="w-auto"
                    placeholder="2020/02/03"
                    defaultValue={this.state.date}
                    onChange={this.onChangeDate}
                  />
                </label>
              </div>
              <div className="mt-12 flex justify-center">
                <button className="btn" onClick={this.onClickBtn}>
                  検索
                </button>
              </div>
            </form>
          </div>
          <h2>予約一覧（{this.state.reservations.length}件）</h2>
          <br />
          {this.state.reservations?.map((reservation) => {
            if (reservation.shiftReason == undefined) {
              return (
                <div className="centering">
                  <div className="reserve-card">
                    <div className="reserve-card-title">{`予約`}</div>
                    <div className="reserve-card-datetime">{`${reservation.reservationDate} ${reservation.reservationTime}:00 ~`}</div>
                    <div className="reserve-card-name">
                      {`${reservation.reservationUserName}`}{" "}
                      様がご来店になります
                    </div>
                  </div>
                </div>
              );
            } else {
              if (reservation.shiftReason.match(/体験/)) {
                return (
                  <div className="centering">
                    <div className="free-reserve-card">
                      <div className="free-reserve-card-title">
                        {`体験`}
                        <span className="text-xs">
                          ：{`${reservation.shiftReason}`}
                        </span>
                      </div>
                      <div className="free-reserve-card-datetime">{`${reservation.reservationDate} ${reservation.reservationTime}:00 ~`}</div>
                      <div className="free-reserve-card-name">
                        {`${reservation.shiftUserName}`} が登録した無料体験です
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="centering">
                    <div className="block-reserve-card">
                      <div className="block-reserve-card-title">
                        {`予定`}
                        <span className="text-xs">
                          ：{`${reservation.shiftReason}`}
                        </span>
                      </div>
                      <div className="block-reserve-card-datetime">{`${reservation.reservationDate} ${reservation.reservationTime}:00 ~`}</div>
                      <div className="block-reserve-card-name">
                        {`${reservation.shiftUserName}`} が登録した予定です
                      </div>
                    </div>
                  </div>
                );
              }
            }
          })}
        </div>
      </>
    );
  }

  onChangeSelectedGym = (e) => {
    this.setState({ selectedGym: e.target.value });
  };
  onChangeDate = (e) => this.setState({ date: e.target.value });

  fetchReservations = async () => {
    const response = await fetch(`${urls.api_server}/controller/reservations`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    });
    const responseJSON = await response.json();
    return responseJSON;
  };

  fetchGyms = async () => {
    axios(`${urls.api_server}/controller/gyms`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
        if (localStorage.getItem("authority") != "manager") {
          this.setState({
            gyms: [
              this.state.gyms.find(
                (gym) => gym.id == Number(localStorage.getItem("user_gym_ids"))
              ),
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickBtn = async (e) => {
    e.preventDefault();
    const { selectedGym, date } = this.state;
    if (selectedGym == "" || selectedGym == "-" || date == "") return;

    const [yyyy, mm, dd] = date.split("/");
    if (
      isNaN(Number(yyyy)) == true ||
      isNaN(Number(mm)) == true ||
      isNaN(Number(dd)) == true
    ) {
      alert(
        "入力していただいた情報の形式が間違っています。半角・全角などをご確認ください。"
      );
      return;
    }
    if (yyyy.length != 4 || mm.length != 2 || dd.length != 2) {
      alert("入力していただいた情報の文字数、または形式が間違えています。");
      return;
    }

    axios(`${urls.api_server}/controller/reservations/selected_gym_date`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({ selectedGymId: selectedGym, date: date }),
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        this.setState({ reservations: results.data.reservations });
      })
      .catch((error) => {
        console.log(error);
      });

    axios(`${urls.api_server}/controller/shifts/selected_gym_date`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({ selectedGymId: selectedGym, date: date }),
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        let update_reservations = this.state.reservations.concat(
          results.data.shifts
        );
        update_reservations.sort(
          (a, b) => Number(a.reservationTime) - Number(b.reservationTime)
        );

        let modified_update_reservatons = [];
        update_reservations.map((r) => {
          if (modified_update_reservatons.length == 0) {
            modified_update_reservatons.push(r);
            return;
          }

          let last_content =
            modified_update_reservatons[modified_update_reservatons.length - 1];
          if (
            last_content.reservationDate == r.reservationDate &&
            last_content.reservationTime == r.reservationTime
          ) {
            if (
              last_content.hasOwnProperty("reservationId") &&
              r.hasOwnProperty("reservationId")
            ) {
              modified_update_reservatons.push(r);
              return;
            } else if (
              last_content.hasOwnProperty("reservationId") &&
              r.hasOwnProperty("shiftId")
            ) {
              return;
            } else if (last_content.hasOwnProperty("shiftId")) {
              modified_update_reservatons.pop();
              modified_update_reservatons.push(r);
              return;
            } else {
              modified_update_reservatons.push(r);
              return;
            }
          } else {
            modified_update_reservatons.push(r);
            return;
          }
        });

        this.setState({ reservations: modified_update_reservatons });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

module.exports = Reservations;
