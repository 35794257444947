const React = require("react");

class Button extends React.Component {
  render() {
    let styleName;
    switch (this.props.type) {
      case "primary":
        styleName = "delete-btn";
        break;
      case "secondary":
        styleName = "frame-btn";
        break;
      default:
        styleName = "btn";
        break;
    }
    return (
      <div className="mt-4 flex justify-center">
        <button className={styleName} onClick={this.props.method}>
          {this.props.name}
        </button>
      </div>
    );
  }
}

module.exports = Button;
