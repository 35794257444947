const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeCancelReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gymId: 0,
      gymName: "〇〇店",
      reservations: [],
    };
  }

  componentDidMount() {
    this.fetchReservations();
  }

  render() {
    return (
      <>
        <div className="w-64 mx-auto mt-8">
          <div className="font-bold text-xl mb-4">{`所属ジム：${this.state.gymName}`}</div>
          {this.state.reservations?.map((reservation) => {
            return (
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-between items-center w-32 h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md">
                  <div>{`${reservation.date}　${reservation.time}:00~`}</div>
                </div>
                <button
                  className="bg-orange-600 w-20 p-3 mb-4 rounded mx-auto text-white text-center"
                  onClick={() => this.onClickDeleteReservation(reservation.id)}
                >
                  <div className="text-white">削除</div>
                </button>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  fetchReservations = () => {
    const userId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/users/${userId}/reservations`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({
          gymId: results.data.gymId,
          gymName: results.data.gymName,
          reservations: results.data.reservations,
        });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickDeleteReservation = (id) => {
    axios(`${urls.api_server}/controller/reservations/${id}/delete`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        alert("キャンセルが完了しました。");
        return;
      })
      .catch((error) => {
        alert("キャンセルが失敗しました。");
        console.log(error);
      });
  };
}
module.exports = TraineeCancelReservation;
