const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { times } = require("async");

const DAYS = ["月", "火", "水", "木", "金", "土", "日"];
const SELECTABLE_TIMES = [...Array(24).keys()].map(i => ++i);
class FixedShifts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dayOfWeek: "",
      time: "",
      blockedTimes: null,
    };
  }

  componentDidMount() {
    this.fetchPermanentBlockedTimes();
  }

  render() {
    return (
      <div className="w-auto">
        <h2 className="mb-10">
          来月以降の
          <br />
          予約できない時間
          <br />
          （固定枠設定）
        </h2>
        <form className="w-64 mx-auto -mt-4">
          <div className="mt-4">
            <label>
              曜日
              <select
                className="blog-select-btn w-48"
                defaultValue={this.state.dayOfWeek}
                onChange={this.onChangeDayOfWeek}
              >
                <option value="-">-</option>
                {DAYS.map(day => {
                  return <option value={day}>{day}</option>;
                })}
              </select>
            </label>
          </div>
          <div className="mt-4">
            <label>
              時間
              <select
                className="blog-select-btn w-48"
                defaultValue={this.state.time}
                onChange={this.onChangeTime}
              >
                <option value="-">-</option>
                {SELECTABLE_TIMES.map(time => {
                  return <option value={time}>{time}</option>;
                })}
              </select>
            </label>
          </div>
          <div className="mt-12 flex justify-center mx-auto">
            <button className="btn" onClick={this.onClickSaveBtn}>
              保存
            </button>
          </div>
        </form>
        <br />
        <h2 className="mb-10">予約できない時間の一覧</h2>
        {this.renderBlockedTimes()}
      </div>
    );
  }

  renderBlockedTimes = () => {
    const { blockedTimes } = this.state;
    if (!blockedTimes) return <p className="text-gray-600 text-center">"読み込み中..."</p>;
    if (blockedTimes.length == 0)
      return <p className="text-gray-600 text-center">"データが見つかりませんでした。"</p>;
    console.log(blockedTimes);
    return (
      <div className="w-1/2 mx-auto">
        {DAYS.map(day => {
          const blockedTimesFilterByDay = blockedTimes
            .filter(bt => bt.day_of_week == day)
            .sort((a, b) => Number(a.gym.id) - Number(b.gym.id));
          return blockedTimesFilterByDay.map(blockedTime => (
            <div className="flex flex-row justify-end items-center w-full h-12 p-2 mb-4 bg-white rounded-lg shadow-md">
              <div className="ml-4 text-xs">{`${
                blockedTime.status
                  ? blockedTime.user.name.substr(0, 8) + "さん固定"
                  : "固定ブロック"
              }`}</div>
              <div className="ml-4 text-xs text-gray-500">
                {`${blockedTime.createdAt.split("T")[0]}`}作成
              </div>
              <div className="ml-4 text-xs">{`${blockedTime.gym.name}`}</div>
              <p className="ml-4 mr-8">
                {`${blockedTime.day_of_week}`}曜{` ${blockedTime.time}:00`}
              </p>
              <button className="delete-btn" onClick={() => this.onClickDeleteBtn(blockedTime.id)}>
                削除
              </button>
            </div>
          ));
        })}
      </div>
    );
  };

  onChangeDayOfWeek = e => {
    this.setState({ dayOfWeek: e.target.value });
  };
  onChangeTime = e => {
    this.setState({ time: e.target.value });
  };

  onClickSaveBtn = async e => {
    e.preventDefault();
    const { dayOfWeek, time } = this.state;
    if (!dayOfWeek || dayOfWeek == "-" || !time || time == "-") return;

    axios(`${urls.api_server}/controller/trainer/${localStorage.getItem("user_id")}/perm_block`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      data: JSON.stringify({ dayOfWeek, time }),
    })
      .then(results => {
        if (results.status === 404 || results.status === 500) {
          alert(results.message);
          return;
        }
        if (results.status == 201) {
          alert("予約できない時間を追加しました。");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchPermanentBlockedTimes = async () => {
    axios(
      `${urls.api_server}/controller/trainer/${localStorage.getItem("user_id")}/perm_block_times`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "GET",
      }
    )
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ blockedTimes: results.data.tempReservations });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onClickDeleteBtn = async id => {
    axios(`${urls.api_server}/controller/blockedTime/${id}/delete`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
    })
      .then(results => {
        if (results.status == 201) {
          alert("削除しました。");
          let blockedTimes = this.state.blockedTimes;
          blockedTimes = blockedTimes.filter(blockedTime => blockedTime.id != id);
          this.setState({ blockedTimes });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

module.exports = FixedShifts;
