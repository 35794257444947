const React = require('react');

class Panel extends React.Component {
    render() {
        return(
            <div className="panel">
                <img
                    className="w-full"
                    src={this.props.image}
                    alt=""
                ></img>
                <div className="padding">
                    <div className="panel-title">{this.props.title}</div>
                    <p className="panel-description">
                    {this.props.description}
                    </p>
                </div>
            </div>
        )
    }
};

module.exports = Panel;