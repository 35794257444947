const React = require("react");
const axios = require("axios");
const urls = require("../env");
const Button = require("../components/button");

class TrialsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trialUsers: [],
    };
  }

  componentDidMount() {
    this.fetchTrialUsers();
  }

  fetchTrialUsers = () => {
    const gymId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/gyms/${gymId}/trialusers`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status != 200) {
          alert("何らかのエラーが発生しています。本部にご連絡ください。");
          return;
        }
        this.setState({ trialUsers: results.data.trialUsers });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setTrialUserCVCompleted = (e, userId, userName) => {
    e.preventDefault();
    if (
      window.confirm(`本当に、${userName} さんを「入会達成」に変更しますか？`)
    ) {
      axios(
        `${urls.api_server}/controller/users/${userId}/trial_cv_completed`,
        {
          auth: {
            username: urls.basic_user,
            password: urls.basic_pass,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "GET",
        }
      )
        .then(async (results) => {
          if (results.status != 200) {
            alert(
              "【入会達成に変更できませんでした！】何らかのエラーが発生しています。本部にご連絡ください。"
            );
            return;
          }
          alert(`${userName} さんを「入会達成」にしました。`);
          await this.fetchTrialUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <>
        <h2>初回体験のお客様</h2>
        <table className="w-2/3">
          <thead>
            <tr>
              <th>{"ユーザーID / 名前"}</th>
              <th>{"メールアドレス"}</th>
              <th>{"電話番号"}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.trialUsers.map((u) => {
              return (
                <tr className="text-center">
                  <td>{`${u.id} / ${u.name}`}</td>
                  <td>{u.email}</td>
                  <td>{u.phone}</td>
                  <td>
                    <Button
                      name="入会達成"
                      method={(e) =>
                        this.setTrialUserCVCompleted(e, u.id, u.name)
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

module.exports = TrialsShow;
