const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeFixReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gymId: 0,
      gymName: "〇〇店",
      days: ["月", "火", "水", "木", "金", "土", "日"],
      selectedDay: "月",
      times: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      selectedHour: 12,
      availableTimes: [],
      usersTempReservations: [],
      sectionTimes: {},
    };
    this.onChangeDays = this.onChangeDays.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
  }
  componentDidMount() {
    this.fetchAvailableTimes();
  }

  render() {
    return (
      <>
        <div className="w-64 mx-auto mt-8">
          <div className="font-bold text-xl mb-4">{`所属ジム：${this.state.gymName}`}</div>
          {this.state.usersTempReservations?.map((tempReservation) => {
            return (
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-between items-center w-32 h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md">
                  <div>{`${tempReservation.day_of_week}　${tempReservation.time}:00~`}</div>
                </div>
                <button
                  className="bg-orange-600 w-20 p-3 mb-4 rounded mx-auto text-white text-center"
                  onClick={() =>
                    this.onClickDeleteReservation(tempReservation.id)
                  }
                >
                  <div className="text-white">削除</div>
                </button>
              </div>
            );
          })}
          {/* <div className="mt-4">＜ジムの固定予約可能時間＞</div>
          <div>
            {this.state.availableTimes?.map((availableTime) => {
              return (
                <div className="flex flex-row">
                  <div className="mr-6 font-bold">{availableTime.day}</div>
                  {availableTime.workingHours.map((hour) => {
                    return <div className="mr-3">{hour}</div>;
                  })}
                </div>
              );
            })}
          </div> */}
          <select
            className="h-8 w-16 mr-4 border-solid mt-8 bg-white"
            value={this.state.selectedDay}
            onChange={this.onChangeDays}
            name="num"
          >
            {this.state.days.map((day) => (
              <option value={day}>{day}</option>
            ))}
          </select>
          <select
            className="h-8 w-16 border-solid bg-white"
            value={this.state.selectedHour}
            onChange={this.onChangeTime}
            name="num"
          >
            {/* {this.state.times.map((time) => (
              <option value={time}>{time}</option>
            ))} */}
            {(() => {
              if (this.state.availableTimes.length > 0) {
                switch (this.state.selectedDay) {
                  case this.state.availableTimes[0].day:
                    console.log(this.state.availableTimes[0].workingHours);
                    return this.state.availableTimes[0].workingHours.map(
                      (time) => {
                        console.log("time");
                        return <option value={time}>{time}</option>;
                      }
                    );
                    break;
                  case this.state.availableTimes[1].day:
                    console.log(this.state.availableTimes[1].workingHours);
                    return this.state.availableTimes[1].workingHours.map(
                      (time) => {
                        console.log("time");
                        return <option value={time}>{time}</option>;
                      }
                    );
                    break;
                  case this.state.availableTimes[2].day:
                    console.log(this.state.availableTimes[2].workingHours);
                    return this.state.availableTimes[2].workingHours.map(
                      (time) => <option value={time}>{time}</option>
                    );
                    break;
                  case this.state.availableTimes[3].day:
                    return this.state.availableTimes[3].workingHours.map(
                      (time) => <option value={time}>{time}</option>
                    );
                    break;
                  case this.state.availableTimes[4].day:
                    return this.state.availableTimes[4].workingHours.map(
                      (time) => <option value={time}>{time}</option>
                    );
                    break;
                  case this.state.availableTimes[5].day:
                    return this.state.availableTimes[5].workingHours.map(
                      (time) => <option value={time}>{time}</option>
                    );
                    break;
                  case this.state.availableTimes[6].day:
                    return this.state.availableTimes[6].workingHours.map(
                      (time) => <option value={time}>{time}</option>
                    );
                    break;
                  default:
                }
              }
            })()}
          </select>
          <button
            className="bg-orange-600 w-56 py-3 rounded mx-auto my-5 text-white text-center"
            onClick={this.onClickBtn}
          >
            <div className="text-lg text-white">固定予約を保存</div>
          </button>
        </div>
      </>
    );
  }
  onClickDeleteReservation = async (id) => {
    if (window.confirm("本当に削除しますか？")) {
      axios(`${urls.api_server}/controller/temp_reservations/${id}/delete`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      })
        .then((results) => {
          if (results.status == 200) {
            alert("固定予約を削除しました。");
          }
          if (results.status === 404) {
            console.log("404");
            return;
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onChangeDays = async (event) => {
    await this.setState({ selectedDay: event.target.value });
    const availableTimesOfSelectedDay = this.state.availableTimes.filter(
      (t) => t.day == this.state.selectedDay
    )[0].workingHours;
    await this.setState({ selectedHour: availableTimesOfSelectedDay[0] });
  };
  onChangeTime = async (event) => {
    await this.setState({ selectedHour: event.target.value });
  };

  fetchAvailableTimes = async () => {
    const userId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/users/${userId}/temp_reservations`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({
          gymName: results.data.gymName,
          gymId: results.data.gymId,
          availableTimes: results.data.availableTimes,
          usersTempReservations: results.data.usersTempReservations,
        });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickBtn = async () => {
    const userId = this.props.match.params.id;
    const { gymId, selectedDay, selectedHour } = this.state;

    axios(`${urls.api_server}/controller/users/${userId}/temp_reservation`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({
        gymId: gymId,
        day: selectedDay,
        hour: selectedHour,
      }),
    })
      .then((results) => {
        if (results.status == 201) {
          alert("この時間は、固定予約をできません。");
          return;
        }
        if (results.status == 200) {
          alert("固定予約を作成しました。");
          return;
        }
        alert("サーバーのエラーです。");
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
module.exports = TraineeFixReservation;
