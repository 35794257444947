const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeKarte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      QandA: [],
    };
  }
  componentDidMount() {
    this.fetchQandA();
  }
  render() {
    return (
      <div className="w-5/6 mx-auto">
        <h2 className="mb-2">初回カウンセリングシート</h2><br />
        <form className="">
        <div className="w-full px-2">
          {this.state.QandA.map((q_a) => {
            if (q_a.image==null)
            return (
              <div className="mt-8">
                <div className="font-semibold text-gray-500 text-xs">{`${q_a.question}`}</div>
                {q_a.question=="姿勢の写真を添付してください。"? <div><br /><img src={q_a.image}/></div>:<div>{q_a.answer}</div>}
              </div>
            );
          })}
        </div>
        </form>
      </div>
    );
  }

  fetchQandA = async () => {
    const userId = this.props.match.params.id;
    axios(
      `${urls.api_server}/controller/users/${userId}/karte_q_and_a`,
      {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      }
    ).then((results) => {
      if (results.status === 404) {
        console.log("404");
        return;
      }
      // console.log(results)
      this.setState({ QandA: results.data.QandA });
      return;
    })
    .catch((error) => {
      console.log(error);
    });
  };
}
module.exports = TraineeKarte;
