const React = require("react");
const { Link } = require("react-router-dom");
const { isMobile } = require("mobile-device-detect");
const { Redirect } = require("react-router-dom");
const User = require("../axios");
const axios = require("axios");
const urls = require("../env");
const user = new User();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      gym_name: "",
      reject: false,
    };
  }
  componentDidMount() {
    this.get_user();
  }

  get_user = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/users/${localStorage.getItem("user_id")}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ user: results.data.user, gym_name: results.data.gym_name });
        localStorage.setItem("authority", this.state.user.authority);
        localStorage.setItem("user_gym_ids", this.state.user.gymusers[0].gym_id);
        if (localStorage.getItem("authority") == "trainee") this.setState({ reject: true });
      })
      .catch(error => {
        console.log(error);
      });
  };

  execute_logout = async () => {
    user.logout();
    this.setState({ login: false });
    window.location.reload();
  };

  render() {
    return (
      <nav className="nav">
        {this.renderRedirect()}
        <div className="logo">
          <Link to="/">
            <img src="/image/logo.png" width="200px" height="auto" alt="logo" />
          </Link>
        </div>
        {this.renderForPC()}
      </nav>
    );
  }

  renderRedirect = () => {
    if (this.state.reject) {
      return <Redirect to="/not_found" />;
    }
  };

  renderForPC = () => {
    if (isMobile) return null;

    return (
      <div className="links">
        <div className="text-sm lg:flex-grow">
          <a href="https://katagirijuku.jp/media/" className="link">
            塾長ブログ
          </a>
        </div>
        {this.renderLogoutButton()}
      </div>
    );
  };

  renderLogoutButton = () => {
    if (!user.is_login()) return null;

    const authority = {
      manager: "管理者",
      owner: "オーナー",
      trainer: "トレーナー",
    };
    return (
      <>
        <div>
          <p className="frame-btn">所属店舗：{this.state.gym_name}</p>
        </div>
        <div style={{ marginRight: 20 }}>
          <p className="frame-btn">{authority[this.state.user.authority]}</p>
        </div>

        <div>
          <p onClick={this.execute_logout} className="frame-btn">
            ログアウト
          </p>
        </div>
      </>
    );
  };
}

module.exports = Header;
