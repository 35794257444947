// manualカテゴリの記事のみ選定
const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Link } = require("react-router-dom");

class manuals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      link: "",
      authority: "",
      manuals: [],
      initialManuals: [],
      applePayAvailable: false,
    };
  }
  componentDidMount() {
    this.get_manuals();
    this.setState({ manuals: this.state.initialManuals });
    this.checkApplePayAvailability();
  }
  render() {
    let manuals =
      localStorage.getItem("authority") === "trainer"
        ? this.state.manuals.filter((manual) => manual.authority == "trainer")
        : this.state.manuals;
    manuals =
      localStorage.getItem("authority") === "owner"
        ? this.state.manuals.filter((manual) => manual.authority != "manager")
        : manuals;
    return (
      <>
        {(() => {
          if (!(localStorage.getItem("authority") === "trainer")) {
            return (
              <>
                <h2>マニュアルの追加</h2>
                <div className="form-card">
                  <form className="blog mx-8">
                    <div className="mt-4 flex">
                      <label className="-ml-6 mb-2">
                        タイトル
                        <input
                          className="blog-title w-64 mt-2"
                          placeholder="マニュアルの名称"
                          defaultValue={this.state.name}
                          onChange={this.onChangeName}
                        />
                      </label>
                      　
                      <label>
                        対象
                        <select
                          className="blog-category blog-select-btn mt-2"
                          defaultValue={this.state.authority}
                          onChange={this.onChangeAuthority}
                        >
                          <option value="-">-</option>
                          {/* <option value="trainee">塾生</option> */}
                          <option value="trainer">トレーナー</option>
                          <option value="owner">オーナー</option>
                          <option value="manager">管理者</option>
                        </select>
                      </label>
                      　
                      <label>
                        リンク
                        <input
                          className="blog-category mt-2"
                          placeholder="マニュアルのリンク"
                          defaultValue={this.state.link}
                          onChange={this.onChangeLink}
                        />
                      </label>
                    </div>
                    <div className="mt-12 flex justify-center">
                      <button
                        className="btn"
                        onClick={(e) => this.onClickBtn(e)}
                      >
                        追加
                      </button>
                    </div>
                  </form>
                </div>
              </>
            );
          }
        })()}
        <h2 className="mt-12">マニュアルの一覧</h2>
        <br />
        {/* <p className="centering text-gray-600">GoogleDriveにマニュアルを配備次第反映予定</p> */}
        <div className="centering">
          <input
            className="blog-search"
            type="text"
            placeholder="検索キーワードを入力してエンターキーを押しましょう"
            onKeyPress={(event) => {
              if (
                event.keyCode === 13 ||
                event.which === 13 ||
                event.keyCode === 52 ||
                event.which === 52
              ) {
                console.log(event.target.value);
                return this.filterList(event.target.value);
              }
            }}
            onKeyUp={this.refleshCheck}
          />
        </div>
        <table className="blog-table w-2/3">
          <thead>
            <tr>
              <th>{"マニュアル"}</th>
              <th>{"対象"}</th>
              {(() => {
                if (!(localStorage.getItem("authority") === "trainer"))
                  return <th>{""}</th>;
              })()}
            </tr>
          </thead>
          <tbody>
            {manuals.map((manual, index) => {
              const target = {
                trainer: "トレーナー",
                owner: "オーナー",
                manager: "管理者",
                trainee: "塾生",
              };
              return (
                <tr key={manual.id} className={index % 2 == 0 ? "odd-tr" : ""}>
                  <td>
                    <a href={manual.link} className="" target="_blank">
                      {manual.name}
                    </a>
                  </td>
                  <td>{target[manual.authority]}</td>
                  {(() => {
                    if (!(localStorage.getItem("authority") === "trainer"))
                      return (
                        <td>
                          <button
                            className="delete-btn"
                            onClick={() => this.onClickDeleteBtn(manual.id)}
                          >
                            削除
                          </button>
                        </td>
                      );
                  })()}
                </tr>
              );
            })}
          </tbody>
        </table>
        <h2>PAY.JPテスト</h2>
        <div className="w-24 mx-auto mt-20">
          <button className="btn" onClick={(e) => this.onClickTestBtn(e)}>
            てすてす
          </button>
        </div>
      </>
    );
  }

  onClickTestBtn = async (e) => {
    e.preventDefault();
    try {
      console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 1");
      if (!this.state.applePayAvailable) return;
      console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 1.5 39");

      // TODO
      // window.Payjp.setPublicKey("pk_live_ba4534fc132cc80076a8e717");
      window.Payjp.setPublicKey("pk_test_950c45e01d7ac686657a3936");

      var paymentRequest = {
        countryCode: "JP",
        currencyCode: "JPY",
        total: {
          label: "PAY.JP SAMPLE",
          amount: "16",
        },
      };
      const session = window.Payjp.applePay.buildSession(
        paymentRequest,
        function success(result) {
          // 顔認証、指紋認証、パスワード認証にどれかの成功時の処理
          console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 2");
          console.log(result);

          axios({
            auth: {
              username: urls.basic_user,
              password: urls.basic_pass,
            },
            method: "POST",
            url: `${urls.api_server}/api/apple_pay/execute`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            data: JSON.stringify({
              token: result.token.id,
            }),
          })
            .then((results) => {
              console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 3");
              if (results.status != 200) {
                session.completePayment(
                  window.Payjp.ApplePaySession.STATUS_FAILURE
                );
                return;
              }
              // ユーザーの画面のペイメントシートを閉じApple Payによる決済を完了↓
              session.completePayment(
                window.Payjp.ApplePaySession.STATUS_SUCCESS
              );
              return;
            })
            .catch((error) => {
              console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% ERROR", error);
              session.completePayment(
                window.Payjp.ApplePaySession.STATUS_FAILURE
              );
            });
        },
        function failure(e) {
          console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 4");
          console.log("失敗している！！", e);
          // 失敗時の処理
        }
      );
      session.begin();
    } catch (error) {
      console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% ERROR");
      console.log(error);
    }
  };

  checkApplePayAvailability = () => {
    // TODO
    // window.Payjp.setPublicKey("pk_live_ba4534fc132cc80076a8e717");
    window.Payjp.setPublicKey("pk_test_950c45e01d7ac686657a3936");

    window.Payjp.applePay.checkAvailability((available) => {
      console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 5')
      console.log(available)
      if (available) {
        this.setState({ applePayAvailable: true });
      }
    });
  };

  get_manuals = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/manuals`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState(results["data"]);
        this.setState({ initialManuals: this.state.manuals });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterList = (value) => {
    const searchedManuals = this.state.manuals.filter(
      (manual) =>
        manual.name.toString().indexOf(value) >= 0 ||
        manual.description.toString().indexOf(value) >= 0
    );
    this.setState({ manuals: searchedManuals });
  };

  filterListByCategory = async (value) => {
    await this.setState({ manuals: this.state.initialManuals });
    if (value.length == 1) return;
    const searchedManuals = this.state.manuals.filter(
      (manual) => manual.authority == value
    );
    this.setState({ manuals: searchedManuals });
  };

  refleshCheck = (e) => {
    if (e.target.value.length == 0) {
      this.setState({ manuals: this.state.initialManuals });
    }
  };

  onChangeName = (e) => this.setState({ name: e.target.value });
  onChangeAuthority = (e) => this.setState({ authority: e.target.value });
  onChangeLink = (e) => this.setState({ link: e.target.value });

  onClickBtn = async (e) => {
    e.preventDefault();
    const { name, link, authority } = this.state;
    if (name == "") return;
    axios(`${urls.api_server}/controller/manuals/set`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      data: JSON.stringify({ name, link, authority }),
    }).then((results) => {
      console.log(results.data);
      this.setState(results.data);
    });
  };

  onClickDeleteBtn = async (id) => {
    axios(`${urls.api_server}/controller/manuals/${id}/del`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
    })
      .then((results) => {
        if (results.status == 200) {
          alert("削除しました。");
          this.setState(results.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

module.exports = manuals;
