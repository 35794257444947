const React = require("react");
const { Link } = require("react-router-dom");
const axios = require("axios");
const urls = require("../env");

class GymsAdvertisementsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisements: [],
      targetDate: "",
      year: "",
      month: "",
      gymName: "",
    };
  }

  componentDidMount() {
    this.fetchGymAdvertisements();
  }

  fetchGymAdvertisements = () => {
    const gymId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/gyms/${gymId}/user_advertisements/`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(async results => {
        if (results.status != 200) {
          alert("何らかのエラーが発生しました。本部にご連絡ください。");
          return;
        }
        this.setState({
          advertisements: results.data.advertisements,
          gymName: results.data.gymName,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  getMonth = () => {
    if (this.state.month) {
      return this.state.month;
    } else {
      let d = new Date();
      let month = d.getMonth() + 1;
      return month;
    }
  };

  getYear = () => {
    if (this.state.year) {
      return this.state.year;
    } else {
      let d = new Date();
      let year = d.getFullYear();
      return year;
    }
  };

  getAdvertisementsForSpecificMonth = () => {
    const gymId = this.props.match.params.id;
    const { targetDate } = this.state;
    let targetMonth = new Date(targetDate);
    const beginningOfMonthDate = new Date(new Date(targetMonth).setHours(0, 0, 0));

    axios(
      `${urls.api_server}/controller/gyms/${gymId}/user_advertisements/?search=${beginningOfMonthDate}`,
      {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      }
    )
      .then(async results => {
        if (results.status != 200) {
          alert("何らかのエラーが発生しました。本部にご連絡ください。");
          return;
        }
        this.setState({
          advertisements: results.data.advertisements,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <div className="w-128 mx-auto mb-10"></div>
        <h2 className="my-16 text-2xl">{this.state.gymName}の広告経路の確認</h2>
        <div className="centering">
          {this.getYear()}年{this.getMonth()}月の広告経路先とコンバージョン数
        </div>
        <div className="centering">
          <label>
            <input
              type="month"
              onChange={async event => {
                const value = event.target.value;
                await this.setState({
                  targetDate: value,
                  year: value.substr(0, 4),
                  month: value.substr(5, 6),
                });
                await this.getAdvertisementsForSpecificMonth();
              }}
            ></input>
          </label>
        </div>
        {this.renderAdvertisements()}
      </>
    );
  }

  renderAdvertisements = () => {
    const { advertisements } = this.state;
    if (!advertisements || advertisements.length == 0) return this.renderException();
    return (
      <table className="w-2/3">
        <thead>
          <tr>
            <th>広告経路先</th>
            <th>コンバージョン</th>
          </tr>
        </thead>
        <tbody>
          {advertisements.map((advertisement, index) => (
            <tr key={index}>
              <td>{advertisement.parameter_key}</td>
              <td className="text-center">{advertisement.parameter_total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderException = () => {
    const { advertisements } = this.state;
    const text = !advertisements ? "読み込み中..." : "データが見つかりませんでした。";
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          height: "50px",
          paddingTop: "15px",
        }}
      >
        {text}
      </div>
    );
  };
}

module.exports = GymsAdvertisementsShow;
