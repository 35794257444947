const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeKarte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      questionId_Answers: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      imageFile: null,
    };
  }
  componentDidMount() {
    this.fetchQuestions();
  }
  render() {
    return (
      <div className="w-5/6 mx-auto">
        <h2 className="mb-2">初回カウンセリングシート</h2>
        <br />
        <form className="w-full px-6">
          {this.state.questions.map((question) => {
            return (
              <>
                <div className="font-semibold mt-6">{`■ ${question.hearing_text}`}</div>
                {question.questions.map((questionContent) => {
                  if (questionContent.type == "text") {
                    return (
                      <>
                        <div className="mt-4">{questionContent.text}</div>
                        <input
                          className="w-full"
                          value={
                            this.state.questionId_Answers[
                              questionContent.id - 1
                            ]
                          }
                          onChange={(event) =>
                            this.onChangeTextInput(questionContent.id, event)
                          }
                        />
                      </>
                    );
                  }
                  if (questionContent.type == "single") {
                    const singleChoiceOptions = questionContent.options.split(
                      ","
                    );
                    return (
                      <>
                        <div className="mt-4">{questionContent.text}</div>
                        {singleChoiceOptions.map((option) => {
                          if (
                            this.state.questionId_Answers[
                              questionContent.id - 1
                            ].indexOf(option) != -1
                          ) {
                            return (
                              <button
                                className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-orange-600 rounded-lg shadow-md"
                                onClick={(e) => {
                                  this.onClickSingleBtn(
                                    e,
                                    questionContent.id,
                                    option
                                  );
                                }}
                              >
                                <div className="text-base text-white text-center mx-auto">{`${option}`}</div>
                              </button>
                            );
                          } else {
                            return (
                              <button
                                className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md"
                                onClick={(e) => {
                                  this.onClickSingleBtn(
                                    e,
                                    questionContent.id,
                                    option
                                  );
                                }}
                              >
                                <div className="text-base text-white text-center mx-auto">{`${option}`}</div>
                              </button>
                            );
                          }
                        })}
                      </>
                    );
                  }
                  if (questionContent.type == "multi") {
                    const multiChoiceOptions = questionContent.options.split(
                      ","
                    );
                    return (
                      <>
                        <div className="mt-4">{questionContent.text}</div>
                        {multiChoiceOptions.map((option) => {
                          if (
                            this.state.questionId_Answers[
                              questionContent.id - 1
                            ].indexOf(option) != -1
                          ) {
                            return (
                              <button
                                className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-green-600 rounded-lg shadow-md"
                                onClick={(e) => {
                                  this.onClickMultiBtn(
                                    e,
                                    questionContent.id,
                                    option
                                  );
                                }}
                              >
                                <div className="text-base text-white text-center mx-auto">{`${option}`}</div>
                              </button>
                            );
                          } else {
                            return (
                              <button
                                className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md"
                                onClick={(e) => {
                                  this.onClickMultiBtn(
                                    e,
                                    questionContent.id,
                                    option
                                  );
                                }}
                              >
                                <div className="text-base text-white text-center mx-auto">{`${option}`}</div>
                              </button>
                            );
                          }
                        })}
                      </>
                    );
                  }
                  if (questionContent.type == "photo") {
                    return (
                      <>
                        <div className="mt-4">{questionContent.text}</div>
                        <input
                          className="w-full"
                          type="file"
                          onChange={(e) => this.onChangeUploadImage(e)}
                        />
                        <button
                          className="btn mt-4"
                          onClick={(e) => this.onUpload(e, "body_image")}
                          type="submit"
                        >
                          添付確定
                        </button>
                      </>
                    );
                  }
                })}
              </>
            );
          })}
          <button
            className="block bg-orange-600 w-56 py-3 rounded mx-auto my-5 text-white text-center"
            onClick={(e) => this.onClickSaveBtn(e)}
          >
            保存
          </button>
        </form>
      </div>
    );
  }

  onChangeTextInput = async (questionId, event) => {
    let questionId_Answers = this.state.questionId_Answers;
    questionId_Answers[questionId - 1] = event.target.value;
    this.setState({ questionId_Answers: questionId_Answers });
  };

  onClickSingleBtn = async (e, questionId, answer) => {
    e.preventDefault();
    let questionId_Answers = this.state.questionId_Answers;
    questionId_Answers[questionId - 1] = answer;
    this.setState({ questionId_Answers: questionId_Answers });
  };

  onClickMultiBtn = async (e, questionId, answer) => {
    e.preventDefault();
    let questionId_Answers = this.state.questionId_Answers;
    if (questionId_Answers[questionId - 1].indexOf(answer) != -1) {
      questionId_Answers[questionId - 1] = questionId_Answers[
        questionId - 1
      ].replace(`,${answer}`, "");
    } else {
      questionId_Answers[questionId - 1] =
        questionId_Answers[questionId - 1] + "," + answer;
    }
    this.setState({ questionId_Answers: questionId_Answers });
  };

  onClickSaveBtn = (e) => {
    e.preventDefault();
    const traineeId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/karte_answers`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({
        trainer_id: 2,
        trainee_id: traineeId,
        answers: this.state.questionId_Answers,
        image: this.state.imageFile,
      }),
    })
      .then((results) => {
        if (results.status === 200) {
          alert("カルテを保存しました。");
          return;
        }
        alert("何らかの問題で、カルテを保存できません。");
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchQuestions = () => {
    axios(`${urls.api_server}/controller/questions`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ questions: results.data.hearingAndQuestions });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onUpload = (e, type, ref_id = null) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(type, this.state.imageFile);
    const config = {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        `${urls.api_server}/controller/answers/image_upload/${type}`,
        formData,
        config
      )
      .then((response) => {
        alert("アップロードが完了しました  url: " + response.data.image_url);
        this.setState({ imageFile: response.data.image_url });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChangeUploadImage = (e) => {
    this.setState({ imageFile: e.target.files[0] });
  };
}
module.exports = TraineeKarte;
