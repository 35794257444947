const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Link } = require("react-router-dom");

const DAYS = ["月", "火", "水", "木", "金", "土", "日"];

class Shifts extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();

    this.state = {
      date: "",
      reason: "",
      existingBlocks: [],
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: 1,
      hour: 9,
      blockedTimes: null,
    };
  }

  async componentDidMount() {
    await this.fetchData();
    await this.fetchPermanentBlockedTimes();
  }

  render() {
    const { year, month, day, hour } = this.state;
    const lastDay = new Date(year, month, 0).getDate();
    let daysArray = [];
    for (var i = 1; i < lastDay + 1; i++) daysArray.push(i);
    return (
      <div className="w-auto">
        <h2 className="mb-10">予約できない時間</h2>
        <p className="-mt-4 text-gray-600 text-center">
          所属ジムが設定されてない場合は
          <br />
          保存しても無効です！
        </p>
        <br />
        <form className="w-64 mx-auto">
          <div className="mt-4">
            <label>
              年
              <select
                name="prefecture"
                class="w-full px-2 py-1 h-10 text-gray-700 bg-gray-100 rounded mb-3 border-2"
                defaultValue={year}
                onChange={this.onChangeYear}
              >
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </label>
          </div>
          <div>
            <label>
              月
              <select
                name="prefecture"
                class="w-full px-2 py-1 h-10 text-gray-700 bg-gray-100 rounded mb-3 border-2"
                defaultValue={month}
                onChange={this.onChangeMonth}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </label>
          </div>
          <div>
            <label>
              日
              <select
                name="prefecture"
                class="w-full px-2 py-1 h-10 text-gray-700 bg-gray-100 rounded mb-3 border-2"
                defaultValue={day}
                onChange={this.onChangeDay}
              >
                {daysArray.map(d => (
                  <option value={d}>{d}</option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <label>
              時間
              <select
                name="prefecture"
                class="w-full px-2 py-1 h-10 text-gray-700 bg-gray-100 rounded mb-3 border-2"
                defaultValue={hour}
                onChange={this.onChangeHour}
              >
                <option value="7">7:00~</option>
                <option value="8">8:00~</option>
                <option value="9">9:00~</option>
                <option value="10">10:00~</option>
                <option value="11">11:00~</option>
                <option value="12">12:00~</option>
                <option value="13">13:00~</option>
                <option value="14">14:00~</option>
                <option value="15">15:00~</option>
                <option value="16">16:00~</option>
                <option value="17">17:00~</option>
                <option value="18">18:00~</option>
                <option value="19">19:00~</option>
                <option value="20">20:00~</option>
                <option value="21">21:00~</option>
                <option value="22">22:00~</option>
                <option value="23">23:00~</option>
              </select>
            </label>
          </div>
          <div className="mt-4">
            <label>
              理由（12文字以内）
              <input
                className="w-full border-2"
                value={this.state.reason}
                placeholder="店舗清掃のため"
                onChange={e => this.onChangeReason(e)}
              />
            </label>
          </div>
          <div className="mt-12 flex justify-center">
            <button className="btn" onClick={this.onClickSaveBtn}>
              保存
            </button>
          </div>
        </form>
        <p className="text-gray-600 text-center">
          予約できない時間が増えない時は
          <br />
          オーナーかマネジャーまで連絡を
        </p>
        <div className="mt-12 mb-8 flex justify-center">
          <Link to="/fixed_shifts">
            <button className="btn bg-green-500">毎週固定で設定</button>
          </Link>
        </div>
        <h2 className="mb-10 mx-auto">予定があり、予約できない時間一覧</h2>
        <p className="text-gray-600 text-center mx-auto">クリックして削除が可能です。</p>
        {this.state.existingBlocks.map(block => {
          return (
            <div className="centering mx-auto" onClick={e => this.onClickBlock(e, block.id)}>
              <div className="block-card bg-red-500 text-white rounded-lg shadow-md pl-2 pr-4 pb-3">
                <div className="reserve-card-title text-white">{`${block.date}  ${block.time}:00 ~`}</div>
                <div className="reserve-card-name text-white">{`${
                  String(block.reason) == "null" ? "固定ブロック" : block.reason
                }`}</div>
              </div>
            </div>
          );
        })}
        <h2 className="mb-10 mx-auto">以下、毎月の固定枠のため予約できない時間枠</h2>
        {this.renderBlockedTimes()}
      </div>
    );
  }

  renderBlockedTimes = () => {
    const { blockedTimes } = this.state;
    if (!blockedTimes) return <p className="text-gray-600 text-center">読み込み中...</p>;
    if (blockedTimes.length == 0)
      return <p className="text-gray-600 text-center">データが見つかりませんでした。</p>;
    console.log(blockedTimes);
    return (
      <div className="w-1/2 mx-auto">
        {DAYS.map(day => {
          const blockedTimesFilterByDay = blockedTimes
            .filter(bt => bt.day_of_week == day)
            .sort((a, b) => Number(a.gym.id) - Number(b.gym.id));
          return blockedTimesFilterByDay.map(blockedTime => (
            <div className="flex flex-row justify-end items-center w-full h-12 p-2 mb-4 bg-white rounded-lg shadow-md">
              <div className="ml-4 text-xs">{`${
                blockedTime.status
                  ? blockedTime.user.name.substr(0, 8) + "さん固定"
                  : "固定ブロック"
              }`}</div>
              <div className="ml-4 text-xs text-gray-500">
                {`${blockedTime.createdAt.split("T")[0]}`}作成
              </div>
              <div className="ml-4 text-xs">{`${blockedTime.gym.name}`}</div>
              <p className="ml-4 mr-8">
                {`${blockedTime.day_of_week}`}曜{` ${blockedTime.time}:00`}
              </p>
              <button className="delete-btn" onClick={() => this.onClickDeleteBtn(blockedTime.id)}>
                削除
              </button>
            </div>
          ));
        })}
      </div>
    );
  };

  fetchPermanentBlockedTimes = async () => {
    axios(
      `${urls.api_server}/controller/trainer/${localStorage.getItem("user_id")}/perm_block_times`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "GET",
      }
    )
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ blockedTimes: results.data.tempReservations });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onChangeYear = e => this.setState({ year: e.target.value });
  onChangeMonth = e => this.setState({ month: e.target.value });
  onChangeDay = e => this.setState({ day: e.target.value });
  onChangeHour = e => this.setState({ hour: e.target.value });

  onClickBlock = async (e, id) => {
    e.preventDefault();
    const post_data = { gym_id: localStorage.getItem("user_gym_ids") };
    if (window.confirm("予約できない時間を、削除しますか？")) {
      axios(`${urls.api_server}/controller/temp_blocks/${id}/delete`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        data: JSON.stringify(post_data),
      })
        .then(results => {
          if (results.status == 200) {
            alert("削除が完了しました。");
            return;
          }
          alert("削除できませんでした。少し経ってから再度お試しください。");
          return;
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  onChangeDate = async e => {
    this.setState({ date: e.target.value });
  };

  onChangeReason = async e => {
    this.setState({ reason: e.target.value });
  };

  onClickSaveBtn = async e => {
    e.preventDefault();
    const { year, month, day, hour, reason } = this.state;

    if (reason == "") {
      alert("「理由」を記述してください。");
      return;
    }
    let formatedMonth = String(month);
    let formatedDay = String(day);
    if (String(month).length == 1) formatedMonth = "0" + String(month);
    if (String(day).length == 1) formatedDay = "0" + String(day);
    const date = `${year}/${formatedMonth}/${formatedDay} ${hour}:00`;

    axios(`${urls.api_server}/controller/trainer/${localStorage.getItem("user_id")}/temp_block`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({ date, reason }),
    })
      .then(results => {
        if (results.status == 202) {
          alert("すでにお客様の予約が入っています。");
          return;
        }
        if (results.status == 203) {
          alert("すでに予約ブロックが入っています。");
          return;
        }
        if (results.status == 201) {
          alert("予約できない時間を追加しました。");
          this.setState({ date: "", reason: "" });
        }
        if (results.status === 404) {
          console.log("404");
          return;
        }
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchData = async () => {
    axios(`${urls.api_server}/controller/trainer/${localStorage.getItem("user_id")}/temp_blocks`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(results => {
        this.setState({
          existingBlocks: results.data.futureBlockedTempReservations,
        });
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  onClickDeleteBtn = async id => {
    axios(`${urls.api_server}/controller/blockedTime/${id}/delete`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
    })
      .then(results => {
        if (results.status == 201) {
          alert("削除しました。");
          let blockedTimes = this.state.blockedTimes;
          blockedTimes = blockedTimes.filter(blockedTime => blockedTime.id != id);
          this.setState({ blockedTimes });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

module.exports = Shifts;
