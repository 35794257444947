const React = require("react");
const axios = require("axios");
const urls = require("../env");

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
    };
  }
  componentDidMount() {
    this.get_payments();
  }
  render() {
    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 1");
    console.log(this.state.payments);
    return (
      <>
        <h2>塾生の決済一覧</h2>
        <table className="w-2/3">
          <thead>
            <tr>
              <th>{"ID"}</th>
              <th>{"決済日"}</th>
              <th>{"購入者"}</th>
              <th>{"商品名"}</th>
              <th>{"価格"}</th>
              <th>{"状態"}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.payments.map(p => (
              <tr>
                <td>{p.id}</td>
                <td>{p.date.split("T")[0]}</td>
                <td>{p.userName}</td>
                <td>{p.item}</td>
                <td>{p.price}</td>
                <td style={{ color: p.status ? "" : "red" }}>{`${p.status ? "成功" : "失敗"}`}</td>
              </tr>
            ))}
          </tbody>
        </table>{" "}
      </>
    );
  }
  get_payments = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/payments`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        console.log(results);
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState(results.data);
        if (localStorage.getItem("authority") === "owner") {
          const user_id = localStorage.getItem("user_id");
          axios({
            auth: {
              username: urls.basic_user,
              password: urls.basic_pass,
            },
            method: "GET",
            url: `${urls.api_server}/controller/owners/${user_id}/gym_ids`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then(results => {
              if (results.status != 200) {
                return;
              }
              const gymIds = results.data.gymIds;
              let modifiedPayments = this.state.payments.filter(p => {
                return gymIds.indexOf(p.gymId) != -1;
              });
              this.setState({ payments: modifiedPayments });
              return;
            })
            .catch(error => {
              return;
            });
        } else {
          let payments = this.state.payments.filter(
            payment => payment.gymId == Number(localStorage.getItem("user_gym_ids"))
          );
          this.setState({ payments: payments });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

module.exports = Payments;
