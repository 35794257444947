const React = require("react");
const axios = require("axios");
const urls = require("../env");

class Accountings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      gyms: [],
      selectedGym: "",
      users: [],
      selectedUser: "",
      items: [],
      selectedItem: "",
    };
  }

  async componentDidMount() {
    this.fetchGyms();
    this.fetchItems();
  }

  render() {
    return (
      <>
        <h2>経理処理</h2>
        <h4 className="w-64 mt-8 mx-auto text-red-600">
          ＊こちらはスマホを利用できない方用です。
        </h4>
        <div className="w-64 mx-auto mt-8">
          <div>
            <form>
              <div className="mt-4">
                <label>
                  所属ジム
                  <select
                    className="blog-select-btn w-48"
                    defaultValue={this.state.selectedGym}
                    onChange={this.onChangeSelectedGym}
                  >
                    <option value="-">-</option>
                    {this.state.gyms?.map((gym) => (
                      <option key={gym.id} value={`${gym.id}`}>
                        {gym.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mt-4">
                <label>
                  購入者
                  <select
                    className="blog-select-btn w-48"
                    defaultValue={this.state.selectedUser}
                    onChange={this.onChangeSelectedUser}
                  >
                    <option value="-">-</option>
                    {this.state.users?.map((user) => (
                      <option key={user.id} value={`${user.id}`}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mt-4">
                <label>
                  商品
                  <select
                    className="blog-select-btn w-48"
                    defaultValue={this.state.selectedItem}
                    onChange={this.onChangeSelectedItem}
                  >
                    <option value="-">-</option>
                    {this.state.items?.map((item) => (
                      <option key={item.id} value={`${item.id}`}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mt-12 flex justify-center">
                <button className="btn" onClick={(e) => this.onClickBtn(e)}>
                  現金による決済を保存
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  fetchGyms = () => {
    axios(`${urls.api_server}/controller/gyms`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchItems = async () => {
    axios(`${urls.api_server}/api/users/_/items`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ items: results.data.items });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchUsersOfSelectedGym = async (gymId) => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      url: `${urls.api_server}/controller/gyms/${gymId}/trainees`,
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ users: results.data.trainees });
        console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChangeSelectedGym = async (e) => {
    this.setState({ selectedGym: e.target.value });
    if (e.target.value != "" || e.target.value != "-") {
      await this.fetchUsersOfSelectedGym(e.target.value);
    }
  };

  onChangeSelectedUser = (e) => {
    this.setState({ selectedUser: e.target.value });
  };

  onChangeSelectedItem = (e) => {
    this.setState({ selectedItem: e.target.value });
  };

  onClickBtn = async (e) => {
    e.preventDefault();
    const { selectedGym, selectedItem, selectedUser } = this.state;
    if (
      selectedGym == "" ||
      selectedGym == "-" ||
      selectedItem == "" ||
      selectedItem == "-" ||
      selectedUser == "" ||
      selectedUser == "_"
    )
      return;
    if (window.confirm("本当に経理情報を保存しますか？")) {
      axios(`${urls.api_server}/controller/payments_cash`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        data: JSON.stringify({
          gymId: Number(selectedGym),
          itemId: Number(selectedItem),
          userId: Number(selectedUser),
        }),
      })
        .then((results) => {
          if (results.status === 404) {
            console.log("404");
            return;
          }
          if (results.status == 201) {
            alert("経理情報を記録しました。");
            return;
          } else {
            alert("問題が起こりました。一定時間後にやり直してください。");
            return;
          }
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}

module.exports = Accountings;
