const React = require("react");
const ReactDOM = require("react-dom");
const Header = require("./components/header");
const Footer = require("./components/footer");
const Auth = require("./auth");
const Login = require("./pages/login");
const Top = require("./pages/top");
const Management = require("./pages/management");
const Trainers = require("./pages/trainers");
const TrainerAdd = require("./pages/trainer_add");
const TraineeAdd = require("./pages/trainee_add");
const Shifts = require("./pages/shifts");
const FixedShifts = require("./pages/fixed_shifts");
const Reservations = require("./pages/reservations");
const Blogs = require("./pages/blogs");
const BlogAdd = require("./pages/blog_add");
const BlogEdit = require("./pages/blog_edit");
const Records = require("./pages/records");
const Payments = require("./pages/payments");
const Accountings = require("./pages/accountings");
const Sales = require("./pages/sales");
const SalesShowMonth = require("./pages/sales_show_month");
const Notifications = require("./pages/notifications");
const Settings = require("./pages/settings");
const Manuals = require("./pages/manuals");
const TrainerEdit = require("./pages/trainer_edit");
const TrainerShow = require("./pages/trainer_show");
const TraineeShow = require("./pages/trainee_show");
const TraineeGym = require("./pages/trainee_gym");
const TraineeKarte = require("./pages/trainee_karte");
const TraineeKarteShow = require("./pages/trainee_karte_show");
const TraineeMenus = require("./pages/trainee_menus");
const TraineeMenuShow = require("./pages/trainee_menu_show");
const TraineeMenuCreate = require("./pages/trainee_menu_create");
const TraineeShowTicket = require("./pages/trainee_show_tickets");
const TraineePayments = require("./pages/trainee_payments");
const TraineeFixReservation = require("./pages/trainee_fix_reservation");
const TraineeCreateReservation = require("./pages/trainee_create_reservation");
const TraineeCancelReservation = require("./pages/trainee_cancel_reservation");
const TraineeReceiveCash = require("./pages/trainee_receive_cash");
const TraineeQuitSubscription = require("./pages/trainee_quit_subscription");
const GymShow = require("./pages/gym_show");
const GymNew = require("./pages/gym_new");
const HpEdit = require("./pages/hp_edit");
const EC = require("./pages/ec");
const ECProducts = require("./pages/ec_products");
const ECProductEdit = require("./pages/ec_product_edit");
const ECProductNew = require("./pages/ec_product_new");
const ECUsers = require("./pages/ec_users");
const ECSales = require("./pages/ec_sales");
const ECSalesShow = require("./pages/ec_sales_show");
const Trials = require("./pages/trials");
const TrialsShow = require("./pages/trials_show");
const CvRoutes = require("./pages/CvRoutes");
const CvRoutesAll = require("./pages/CvRoutesAll");
const CvRoutesGyms = require("./pages/CvRoutesGyms");
const CvRoutesGymsShow = require("./pages/CvRoutesGymsShow");
const { BrowserRouter, Route, Switch } = require("react-router-dom");
const GymFeatureEdit = require("./pages/gym_features_edit");
const GymFeaturesIndex = require("./pages/gyms/gym_features");
const GymFeaturesNew = require("./pages/gyms/gym_features_new");
const ReviewsIndex = require("./pages/reviews");
const ReviewsNew = require("./pages/reviews_new");
const ReviewsEdit = require("./pages/reviews_edit");
const Advertisements = require("./pages/advertisements");
const GymsAdvertisements = require("./pages/gyms_advertisements");
const GymsAdvertisementsShow = require("./pages/gyms_advertisements_show");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
    };
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/server/*" />
            <Route exact path="/socket/*" />
            <Auth>
              <Switch>
                <Route exact path="/" component={Top} />
                <Route exact path="/management" component={Management} />
                {/* <Route exact path="/" component={TraineeShow} /> */}
                <Route exact path="/trainers" component={Trainers} />
                <Route exact path="/trainer_add" component={TrainerAdd} />
                <Route exact path="/trainee_add" component={TraineeAdd} />
                <Route exact path="/shifts" component={Shifts} />
                <Route exact path="/fixed_shifts" component={FixedShifts} />
                <Route exact path="/reservations" component={Reservations} />
                <Route exact path="/blogs" component={Blogs} />
                <Route exact path="/blog_add" component={BlogAdd} />
                <Route exact path="/blog_edit/:id" component={BlogEdit} />
                <Route exact path="/records" component={Records} />
                <Route exact path="/payments" component={Payments} />
                <Route exact path="/accountings" component={Accountings} />
                <Route exact path="/sales" component={Sales} />
                <Route exact path="/sales/show" component={SalesShowMonth} />
                <Route exact path="/notifications" component={Notifications} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/manuals" component={Manuals} />
                <Route exact path="/trainer_edit/:id" component={TrainerEdit} />
                <Route exact path="/trainer_show/:id" component={TrainerShow} />
                <Route exact path="/trainee_show/:id" component={TraineeShow} />
                <Route exact path="/trainee_show/:id/trainee_gym" component={TraineeGym} />
                <Route exact path="/trainee_show/:id/trainee_karte" component={TraineeKarte} />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_karte_show"
                  component={TraineeKarteShow}
                />
                <Route exact path="/trainee_show/:id/trainee_menus" component={TraineeMenus} />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_menu/:menuId"
                  component={TraineeMenuShow}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_menu_create"
                  component={TraineeMenuCreate}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_payments"
                  component={TraineePayments}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_fix_reservation"
                  component={TraineeFixReservation}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_create_reservation"
                  component={TraineeCreateReservation}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_cancel_reservation"
                  component={TraineeCancelReservation}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_receive_cash"
                  component={TraineeReceiveCash}
                />
                <Route
                  exact
                  path="/trainee_show/:id/trainee_quit_subscription"
                  component={TraineeQuitSubscription}
                />
                <Route exact path="/trainee_show/:id/tickets" component={TraineeShowTicket} />
                <Route exact path="/gym_show/:id" component={GymShow} />
                <Route exact path="/gym_new" component={GymNew} />
                <Route exact path="/gym_features_edit/:id" component={GymFeatureEdit} />
                <Route exact path="/gyms/:id/gym_features" component={GymFeaturesIndex} />
                <Route exact path="/gyms/:id/gym_features_new" component={GymFeaturesNew} />
                <Route exact path="/gyms/:id/reviews" component={ReviewsIndex} />
                <Route exact path="/gyms/:id/reviews_new" component={ReviewsNew} />
                <Route exact path="/reviews_edit/:id" component={ReviewsEdit} />
                <Route exact path="/hp_edit" component={HpEdit} />
                <Route exact path="/ec" component={EC} />
                <Route exact path="/ec/products" component={ECProducts} />
                <Route exact path="/ec/product_new" component={ECProductNew} />
                <Route exact path="/ec/product/:id/edit" component={ECProductEdit} />
                <Route exact path="/ec/users" component={ECUsers} />
                <Route exact path="/ec/sales" component={ECSales} />
                <Route exact path="/ec/sales/show" component={ECSalesShow} />
                <Route exact path="/trials" component={Trials} />
                <Route exact path="/trials/show/:id" component={TrialsShow} />
                <Route exact path="/cv_routes" component={CvRoutes} />
                <Route exact path="/cv_routes/all" component={CvRoutesAll} />
                <Route exact path="/cv_routes/gyms" component={CvRoutesGyms} />
                <Route exact path="/cv_routes/gyms/show/:id" component={CvRoutesGymsShow} />
                <Route exact path="/advertisements" component={Advertisements} />
                <Route exact path="/gyms/advertisements/" component={GymsAdvertisements} />
                <Route exact path="/gyms/:id/advertisements/" component={GymsAdvertisementsShow} />
              </Switch>
            </Auth>
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
