const axios = require("axios");
const urls = require("./env");
class Axios {
  is_login = () => localStorage.getItem("authority")=="trainee" ? false : this.get("isLoggedIn") == "true";
  set = (key, value) => localStorage.setItem(key, value);
  get = (key) => this.getLocalStorage(key);
  getLocalStorage = (key) => {
    const ret = localStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return null;
  };

  login = async (email, password) => {
    if (email === "" || password === "") return;
    await axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/api/sessions/login`,
      data: {
        email: email,
        password: password,
        agreement: true,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(async (results) => {
        if (results.status === 404) {
          console.log("404");
          // this.setState({ emailOrPasswordWrong: true });
          return;
        }
        const response = results.data;
        const { id } = response.current_user;
        this.set("isLoggedIn", true);
        this.set("user_id", id);
        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  logout = () => {
    if (this.is_login()) {
      this.set("isLoggedIn", false);
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "POST",
        url: `${urls.api_server}/api/sessions/logout`,
        data: {
          id: this.get("user_id"),
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (results) => {
          if (results.status === 404) {
            console.log("404");
            return;
          }
          localStorage.removeItem("user_id");
          return false;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}

module.exports = Axios;
