const React = require("react");
const { Link } = require("react-router-dom");
const axios = require("axios");
const urls = require("../env");

class TraineeShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      traineeId: 1,
      traineeName: "",
      traineeImg: "",
      traineeAuthority: "",
    };
  }
  componentDidMount() {
    if (this.props.location.aboutProps != undefined) {
      const { id, name, img, authority } = this.props.location.aboutProps.trainee;
      localStorage.setItem("traineeId", id);
      localStorage.setItem("traineeName", name);
      localStorage.setItem("traineeImg", img);
      localStorage.setItem("traineeAuthority", authority);
    }
    const id = localStorage.getItem("traineeId");
    const name = localStorage.getItem("traineeName");
    const img = localStorage.getItem("traineeImg");
    const authority = localStorage.getItem("traineeAuthority");
    this.setState({
      traineeId: id,
      traineeName: name,
      traineeImg: img,
      traineeAuthority: authority,
    });
  }

  render() {
    const { traineeId, traineeName, traineeImg } = this.state;
    const btns = [
      {
        name: "所属ジム",
        url: `/trainee_show/${traineeId}/trainee_gym`,
        authority: "all",
      },
      {
        name: "チケット調整",
        url: `/trainee_show/${traineeId}/tickets`,
        authority: "manager",
      },
      {
        name: "初回カルテ作成",
        url: `/trainee_show/${traineeId}/trainee_karte`,
        authority: "all",
      },
      {
        name: "初回カルテ確認",
        url: `/trainee_show/${traineeId}/trainee_karte_show`,
        authority: "all",
      },
      {
        name: "トレーニングメニュー記述",
        url: `/trainee_show/${traineeId}/trainee_menu_create`,
        authority: "all",
      },
      {
        name: "トレーニングメニュー確認",
        url: `/trainee_show/${traineeId}/trainee_menus`,
        authority: "all",
      },
      {
        name: "決済情報",
        url: `/trainee_show/${traineeId}/trainee_payments`,
        authority: "all",
      },
      {
        name: "予約を固定",
        url: `/trainee_show/${traineeId}/trainee_fix_reservation`,
        authority: "all",
      },
      {
        name: "予約を作成",
        url: `/trainee_show/${traineeId}/trainee_create_reservation`,
        authority: "all",
      },
      {
        name: "予約をキャンセル",
        url: `/trainee_show/${traineeId}/trainee_cancel_reservation`,
        authority: "all",
      },
      {
        name: "現金受け取り",
        url: `/trainee_show/${traineeId}/trainee_receive_cash`,
        authority: "all",
      },
      {
        name: "退会（月額サブスク解除）",
        url: `/trainee_show/${traineeId}/trainee_quit_subscription`,
        authority: "all",
      },
    ];
    return (
      <>
        <div className="centering mt-4">
          <img
            className="w-1/6 mt-4 rounded-full border-solid border-4 border-gray-600"
            src={traineeImg}
          />
        </div>
        <h2 className="centering">{traineeName}</h2>
        <div style={{ marginTop: 20 }}>
          <div className="centering">ユーザー権限:{this.renderEditAuthority()}</div>
        </div>
        <div className="mt-12">
          {btns.map(btn => {
            if (btn.authority === "manager" && localStorage.getItem("authority") !== "manager") {
              return <></>;
            }
            return (
              <Link
                to={{
                  pathname: `${btn.url}`,
                  aboutProps: {
                    traineeId: traineeId,
                  },
                }}
              >
                <div className="bg-orange-600 w-56 py-3 rounded mx-auto mb-5 text-white text-center">
                  {btn.name}
                </div>
              </Link>
            );
          })}
        </div>
      </>
    );
  }

  renderEditAuthority = () => {
    const { traineeAuthority } = this.state;
    const AUTHORITY = {
      trainee: "塾生",
      trainer: "トレーナー",
      owner: "オーナー",
      manager: "管理者",
    };

    if (localStorage.getItem("authority") == "manager") {
      return (
        <select
          style={{ borderWidth: 1, marginLeft: 4 }}
          onChange={event => this.onChangeAuthority(event.target.value)}
        >
          {Object.keys(AUTHORITY).map(key => (
            <option value={key} selected={traineeAuthority == key}>
              {AUTHORITY[key]}
            </option>
          ))}
        </select>
      );
    }
    return (
      <span style={{ fontWeight: "bold", marginLeft: 10 }}>{AUTHORITY[traineeAuthority]}</span>
    );
  };

  onChangeAuthority = value => {
    const { traineeId } = this.state;
    axios(`${urls.api_server}/controller/users/${traineeId}/authority`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "PATCH",
      mode: "cors",
      data: JSON.stringify({
        authority: value,
      }),
    })
      .then(results => {
        if (results.status == 201) {
          const { authority } = results.data;
          alert(authority + " 権限への変更が完了しました。");
          this.setState({ traineeAuthority: authority });
          localStorage.setItem("traineeAuthority", authority);
        } else {
          alert("変更に失敗しました。");
        }
        console.log(results);
      })
      .catch(error => {
        alert("変更に失敗しました。");
        console.log(error);
      });
  };
}

module.exports = TraineeShow;
