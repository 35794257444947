const React = require("react");
const urls = require("../env");
const axios = require("axios");
const Button = require("../components/button");

class SalesShowMonth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gym: "",
      year: 0,
      month: 0,
      payments: [],
      paymentDate: "",
      paymentId: "",
      sumOfCashPayments: 0,
    };
  }

  async componentDidMount() {
    const { gym, year, month, payments } = this.props.location.state;
    this.setState({ gym, year, month, payments });
    this.calcSumOfCashPayments(payments);
  }

  render() {
    const { gym, year, month, payments, sumOfCashPayments } = this.state;
    return (
      <>
        <h2>{`${year}年 ${month}月 ${gym}の売上`}</h2>
        <table className="w-1/3">
          <tbody>
            <tr>
              <td>現金決済合計額(税込)</td>
              <td>{sumOfCashPayments.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <table className="w-4/5">
          <thead>
            <tr>
              <th>{"プラン"}</th>
              <th>{"金額"}</th>
              <th>{"ユーザーID/名前"}</th>
              <th>{"現金支払"}</th>
              <th>{"日付"}</th>
              <th>{""}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {payments.map(p => (
              <tr>
                <td className="text-center">{`${p.item_name}`}</td>
                <td className="text-center">{`${Number(
                  p.item_price + p.item_price / 10
                ).toLocaleString()}`}</td>
                <td className="text-center">{`${p.paying_user_id} / ${p.paying_user_name}`}</td>
                <td className="text-center">{`${p.payment_type == "cash" ? "🙆‍♀️" : ""}`}</td>
                <td className="text-center">
                  <input
                    type="date"
                    defaultValue={p.created_at.split("T")[0]}
                    onChange={e => this.onChangeDate(e, p)}
                  ></input>
                </td>
                <td>
                  {localStorage.getItem("authority") === "manager" ? (
                    <button className="btn" onClick={() => this.onSubmit(p)}>
                      更新
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {localStorage.getItem("authority") === "manager" ? (
                    <Button name="削除" method={() => this.onClickDelete(p.payment_id)} />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  onClickDelete = id => {
    if (window.confirm("本当に削除しますか？")) {
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "GET",
        url: `${urls.api_server}/controller/payments/${id}/delete`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(results => {
          if (results.status != 201) {
            alert(
              "削除が失敗しました。もう一度お試しいただきうまくいかなければ、開発者にご連絡ください。"
            );
            return;
          }
          alert("削除が完了しました。");
          return;
        })
        .catch(error => {
          return;
        });
    }
  };

  onChangeDate = (e, p) => {
    const paymentCreatedDate = e.target.value;
    this.setState({ paymentDate: paymentCreatedDate, paymentId: p.payment_id });
  };

  calcSumOfCashPayments = payments => {
    let sumOfCashPayments = 0;
    payments.map(p => {
      if (p.payment_type == "cash") {
        sumOfCashPayments += p.item_price + p.item_price / 10;
      }
    });

    this.setState({ sumOfCashPayments });
  };

  onSubmit = p => {
    // 対象の日付変更していないで更新、もしくは日付を更新したあと、別の支払いの日時を変更せずに更新した場合の対応。
    let paymentChangeddDate = "";
    if (p.payment_id == this.state.paymentId) {
      paymentChangeddDate = this.state.paymentDate;
    } else {
      return alert("日付を変更してください");
    }
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "PATCH",
      url: `${urls.api_server}/controller/payments/${p.payment_id}/`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
      data: JSON.stringify({
        paymentCreatedDate: paymentChangeddDate,
      }),
    })
      .then(results => {
        if (results.status === 404) {
          alert(
            "変更に失敗しました。もう一度お試しいただきうまくいかなければ、開発者にご連絡ください"
          );
          return;
        }
        if (results.status == 201) {
          alert("変更が完了しました。");
        }
        const paymentCreatedDate = results.data.paymentCreatedDate;
        this.setState({
          paymentDate: paymentCreatedDate,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}

module.exports = SalesShowMonth;
