const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TrainerAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
    };
  }

  render() {
    return (
      <div className="w-64 mx-auto">
        <h2 className="mb-10">トレーナーの登録</h2>
        <form>
          <div className="mt-4">
            <label>
              名前
              <input
                className="w-auto"
                value={this.state.date}
                onChange={(e) => this.onChangeName(e)}
              />
            </label>
          </div>
          <div className="mt-4">
            <label>
              メールアドレス
              <input
                className="w-auto"
                value={this.state.date}
                onChange={(e) => this.onChangeEmail(e)}
              />
            </label>
          </div>
          <div className="mt-12 flex justify-center">
            <button className="btn" onClick={this.onClickSaveBtn}>
              トレーナー登録
            </button>
          </div>
        </form>
      </div>
    );
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  onClickSaveBtn = async (e) => {
    e.preventDefault();
    const { name, email } = this.state;
    if (name == "") return;
    const regex = new RegExp(
      /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/
    );
    if (!regex.test(email)) return;

    axios(`${urls.api_server}/controller/trainer_register`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({ name, email }),
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          alert(
            "トレーナー登録が失敗しました。"
          );
          return;
        }
        if (results.status == 201) {
          alert(
            "トレーナー登録が完了しました。ご登録のメールアドレスをご確認ください。"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(
          "トレーナー登録が失敗しました。"
        );
      });
  };
}

module.exports = TrainerAdd;
