const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { renderMDText } = require("../helper/render_md_text");
const marked = require("marked");

class TrainerEdit extends React.Component {
  constructor(props) {
    super(props);
    const trainerProfileId = this.props.match.params.id;
    this.state = {
      trainerProfileId: 0,
      trainerName: "",
      interviewText: null,
      interviewThumb: null,
      interviewThumbFile: null,
      interviewTextImageFile: null,
      localStorageKeyForText: `interview_text_${trainerProfileId}`,
      localStorageKeyForThumb: `interview_thumb_${trainerProfileId}`,
    };
  }

  componentDidMount() {
    marked.setOptions({ gfm: true, breaks: true });
    this.fetchTrainerInfo();
  }

  fetchTrainerInfo = () => {
    const trainerProfileId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/trainer_profiles/${trainerProfileId}`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        const { id, name, interview_text, interview_thumb } = results.data;

        this.setStateInterviewValue({ interview_text, interview_thumb });
        this.setState({
          trainerProfileId: id,
          trainerName: name,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  setStateInterviewValue = (valuesFromAPI = {}) => {
    const { localStorageKeyForText, localStorageKeyForThumb } = this.state;
    const { interview_text, interview_thumb } = valuesFromAPI;
    let interviewText = localStorage.getItem(localStorageKeyForText);
    let interviewThumb = localStorage.getItem(localStorageKeyForThumb);
    if (!!interviewText && !!interviewThumb) {
      return this.setState({ interviewText, interviewThumb });
    } else if (!!interviewText) {
      return this.setState({
        interviewText,
        interviewThumb: interview_thumb,
      });
    } else if (!!interviewThumb) {
      return this.setState({
        interviewText: interview_text,
        interviewThumb,
      });
    }
    this.setState({
      interviewText: interview_text,
      interviewThumb: interview_thumb,
    });
  };

  onChangeInterviewThumbFile = e => {
    this.setState({ interviewThumbFile: e.target.files[0] });
  };
  onChangeInterviewThumb = e => {
    this.setState({ interviewThumb: e.target.value });
  };
  onChangeInterviewTextImageFile = e => {
    this.setState({ interviewTextImageFile: e.target.files[0] });
  };
  onChangeInterviewText = e => {
    // const interviewText = e.target.value.replace(/\r?\n/g , '\n\n')
    const interviewText = e.target.value;
    // const interviewText = e.target.value.replace(/replaced_text/g, '')
    this.setInterviewText(interviewText);
  };
  mouseOver = () => this.setState({ hover: true });
  mouseOut = () => this.setState({ hover: false });

  setInterviewText = interviewText => {
    const { localStorageKeyForText } = this.state;
    localStorage.setItem(localStorageKeyForText, interviewText);
    this.setState({ interviewText });
  };

  onImageUpload = (e, type) => {
    this.imageUpload(type);
    return e.preventDefault();
  };
  imageUpload = type => {
    let formKey, formValue;
    const { interviewTextImageFile, interviewThumbFile } = this.state;
    switch (type) {
      case "interviewTextImage":
        formKey = "interview_text_image";
        formValue = interviewTextImageFile;
        break;
      case "interviewThumb":
      default:
        formKey = "interview_thumb";
        formValue = interviewThumbFile;
        break;
    }

    const formData = new FormData();
    formData.append(formKey, formValue);
    const config = {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        `${urls.api_server}/controller/trainer_profiles/image_upload/${formKey}`,
        formData,
        config
      )
      .then(response => {
        this.onUploaedImage(response.data.image_url, type);
        alert("アップロードが完了しました  url: " + response.data.image_url);
      })
      .catch(error => {
        alert("アップロードに失敗しました" + error);
        console.log(error);
      });
  };
  onUploaedImage = (val, type) => {
    switch (type) {
      case "interviewTextImage":
        let { interviewText } = this.state;
        interviewText += `\n \n![画像タイトル](${val}) \n`;
        this.setInterviewText(interviewText);
        break;
      case "interviewThumb":
      default:
        localStorage.setItem(this.state.localStorageKeyForThumb, val);
        this.setState({ interviewThumb: val });
        break;
    }
  };

  onSubmit = async e => {
    e.preventDefault();
    const { interviewText, interviewThumb } = this.state;

    const trainerProfileId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/trainer_profiles/${trainerProfileId}`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "PATCH",
      mode: "cors",
      data: JSON.stringify({
        interview_text: interviewText,
        interview_thumb: interviewThumb,
      }),
    })
      .then(results => {
        if (results.status == 201) {
          alert("変更が完了しました。");
          this.clearLocalStorage();
        }
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
      })
      .catch(error => {
        console.log(error);
      });
  };

  clearLocalStorage = () => {
    const { localStorageKeyForText, localStorageKeyForThumb } = this.state;
    localStorage.removeItem(localStorageKeyForText);
    localStorage.removeItem(localStorageKeyForThumb);
  };

  render() {
    const { trainerProfileId, trainerName, interviewText, interviewThumb } = this.state;

    const link = `https://katagirijuku.jp/trainers/${trainerProfileId}/interview`;

    return (
      <>
        <h2>トレーナー：{trainerName} の記事</h2>
        <div className="mt-12 flex justify-center">
          記事リンク：
          <a className="blog-link" href={link}>
            {link}
          </a>
          <br />
        </div>
        <div className="form-card">
          <form className="blog" style={{ width: "100%" }}>
            <div className="mt-4 flex">
              <label>
                サムネイル画像
                <div
                  className="preview"
                  onMouseOver={this.mouseOver.bind(this)}
                  onMouseOut={this.mouseOut.bind(this)}
                >
                  ◆
                  {this.state.hover ? (
                    <img className="preview-image" src={(() => interviewThumb)()} />
                  ) : null}
                </div>
                <input
                  style={{ marginTop: 10 }}
                  className="blog-thumbnail"
                  placeholder="記事のサムネイル画像（https://~~）"
                  defaultValue={interviewThumb}
                  onChange={e => this.onChangeInterviewThumb(e)}
                />
              </label>
              <label className="mt-4 flex" style={{ flexDirection: "row", marginLeft: 30 }}>
                <input
                  className="mt-4"
                  type="file"
                  onChange={e => this.onChangeInterviewThumbFile(e)}
                />
                <button
                  type="button"
                  className="btn mt-4 centering"
                  onClick={e => this.onImageUpload(e, "interviewThumb")}
                >
                  アップロード
                </button>
                <br />
                <br />
              </label>
            </div>
            <div className="mt-4 flex" style={{ flexDirection: "row", marginTop: 20 }}>
              <label style={{ flex: 1, maxWidth: "50%" }}>
                インタビュー記事（
                <a
                  className="blog-link"
                  href="https://media.katagirijuku.jp/health/how-to-markdown"
                >
                  書き方
                </a>
                ）<br />
                <textarea
                  // className="blog-content"
                  style={{
                    width: "100%",
                    height: 1000,
                    marginTop: 10,
                  }}
                  placeholder="記事本文（マークダウンで記述）"
                  defaultValue={interviewText}
                  onChange={this.onChangeInterviewText}
                ></textarea>
                <label className="mt-4 flex" style={{ flexDirection: "row", marginLeft: 30 }}>
                  <input
                    className="mt-4"
                    type="file"
                    onChange={e => this.onChangeInterviewTextImageFile(e)}
                  />
                  <button
                    type="button"
                    className="btn mt-4 centering"
                    onClick={e => this.onImageUpload(e, "interviewTextImage")}
                  >
                    画像を挿入
                  </button>
                  <br />
                  <br />
                </label>
              </label>
              <label style={{ flex: 1, paddingLeft: 20, maxWidth: "50%" }}>
                プレビュー
                <div
                  style={{
                    marginTop: 20,
                  }}
                  className={"markdownText"}
                  dangerouslySetInnerHTML={renderMDText(interviewText)}
                />
              </label>
            </div>
            <br />
            <div className="mt-12 flex justify-center">{this.renderSubmitButton()}</div>
          </form>
        </div>
      </>
    );
  }

  renderSubmitButton = () => {
    const { interviewText, interviewThumb } = this.state;
    if (!!interviewText && !!interviewThumb) {
      return (
        <button className="btn" type="submit" onClick={e => this.onSubmit(e)}>
          この内容で投稿する
        </button>
      );
    }

    let NoInputValue = "";
    if (!interviewText) NoInputValue += "インタビュー記事 ";
    if (!interviewThumb) NoInputValue += "サムネイル画像 ";
    const alertMessage = `${NoInputValue} を入力してください`;

    return (
      <button type="button" className="btn-off" onClick={() => alert(alertMessage)}>
        この内容で投稿する
      </button>
    );
  };
}

module.exports = TrainerEdit;
