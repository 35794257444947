const React = require("react");
const axios = require("axios");
const envs = require("../env");

class ECProductEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      slug: "",
      image: "",
      price: 0,
      type: 2,
      item_id: "",
      content: "",
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  onChangeName = (e) => this.setState({ name: e.target.value });
  onChangeImage = (e) => this.setState({ image: e.target.value });
  onChangeContent = (e) => this.setState({ content: e.target.value });

  render() {
    return (
      <>
        <h2>商品の編集</h2>
        <div className="form-card">
          <form className="blog">
            <div className="mt-4 flex justify-between">
              <label>
                商品名
                <input
                  className="blog-title"
                  placeholder="商品名"
                  defaultValue={this.state.name}
                  onChange={this.onChangeName}
                />
              </label>
              <label>
                スラグ（変更編集不可）
                <p className="blog-slug text-center mt-3 text-gray-400">
                  {this.state.slug}
                </p>
              </label>
              <label>
                商品画像
                <input
                  className="blog-category"
                  placeholder="記事のサムネイル画像（https://~~）"
                  defaultValue={this.state.image}
                  onChange={this.onChangeImage}
                />
              </label>
            </div>
            <div className="mt-4 flex justify-between">
              <label>
                税抜価格（カンマ「,」不要）
                <p className="blog-slug text-center mt-3 text-gray-400">
                  {this.state.price}
                </p>
              </label>
              　
              <label>
                商品タイプ（1.毎月 / 2.単発）
                <p className="blog-slug text-center mt-3 text-gray-400">
                  {this.state.type}
                </p>
              </label>
              <label>
                アイテムID
                <p className="blog-slug text-center mt-3 text-gray-400">
                  {this.state.item_id}
                </p>
              </label>
            </div>
            <div className="mt-4">
              <label>
                商品説明（
                <a
                  className="blog-link"
                  href="https://media.katagirijuku.jp/health/how-to-markdown"
                >
                  書き方
                </a>
                ）<br />
                <textarea
                  className="blog-content"
                  placeholder="商品説明・本文（マークダウンで記述）"
                  defaultValue={this.state.content}
                  onChange={this.onChangeContent}
                ></textarea>
              </label>
            </div>
            <div className="mt-12 flex justify-center">
              <button className="btn" onClick={(e) => this.onClickBtn(e)}>
                この内容で公開する
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  onClickBtn = async (e) => {
    e.preventDefault();
    const itemId = this.props.match.params.id;
    const { name, slug, image, price, type, item_id, content } = this.state;

    if (
      name == "" ||
      slug == "" ||
      image == "" ||
      price == 0 ||
      type == 0 ||
      item_id == "" ||
      content == ""
    ) {
      alert("入力されていない項目があります。再度確認ください。");
      return;
    }

    if (type != 1 && type != 2) {
      alert("商品タイプは 1 または 2 のどちらかを記述してください。");
      return;
    }

    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 1");
    console.log(itemId);

    // 商品の編集保存
    axios(`${envs.api_server}/ec/item/${itemId}/edit`, {
      auth: {
        username: envs.basic_user,
        password: envs.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({
        name,
        slug,
        price,
        payment_type: type,
        item_id,
        image,
        content,
      }),
    }).then((results) => {
      if (results.status != 200) {
        alert(
          "何らかの理由で現在アプリに不具合が生じています。\n一定時間後にお試しいただき、それでもダメであればご連絡ください。"
        );
        return;
      }
      alert("商品を登録・公開しました。");
    });
  };

  fetchData = async () => {
    const itemId = this.props.match.params.id;
    axios(`${envs.api_server}/ec/item/${itemId}`, {
      auth: {
        username: envs.basic_user,
        password: envs.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status != 200) {
          alert("何らかの不具合が生じています。");
          return;
        }
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 1");
        console.log(results.data);
        this.setState({
          name: results.data.item.name,
          slug: results.data.item.slug,
          image: results.data.item.image,
          price: results.data.item.price,
          type: results.data.item.payment_type == "subscription" ? 1 : 2,
          item_id: results.data.item.item_id,
          content: results.data.item.discription,
        });
      })
      .catch((error) => {
        console.log(error);
        alert("何らかの不具合が生じています。");
      });
  };
}

module.exports = ECProductEdit;
