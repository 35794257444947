const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Redirect } = require('react-router-dom');

class BlogEdit extends React.Component {
  constructor(props) {
    const now = new Date();
    super(props);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.mouseOver2 = this.mouseOver2.bind(this);
    this.mouseOut2 = this.mouseOut2.bind(this);
    this.mouseOver3 = this.mouseOver3.bind(this);
    this.mouseOut3 = this.mouseOut3.bind(this);
    if (props.location.aboutProps && !localStorage.getItem(props.location.pathname+'/title')) {
      let published_flag = props.location.aboutProps.blog.published == true ? 1 : 0;
      let unlisted_flag = props.location.aboutProps.blog.unlisted == true ? 1 : 0;
      this.setLocalStorage(props);
      this.state = {
        title: props.location.aboutProps.blog.title,
        description: props.location.aboutProps.blog.description,
        slug: props.location.aboutProps.blog.slug,
        category: props.location.aboutProps.blog.category,
        author: 1,
        published: published_flag,
        unlisted: unlisted_flag,
        post_date: props.location.aboutProps.blog.post_date,
        edit_date: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
        thumbnail: props.location.aboutProps.blog.thumbnail,
        sns_card: props.location.aboutProps.blog.sns_card,
        content: props.location.aboutProps.blog.content,
        banner: props.location.aboutProps.blog.banner,
        banner_link: props.location.aboutProps.blog.banner_link,
        tags: props.location.aboutProps.blog.tags,
        title_count: 0,
        description_count: 0,
        redirect: false,
        slugs: props.location.aboutProps.slugs,
        slug_check: String(props.location.aboutProps.slugs).replace("[","").replace("]","").replace(/\"/g,"").split(",").indexOf(props.location.aboutProps.blog.slug) == -1 ? "使用可能なスラグです" : props.location.aboutProps.blog.slug != "" ? "このスラグは使用できません" : "スラグは入力必須です",
        hover: false,
        hover2: false,
        hover3: false,
        reload: false
      }
    } else {
      if (!localStorage.getItem(props.location.pathname+'/title')) {
        this.state = {
          redirect: true
        }
      } else {
        let title_length = localStorage.getItem(props.location.pathname+'/title') ? localStorage.getItem(props.location.pathname+'/title').length : 0;
        let description_length = localStorage.getItem(props.location.pathname+'/description') ? localStorage.getItem(props.location.pathname+'/description').length : 0;
        this.state = {
          title: localStorage.getItem(props.location.pathname+'/title'),
          description: localStorage.getItem(props.location.pathname+'/description'),
          slug: localStorage.getItem(props.location.pathname+'/slug'),
          category: localStorage.getItem(props.location.pathname+'/category'),
          author: 1,
          published: Number(localStorage.getItem(props.location.pathname+'/published')),
          unlisted: Number(localStorage.getItem(props.location.pathname+'/unlisted')),
          post_date: localStorage.getItem(props.location.pathname+'/post_date'),
          edit_date: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
          thumbnail: localStorage.getItem(props.location.pathname+'/thumbnail'),
          sns_card: localStorage.getItem(props.location.pathname+'/sns_card'),
          content: localStorage.getItem(props.location.pathname+'/content'),
          banner: localStorage.getItem(props.location.pathname+'/banner'),
          banner_link: localStorage.getItem(props.location.pathname+'/banner_link'),
          tags: localStorage.getItem(props.location.pathname+'/tags'),
          title_count: title_length,
          description_count: description_length,
          redirect: false,
          slugs: localStorage.getItem(props.location.pathname+'/slugs'),
          slug_check: localStorage.getItem(props.location.pathname+'/slugs').replace("[","").replace("]","").replace(/\"/g,"").split(",").indexOf(localStorage.getItem(props.location.pathname+'/slug')) == -1 ? "使用可能なスラグです" : "このスラグは使用できません",
          hover: false,
          hover2: false,
          hover3: false,
          reload: false
        }
      }
    }
  }

  renderRedirect = () => {
    if (this.state.reload) {
      // window.location.reload();
    }
    if (this.state.redirect) {
      return <Redirect to='/blogs' />
    }
  }

  render() {
    // if (localStorage.getItem(this.props.location.pathname+"/slugs")!=null) this.setState({reload: true});
    return (
      <>
        {this.renderRedirect()}
        <h2>記事の編集</h2>
        <div className="form-card">
          <form className="blog">
            <div className="mt-4 flex">
              <label>
                タイトル（文字数：{this.state.title_count}/34）
                <input
                  className="blog-title"
                  placeholder="記事のタイトル"
                  defaultValue={this.state.title}
                  onChange={this.onChangeTitle}
                  onKeyUp={e => this.handleCountChangeTitle(this.state.title, e.target.value.length)}
                />
              </label>
              　
              <label>
                カテゴリ（<span className="slug-check">{(()=>this.state.category=="-"?"選択してください":"OK!")()}</span>）
                <select
                  className="blog-category blog-select-btn"
                  defaultValue={this.state.category}
                  onChange={this.onChangeCategory}
                >
                  <option>-</option>
                  <option>workout</option>
                  <option>diet</option>
                  <option>personal-training</option>
                  <option>stretch</option>
                  <option>health</option>
                </select>
              </label>
              　
              <label>
                スラグ（<span className="slug-check">{this.state.slug_check}</span>）
                <input
                  className="blog-slug"
                  placeholder="記事のパス（URL）"
                  defaultValue={this.state.slug}
                  onChange={this.onChangeSlug}
                  onKeyUp={()=>this.handleCountChangeSlug(this.state.slug, this.state.slugs)}
                />
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                タグ（<span className="slug-check">{String(this.state.tags).split(",").map(tag=>` #${tag} `)}</span>）
                <input
                  className="blog-tags"
                  placeholder="記事につけるタグ（カンマ区切り｜例：塾長の解説,綺麗なダイエット）"
                  defaultValue={this.state.tags}
                  onChange={this.onChangeTags}
                  onKeyUp={this.handleCountChangeTags(this.state.tags)}
                />
              </label>
              　
              <label>
                投稿日
                <input
                  className="blog-post-date"
                  placeholder="投稿日"
                  defaultValue={this.state.post_date}
                  onChange={this.onChangePostDate}
                />
              </label>
              　
              <label>
                更新日
                <input
                  className="blog-edit-date"
                  placeholder="更新日"
                  defaultValue={this.state.edit_date}
                  onChange={this.onChangeEditDate}
                />
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                サムネイル画像　<div className="preview" onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)}>◆{this.state.hover ? (<img className="preview-image" src={(()=>this.state.thumbnail)()}/>) : null}</div>
                <input
                  className="blog-thumbnail"
                  placeholder="記事のサムネイル画像（https://~~）"
                  defaultValue={this.state.thumbnail}
                  onChange={this.onChangeThumbnail}
                  onKeyUp={this.handleCountChangeThumbnail(this.state.thumbnail)}
                />
              </label>
              　
              <label>
                SNSシェア画像　<div className="preview" onMouseOver={this.mouseOver2.bind(this)} onMouseOut={this.mouseOut2.bind(this)}>◆{this.state.hover2 ? (<img className="preview-image" src={(()=>this.state.sns_card)()}/>) : null}</div>
                <input
                  className="blog-sns-card"
                  placeholder="SNSにシェアした時の画像（https://~~）"
                  defaultValue={this.state.sns_card}
                  onChange={this.onChangeSNSCard}
                  onKeyUp={this.handleCountChangeSnsCard(this.state.sns_card)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                サマリー（文字数：{this.state.description_count}/130）
                <input
                  className="blog-description"
                  placeholder="記事の説明（SEO意識）"
                  defaultValue={this.state.description}
                  onChange={this.onChangeDescription}
                  onKeyUp={e => this.handleCountChangeDescription(this.state.description, e.target.value.length)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                記事の内容（<a className="blog-link" href="https://media.katagirijuku.jp/health/how-to-markdown">書き方</a>）<br/>
                <textarea
                  className="blog-content"
                  placeholder="記事本文（マークダウンで記述しましょう）"
                  defaultValue={this.state.content}
                  onChange={this.onChangeContent}
                  onKeyUp={this.handleCountChangeContent(this.state.content)}
                ></textarea>
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                バナー画像　<div className="preview" onMouseOver={this.mouseOver3.bind(this)} onMouseOut={this.mouseOut3.bind(this)}>◆{this.state.hover3 ? (<img className="preview-image" src={(()=>this.state.banner)()}/>) : null}</div>
                <input
                  className="blog-banner"
                  placeholder="バナー画像（https://~~）"
                  defaultValue={this.state.banner}
                  onChange={this.onChangeBanner}
                  onKeyUp={this.handleCountChangeBanner(this.state.banner)}
                />
              </label>
              　
              <label>
                バナー遷移先ページ
                <input
                  className="blog-banner-link"
                  placeholder="バナーをクリックした時の飛び先（https://~~）"
                  defaultValue={this.state.banner_link}
                  onChange={this.onChangeBannerLink}
                  onKeyUp={this.handleCountChangeBannerLink(this.state.banner_link)}
                />
              </label>
            </div>
            <br />
            <div className="mt-4 flex">
              {(() => {
                if (this.state.published) {
                  return (
                    <button className="btn-on blog-publish" onClick={ e => this.onClickReversePublish(e) }>
                      非公開状態に変更する（現在は「公開」で設定中です）
                    </button>
                  )
                } else {
                  return (
                    <button className="btn-off blog-publish" onClick={ e => this.onClickReversePublish(e) }>
                      公開状態に変更する（現在は「非公開」で設定中です）
                    </button>
                  )
                }
              })()}
              　
              {(() => {
                if (this.state.unlisted) {
                  return (
                    <button className="btn-on blog-unlist" onClick={ e => this.onClickReverseUnlist(e) }>
                      一般公開に変更する（現在ブログに掲載されない設定です）
                    </button>
                  )
                } else {
                  return (
                    <button className="btn-off blog-unlist" onClick={ e => this.onClickReverseUnlist(e) }>
                      限定公開に変更する（現在ブログに掲載される設定です）
                    </button>
                  )
                }
              })()}
            </div>
            {(() => {
              if (this.state.title!="" && this.state.description!="" && this.state.content!="" && this.state.slug_check=="使用可能なスラグです" && this.state.category!="-") {
                return (
                  <div className="mt-12 flex justify-center">
                    <button className="btn" onClick={ e => this.onClickBtn(e) }>
                      この内容で更新する
                    </button>
                  </div>
                )
              }})()
            }
          </form>
        </div>
      </>
    );
  }

  checkAvailableSlug = (slug, slugs) => {
    slug==""
    ? this.setState({ slug_check: "スラグは入力必須です"})
    : this.setState({ slug_check: String(slugs).replace("[","").replace("]","").replace(/\"/g,"").split(",").indexOf(slug) == -1 ? "使用可能なスラグです" : "このスラグは使用できません"});
  }

  handleCountChangeTitle = (text, length) => {
    this.setState({title_count: length});
    localStorage.setItem(this.props.location.pathname+'/title', text);
  }
  handleCountChangeDescription = (text, length) => {
    this.setState({description_count: length});
    localStorage.setItem(this.props.location.pathname+'/description', text);
  }
  handleCountChangeSlug = (slug, slugs) => {
    localStorage.setItem(this.props.location.pathname+'/slug', slug);
    this.checkAvailableSlug(slug, slugs)
  }
  handleCountChangeTags = text => localStorage.setItem(this.props.location.pathname+'/tags', text);
  handleCountChangeContent = text => localStorage.setItem(this.props.location.pathname+'/content', text);
  handleCountChangeThumbnail = text => localStorage.setItem(this.props.location.pathname+'/thumbnail', text);
  handleCountChangeSnsCard = text => localStorage.setItem(this.props.location.pathname+'/sns_card', text);
  handleCountChangeBanner = text => localStorage.setItem(this.props.location.pathname+'/banner', text);
  handleCountChangeBannerLink = text => localStorage.setItem(this.props.location.pathname+'/banner_link', text);

  onClickBtn = () => {
    this.clearLocalStorage(this.props.location.pathname);
    console.log(this.state)
    const { title, category, slug, tags, post_date, edit_date, thumbnail, sns_card, description, content, banner, banner_link, published, unlisted, author } = this.state;
    if (title == "") return;
    axios(`${urls.api_server}/api/blogs/${this.props.location.pathname.split("/")[2]}/edit`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      data: JSON.stringify( { title, category, slug, tags, post_date, edit_date, thumbnail, sns_card, description, content, banner, banner_link, published, unlisted, author }),
    }).then((results) => {
      if (results.status === 404) {
        console.log("404");
        return;
      }
      console.log(results);
      if (results.status==201) this.setState({ redirect: true });
      return;
    })
    .catch((error) => {
      console.log(error);
    });
  }

  onClickReversePublish = async e => {
    e.preventDefault();
    let published_update = this.state.published ? 0 : 1;
    await this.setState({ published: published_update });
    localStorage.setItem(this.props.location.pathname+'/published', this.state.published);
  }

  onClickReverseUnlist = async e => {
    e.preventDefault();
    let unlisted_update = this.state.unlisted ? 0 : 1;
    await this.setState({ unlisted: unlisted_update });
    localStorage.setItem(this.props.location.pathname+'/unlisted', this.state.unlisted);
  }

  onChangeTitle = e => this.setState({ title: e.target.value });
  onChangeCategory = e => {
    this.setState({ category: e.target.value });
    localStorage.setItem(this.props.location.pathname+'/category', e.target.value);
  }
  onChangeSlug = e => this.setState({ slug: e.target.value });
  onChangeDescription = e => this.setState({ description: e.target.value });
  onChangeTags = e => this.setState({ tags: e.target.value });
  // onChangePostDate = e => this.setState({ post_date: e.target.value });
  // onChangeEditDate = e => this.setState({ edit_date: e.target.value });
  onChangeThumbnail = e => this.setState({ thumbnail: e.target.value });
  onChangeSNSCard = e => this.setState({ sns_card: e.target.value });
  onChangeBanner = e => this.setState({ banner: e.target.value });
  onChangeBannerLink = e => this.setState({ banner_link: e.target.value });
  onChangeContent = e => this.setState({ content: e.target.value });

  mouseOver = () => this.setState({hover: true});
  mouseOut = () => this.setState({hover: false});
  mouseOver2 = () => this.setState({hover2: true});
  mouseOut2 = () => this.setState({hover2: false});
  mouseOver3 = () => this.setState({hover3: true});
  mouseOut3 = () => this.setState({hover3: false});

  setLocalStorage = props => {
    const delete_target = props.location.aboutProps.slugs.indexOf(props.location.aboutProps.blog.slug);
    const slugs_for_check = props.location.aboutProps.slugs;
    slugs_for_check.splice(delete_target,1);
    console.log(slugs_for_check);
    const published_flag = props.location.aboutProps.blog.published == true ? 1 : 0;
    const unlisted_flag = props.location.aboutProps.blog.unlisted == true ? 1 : 0;
    localStorage.setItem(props.location.pathname+'/title', props.location.aboutProps.blog.title);
    localStorage.setItem(props.location.pathname+'/description', props.location.aboutProps.blog.description);
    localStorage.setItem(props.location.pathname+'/slug', props.location.aboutProps.blog.slug);
    localStorage.setItem(props.location.pathname+'/slugs', slugs_for_check);
    localStorage.setItem(props.location.pathname+'/category', props.location.aboutProps.blog.category);
    localStorage.setItem(props.location.pathname+'/published', published_flag);
    localStorage.setItem(props.location.pathname+'/unlisted', unlisted_flag);
    localStorage.setItem(props.location.pathname+'/post_date', props.location.aboutProps.blog.post_date);
    localStorage.setItem(props.location.pathname+'/thumbnail', props.location.aboutProps.blog.thumbnail);
    localStorage.setItem(props.location.pathname+'/sns_card', props.location.aboutProps.blog.sns_card);
    localStorage.setItem(props.location.pathname+'/content', props.location.aboutProps.blog.content);
    localStorage.setItem(props.location.pathname+'/banner', props.location.aboutProps.blog.banner);
    localStorage.setItem(props.location.pathname+'/banner_link', props.location.aboutProps.blog.banner_link);
    localStorage.setItem(props.location.pathname+'/tags', props.location.aboutProps.blog.tags);
  }
  clearLocalStorage = pathname => {
    localStorage.removeItem(pathname+'/title')
    localStorage.removeItem(pathname+'/description')
    localStorage.removeItem(pathname+'/slug')
    localStorage.removeItem(pathname+'/slugs')
    localStorage.removeItem(pathname+'/category')
    localStorage.removeItem(pathname+'/published')
    localStorage.removeItem(pathname+'/unlisted')
    localStorage.removeItem(pathname+'/post_date')
    localStorage.removeItem(pathname+'/thumbnail')
    localStorage.removeItem(pathname+'/sns_card')
    localStorage.removeItem(pathname+'/content')
    localStorage.removeItem(pathname+'/banner')
    localStorage.removeItem(pathname+'/banner_link')
    localStorage.removeItem(pathname+'/tags')
    localStorage.removeItem(pathname+'/slugs')

  }
}

module.exports = BlogEdit;
