const React = require("react");
const urls = require("../env");
const axios = require("axios");
const Button = require("../components/button");
const { Link } = require("react-router-dom");

class HpEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hpData: {}, 
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass
      },
      method: "GET",
      url: `${urls.api_server}/api/hp_news`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results)
        const hp_news = results.data?.home_page;
        this.setState({
          hpData: hp_news,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      hpData,
    } = this.state;

    if (!hpData) return null;
    return (
      <div>
        <h2>ホームページ情報の編集</h2>
        <div className="form-card">
          <form className="w-2/3">
            <h2>トップページ「News」</h2>
            <div className="mt-4">
              <label>
                テキスト
                <input
                  className="w-full mt-4"
                  placeholder="新型コロナウイルスへの対応について"
                  value={hpData.news_text}
                  onChange={event => this.onChangeHpNews(event.target.value, 'news_text')}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                リンク 
                <input
                  className="w-full mt-4"
                  placeholder="https://katagirijuku.jp/media/health/covid-19/"
                  value={hpData.news_link}
                  onChange={event => this.onChangeHpNews(event.target.value, 'news_link')}
                />
              </label>
            </div>

            <div className="mt-12 flex justify-center">
              <button className="btn" onClick={e => this.onClickBtn(e)}>
                更新
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  onClickBtn = async e => {
    e.preventDefault();
    const {
      hpData,
    } = this.state;

    if (!hpData) return;

    axios(`${urls.api_server}/api/hp_news`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({
        home_page: hpData,
      }),
    }).then((results) => {
      if (results.status === 404) {
        console.log("404");
        return;
      }
      if(results.status == 201) {
        alert('データを更新しました')
      } else {
        alert('サーバー側でエラーが生じました')
      }
      setTimeout(() => this.props.history.goBack(), 1000)
      return;
    })
    .catch((error) => {
      console.log(error);
    });
  };

  onChangeHpNews = (val, field) => {
    const { hpData } = this.state;
    hpData[field] = val 
    this.setState({ hpData });
  };
}

module.exports = HpEdit;

