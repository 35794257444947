const React = require("react");
const axios = require("axios");
const urls = require("../env");
const Button = require("../components/button");
const { Link } = require("react-router-dom");

class Trainers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gyms: [],
      trainers: [],
      initialTrainers: [],
      ownerGymIDs: [],
    };
  }
  componentDidMount() {
    this.get_gyms();
    this.get_trainers();
    this.get_gym_ids_of_owner();
  }
  render() {
    let { gyms, ownerGymIDs, trainers } = this.state;
    return (
      <>
        <h2>トレーナーの一覧</h2>
        <br />
        {(() => {
          if (localStorage.getItem("authority") === "manager") {
            return (
              <>
                <div className="centering">
                  <select
                    className="select-btn"
                    onChange={(event) => {
                      console.log(event.target.value);
                      return this.filterListByGym(event.target.value);
                    }}
                  >
                    <option className="center-text">全店舗</option>
                    {gyms.map((gym) => (
                      <option key={gym.id}>{gym.name}</option>
                    ))}
                  </select>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>{"ID"}</th>
                      <th>{"メールアドレス"}</th>
                      <th className="">{"所属店舗"}</th>
                      <th>{""}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trainers.map((trainer) => (
                      <tr key={trainer.id}>
                        <td>{trainer.id}</td>
                        <td>{trainer.email}</td>
                        <td className="">
                          {
                            this.state.gyms.find(
                              (gym) =>
                                gym.id ==
                                (trainer.gymusers.length == 0
                                  ? 0
                                  : Number(trainer.gymusers[0].gym_id))
                            )?.name
                          }
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: `/trainer_show/${trainer.id}`,
                              aboutProps: {
                                trainer: trainer,
                              },
                            }}
                          >
                            <Button name="詳細" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            );
          } else if (localStorage.getItem("authority") === "owner") {
            return (
              <>
                {ownerGymIDs.map((ownerGymId) => {
                  const gym = gyms.find((g) => g.id == ownerGymId);
                  const trainersBelongingToGym = trainers.filter(
                    (t) => t.gymusers.length != 0
                  );
                  const trainersOfGym = trainersBelongingToGym.filter(
                    (t) => t.gymusers[0].gym_id == ownerGymId
                  );
                  return (
                    <>
                      <div className="centering font-bold mt-16">
                        <p>{gym.name}</p>
                      </div>
                      <table className="mt-4">
                        <thead>
                          <tr>
                            <th>{"ID"}</th>
                            <th>{"メールアドレス"}</th>
                            <th>{""}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trainersOfGym.map((trainer) => (
                            <tr key={trainer.id}>
                              <td>{trainer.id}</td>
                              <td className="w-72 text-center">
                                {trainer.email}
                              </td>
                              {(() => {
                                if (
                                  trainer.email === "info@katagirijuku.co.jp"
                                ) {
                                  return <td>{""}</td>;
                                } else if (
                                  localStorage.getItem("authority") !==
                                  "trainer"
                                ) {
                                  return (
                                    <td>
                                      <Link
                                        to={{
                                          pathname: `/trainer_show/${trainer.id}`,
                                          aboutProps: {
                                            trainer: trainer,
                                          },
                                        }}
                                      >
                                        <Button name="詳細" />
                                      </Link>
                                    </td>
                                  );
                                }
                              })()}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  );
                })}
              </>
            );
          } else {
            gyms = gyms.find(
              (gym) => gym.id == Number(localStorage.getItem("user_gym_ids"))
            );
            return (
              <>
                <div className="centering">
                  <p>{gyms?.name}</p>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>{"ID"}</th>
                      <th>{"メールアドレス"}</th>
                      <th>{""}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trainers
                      .filter(
                        (trainer) =>
                          this.state.gyms.find(
                            (gym) =>
                              (
                                gym.id ==
                                (trainer.gymusers.length == 0
                                  ? 0
                                  : Number(trainer.gymusers[0].gym_id))
                              )?.name == gyms?.name
                          ) || trainer.gymusers.length == 0
                      )
                      .map((trainer) => (
                        <tr key={trainer.id}>
                          <td>{trainer.id}</td>
                          <td>{trainer.email}</td>
                          <td>{""}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            );
          }
        })()}
      </>
    );
  }
  get_gym_ids_of_owner = () => {
    const user_id = localStorage.getItem("user_id");
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/controller/owners/${user_id}/gym_ids`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 2");
        console.log(results);
        if (results.status != 200) {
          return;
        }
        this.setState({ ownerGymIDs: results.data.gymIds });
        return;
      })
      .catch((error) => {
        return;
      });
  };

  get_trainers = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/trainers`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState(results["data"]);
        console.log(this.state);
        this.setState({ initialTrainers: this.state.trainers });
        console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get_gyms = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        this.setState({ gyms: results.data.gyms });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  except_manager = () => {
    if (!(localStorage.getItem("authority") === "manager")) {
      let result_gyms = [];
      result_gyms.push(
        this.state.gyms.find(
          (gym) => gym.id == Number(localStorage.getItem("user_gym_ids"))
        )
      );
      console.log(result_gyms);
      this.setState({ gyms: result_gyms });
      const searchedTrainers = this.state.trainers.filter(
        (trainer) =>
          this.state.gyms.find(
            (gym) =>
              gym.id ==
              (trainer.gymusers.length == 0
                ? 0
                : Number(trainer.gymusers[0].gym_id))
          )?.name == this.state.gyms[0].name
      );
      this.setState({ trainers: searchedTrainers });
    }
  };

  filterListByGym = async (value) => {
    await this.setState({ trainers: this.state.initialTrainers });
    if (value == "全店舗") return;
    const searchedTrainers = this.state.trainers.filter(
      (trainer) =>
        this.state.gyms.find(
          (gym) =>
            gym.id ==
            (trainer.gymusers.length == 0
              ? 0
              : Number(trainer.gymusers[0].gym_id))
        )?.name == value
    );
    this.setState({ trainers: searchedTrainers });
  };
}

module.exports = Trainers;
