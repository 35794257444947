const basic_user = "KatagiriJuku";
const basic_pass = "Tamagosama1216";
const env = process.env.REACT_APP_CUSTOM_NODE_ENV || "development";
let api_server, socket_server;
if (env == "production") {
  api_server = "https://server.katagirijuku.work";
  socket_server = "https://socket.katagirijuku.work";
} else if (env == "staging") {
  api_server = "https://staging-server.katagirijuku.work";
  socket_server = "https://staging-socket.katagirijuku.work";
} else {
  api_server = "http://localhost:5000";
  socket_server = "http://localhost:8080";
}

export { api_server, socket_server, basic_user, basic_pass };
