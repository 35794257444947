const React = require("react");
const axios = require("axios");
const urls = require("../env");

const styleDateTime = dateTime => {
  const date = dateTime.split("T");
  const dates = date[0].split("-");
  const times = date[1].split(":");
  return `${dates[0]}/${dates[1]}/${dates[2]} ${times[0]}:${times[1]}`;
};

class TraineePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [
        {
          date: "2020-01-01T01:01:00.000Z",
          type: "",
          status: "",
          item: {
            name: "",
            price: 0,
            type: "",
          },
        },
      ],
    };
  }
  async componentDidMount() {
    this.fetchPayments();
  }
  render() {
    return (
      <>
        <h2>決済履歴</h2>
        <table>
          <thead>
            <tr>
              <th>{"日時"}</th>
              <th>{"決済種類"}</th>
              <th>{"決済可否"}</th>
              <th>{"プラン"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.payments.map(payment => {
              const { date, type, status, item } = payment;
              return (
                <tr>
                  <td>{`${styleDateTime(date)}`}</td>
                  <td>{`${type}`}</td>
                  <td style={status ? {} : { color: "red" }}>{`${status}`}</td>
                  <td>{`${item.name}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  fetchPayments = async () => {
    const traineeId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/trainees/${traineeId}/payments`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ payments: results.data.payments });
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };
}
module.exports = TraineePayments;
