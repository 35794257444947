const React = require("react");
const axios = require("axios");
const urls = require("../env");

class CvRoutesGymsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gymName: "",
      month: [],
      stats: [],
      gyms: [],
    };
  }

  componentDidMount() {
    this.fetchAllGymsCVRoutes();
    this.fetchGyms();
  }

  fetchAllGymsCVRoutes = () => {
    const gymId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/gym_cv/${gymId}/cv_routes`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(async (results) => {
        if (results.status != 200) {
          alert("何らかのエラーが発生しました。本部にご連絡ください。");
          return;
        }
        await this.setState({
          month: results.data.usersOfMonths,
          stats: results.data.inflowStats,
          gymName: results.data.gymName,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchGyms = () => {
    axios(`${urls.api_server}/controller/gyms`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  formatRouteName = (routeName) => {
    if (routeName.indexOf("リーフレット") != -1) {
      let gymNameOfLang_Ja = "";
      const gymName = routeName.split(/[（）]/)[1];
      const leafGym = this.state.gyms.filter((g) => g.slug == gymName.trim());
      if (leafGym.length == 0) {
        gymNameOfLang_Ja = gymName;
      } else {
        gymNameOfLang_Ja = leafGym[0].name;
      }
      return "リーフレット（" + gymNameOfLang_Ja + "）";
    } else {
      return routeName;
    }
  };

  render() {
    let rows = [];
    let count = 0;
    for (let key in this.state.stats) {
      count++;
      rows.push(
        <tr key={count} className="bg-red-200">
          <td>{this.formatRouteName(key)}</td>
          <td>{this.state.stats[key]}</td>
        </tr>
      );
    }
    return (
      <>
        <h2>{"『" + this.state.gymName + "』 の流入経路"}</h2>
        <div>
          <h3 className="w-2/3 mx-auto mt-10 pl-2 font-bold">💪 全期間</h3>
          <table className="w-2/3 mt-0 bg-red-200">
            <thead>
              <tr className="bg-red-200">
                <th>{"流入経路（from）"}</th>
                <th>{"入会数（単位：人）"}</th>
              </tr>
            </thead>
            <tbody className="text-center">{rows}</tbody>
          </table>
        </div>
        {this.state.month.map((m) => {
          return (
            <div key={m.yeat + m.month}>
              <h3 className="w-2/3 mx-auto mt-10 pl-2 font-bold">
                {m.year + "年 " + m.month + "月"}
              </h3>
              <table className="w-2/3 mt-0">
                <thead>
                  <tr>
                    <th>{"流入経路（from）"}</th>
                    <th>{"入会数（単位：人）"}</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td className="text-red-500">{"総数"}</td>
                    <td className="text-red-500">{m.IndividualUsers.length}</td>
                  </tr>
                  {Object.keys(m.inflowStatsMonth).map((key) => {
                    return (
                      <tr>
                        <td>{this.formatRouteName(key)}</td>
                        <td>{m.inflowStatsMonth[key]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </>
    );
  }
}

module.exports = CvRoutesGymsShow;
