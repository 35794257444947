const React = require("react");
const { renderMDText } = require("../helper/render_md_text");
const marked = require("marked");
const axios = require("axios");
const urls = require("../env");

class RviewsNew extends React.Component {
  constructor(props) {
    super(props);
    const gymId = this.props.match.params.id;
    this.state = {
      reviewId: 0,
      reviewText: "",
      reviewOrder: "",
      reviewUserImage: "",
      reviewName: "",
      reviewImageFile: "",
      reviewAge: "",
      reviewSex: "",
      reviewOccupation: "",
    };
  }

  onSubmit = async e => {
    e.preventDefault();
    const {
      reviewText,
      reviewName,
      reviewAge,
      reviewSex,
      reviewOccupation,
      reviewOrder,
      reviewImageFile,
    } = this.state;
    const gymId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/reviews`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      data: JSON.stringify({
        text: reviewText,
        name: reviewName,
        age: reviewAge,
        sex: reviewSex,
        occupation: reviewOccupation,
        user_image: reviewImageFile,
        order: reviewOrder,
        gym_id: gymId,
      }),
    })
      .then(results => {
        if (results.status == 201) {
          alert("作成が完了しました。");
          this.clearLocalStorage();
        }
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
      })
      .catch(error => {
        console.log(error);
      });
  };

  clearLocalStorage = () => {
    const { localStorageKeyForText, localStorageKeyForThumb } = this.state;
    localStorage.removeItem(localStorageKeyForText);
    localStorage.removeItem(localStorageKeyForThumb);
  };

  onChangeReviewText = e => {
    const text = e.target.value;
    this.setState({ reviewText: text });
  };

  onChangeReviewName = e => {
    const name = e.target.value;
    this.setState({ reviewName: name });
  };

  onChangeReviewAge = e => {
    const age = e.target.value;
    this.setState({ reviewAge: age });
  };

  onChangeReviewSex = e => {
    const sex = e.target.value;
    this.setState({ reviewSex: sex });
  };

  onChangeReviewOccupation = e => {
    const occupation = e.target.value;
    this.setState({ reviewOccupation: occupation });
  };

  onChangeReviewOrder = e => {
    const order = e.target.value;
    this.setState({ reviewOrder: order });
  };

  onChangeImageFile = e => {
    this.setState({ reviewImageFile: e.target.files[0] });
  };

  onImageUpload = (e, type) => {
    this.imageUpload(type);
    return e.preventDefault();
  };

  imageUpload = type => {
    let formKey, formValue;
    const ImageFile = this.state.reviewImageFile;
    formKey = "review_user_image";
    formValue = ImageFile;

    const formData = new FormData();
    formData.append(formKey, formValue);
    console.log(formData);
    const config = {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${urls.api_server}/controller/reviews/image_upload/${formKey}`, formData, config)
      .then(response => {
        // this.onUploaedImage(response.data.image_url, type);
        alert("アップロードが完了しました  url: " + response.data.image_url);
        this.setState({ reviewImageFile: response.data.image_url });
      })
      .catch(error => {
        alert("アップロードに失敗しました" + error);
        console.log(error);
      });
  };

  onUploaedImage = (val, type) => {
    switch (type) {
      case "reviewUserImage":
        let { text } = this.state.reviewImageFile;
        console.log(text);
        // this.setText(gymFeaturesText);
        break;
      case "thumb":
      default:
        localStorage.setItem(this.state.localStorageKeyForThumb, val);
        this.setState({ gymFeaturesThumb: val });
        break;
    }
  };

  render() {
    return (
      <div>
        <h2>口コミ・評判の新規作成</h2>
        <div className="form-card">
          <form>
            <div className="mt-4">
              <label>
                <p>口コミ・評判</p>
                <textarea
                  placeholder="例:とても素敵なトレーナーさんがいて楽しく運動できました"
                  onKeyUp={event => this.onChangeReviewText(event)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                名前
                <input
                  placeholder="例: 山本太郎"
                  onKeyUp={event => this.onChangeReviewName(event)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                ユーザー画像
                <input type="file" onChange={e => this.onChangeImageFile(e)} />
                <button
                  className="btn"
                  onClick={e => this.onImageUpload(e, "reviewUserImage")}
                  type="submit"
                >
                  Upload
                </button>
              </label>
            </div>
            <div className="mt-4">
              <label>
                年代
                <input
                  placeholder="例: 30(半角)"
                  type="number"
                  step="10"
                  onChange={event => this.onChangeReviewAge(event)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                性別
                <input placeholder="例: 男性" onChange={event => this.onChangeReviewSex(event)} />
              </label>
            </div>
            <div className="mt-4">
              <label>
                職業
                <input
                  placeholder="例: エンジニア"
                  onKeyUp={event => this.onChangeReviewOccupation(event)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                表示順番
                <input
                  placeholder="例: 1(半角)"
                  onKeyUp={event => this.onChangeReviewOrder(event)}
                />
              </label>
            </div>
            <div className="mt-12 flex justify-center">{this.renderSubmitButton()}</div>
          </form>
        </div>
      </div>
    );
  }
  renderSubmitButton = () => {
    if (!!this.state.reviewText && !!this.state.reviewOrder) {
      return (
        <button className="btn" type="submit" onClick={e => this.onSubmit(e)}>
          この内容で投稿する
        </button>
      );
    }

    let NoInputValue = "";
    if (!this.state.reviewText) NoInputValue += "口コミ・評判 \n";
    if (!this.state.reviewOrder) NoInputValue += "順番 \n";
    const alertMessage = `${NoInputValue}を入力してください`;

    return (
      <button type="button" className="btn-off" onClick={() => alert(alertMessage)}>
        この内容で投稿する
      </button>
    );
  };
}

module.exports = RviewsNew;
