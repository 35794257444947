const React = require("react");
const axios = require("axios");
const envs = require("../env");

class ECProductNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      slug: "",
      image: "",
      price: 0,
      type: 2,
      item_id: "",
      content: "",
    };
  }

  onChangeName = (e) => this.setState({ name: e.target.value });
  onChangeSlug = (e) => this.setState({ slug: e.target.value });
  onChangeImage = (e) => this.setState({ image: e.target.value });
  onChangePrice = (e) => this.setState({ price: e.target.value });
  onChangeType = (e) => this.setState({ type: e.target.value });
  onChangeItemId = (e) => this.setState({ item_id: e.target.value });
  onChangeContent = (e) => this.setState({ content: e.target.value });

  render() {
    return (
      <>
        <h2>商品の追加</h2>
        <div className="form-card">
          <form className="blog">
            <div className="mt-4 flex justify-between">
              <label>
                商品名
                <input
                  className="blog-title"
                  placeholder="商品名"
                  defaultValue={this.state.name}
                  onChange={this.onChangeName}
                />
              </label>
              <label>
                スラグ（一意）
                <input
                  className="blog-slug"
                  placeholder="記事のパス（URL）"
                  defaultValue={this.state.slug}
                  onChange={this.onChangeSlug}
                />
              </label>
              <label>
                商品画像
                <input
                  className="blog-category"
                  placeholder="記事のサムネイル画像（https://~~）"
                  defaultValue={this.state.image}
                  onChange={this.onChangeImage}
                />
              </label>
            </div>
            <div className="mt-4 flex justify-between">
              <label>
                税抜価格（カンマ「,」不要）
                <input
                  className="blog-post-date"
                  placeholder="15000"
                  defaultValue={this.state.price}
                  onChange={this.onChangePrice}
                />
              </label>
              　
              <label>
                商品タイプ（1.毎月 / 2.単発）
                <input
                  className="blog-edit-date"
                  placeholder="1"
                  defaultValue={this.state.type}
                  onChange={this.onChangeType}
                />
              </label>
              <label>
                アイテムID
                <input
                  className="blog-title"
                  placeholder="prd_00000000"
                  defaultValue={this.state.item_id}
                  onChange={this.onChangeItemId}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                商品説明（
                <a
                  className="blog-link"
                  href="https://media.katagirijuku.jp/health/how-to-markdown"
                >
                  書き方
                </a>
                ）<br />
                <textarea
                  className="blog-content"
                  placeholder="商品説明・本文（マークダウンで記述）"
                  defaultValue={this.state.content}
                  onChange={this.onChangeContent}
                ></textarea>
              </label>
            </div>
            <div className="mt-12 flex justify-center">
              <button className="btn" onClick={(e) => this.onClickBtn(e)}>
                この内容で公開する
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  onClickBtn = async (e) => {
    e.preventDefault();
    const { name, slug, image, price, type, item_id, content } = this.state;

    if (
      name == "" ||
      slug == "" ||
      image == "" ||
      price == 0 ||
      type == 0 ||
      item_id == "" ||
      content == ""
    ) {
      alert("入力されていない項目があります。再度確認ください。");
      return;
    }

    if (type != 1 && type != 2) {
      alert("商品タイプは 1 または 2 のどちらかを記述してください。");
      return;
    }

    // slugの重複チェック
    axios(`${envs.api_server}/ec/slug/${slug}`, {
      auth: {
        username: envs.basic_user,
        password: envs.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    }).then((results) => {
      console.log("％％％％％％％％％％％％％％％％％％％％");
      console.log(results);
      if (results.status == 201) {
        alert("このスラグはすでに使用されているため、使用できません。");
        return;
      }
      if (results.status != 200) {
        alert(
          "何らかの理由で現在アプリに不具合が生じています。\n一定時間後にお試しいただき、それでもダメであればご連絡ください。"
        );
        return;
      }

      // 商品の作成
      axios(`${envs.api_server}/ec/item`, {
        auth: {
          username: envs.basic_user,
          password: envs.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        data: JSON.stringify({
          name,
          slug,
          price,
          payment_type: type,
          item_id,
          image,
          discription: content,
        }),
      }).then((results) => {
        if (results.status != 200) {
          alert(
            "何らかの理由で現在アプリに不具合が生じています。\n一定時間後にお試しいただき、それでもダメであればご連絡ください。"
          );
          return;
        }
        alert("商品を登録・公開しました。");
      });
    });
  };
}

module.exports = ECProductNew;
