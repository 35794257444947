const React = require("react");
const { Link } = require("react-router-dom");
const axios = require("axios");
const urls = require("../env");

class Trials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gyms: [],
    };
  }

  componentDidMount() {
    this.fetchGyms();
  }

  fetchGyms = () => {
    axios(`${urls.api_server}/controller/gyms`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="w-128 mx-auto mb-10">
        <h2 className="my-16 text-2xl">初回体験・店舗一覧</h2>
        <div class="flex flex-col">
          {this.state.gyms.map((g) => {
            if (
              !(localStorage.getItem("authority") === "trainer") &&
              !(localStorage.getItem("authority") === "owner")
            ) {
            return (
              <div className="mb-8">
                <Link to={{ pathname: `/trials/show/${g.id}` }}>
                  <button class="w-full py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
                    {g.name}
                  </button>
                </Link>
              </div>
            );
          } else {
            if (
              g.id == localStorage.getItem("user_gym_ids")
            )
            return (
              <div className="mb-8">
                <Link to={{ pathname: `/trials/show/${g.id}` }}>
                  <button class="w-full py-3 px-16 shadow-md no-underline rounded-full bg-red text-white font-sans font-semibold text-xl border-red btn-primary hover:text-white hover:bg-red-light focus:outline-none active:shadow-none btn block">
                    {g.name}
                  </button>
                </Link>
              </div>
            );
          }
          })}
        </div>
      </div>
    );
  }
}

module.exports = Trials;
