const React = require("react");
const envs = require("../env");
const axios = require("axios");
const { Link } = require("react-router-dom");
const Button = require("../components/button");

class ECSales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
    };
  }
  componentDidMount() {
    this.getSales();
  }
  render() {
    return (
      <>
        <h2>EC売上のサマリー</h2>
        <table className="w-2/3">
          <thead>
            <tr>
              <th>{"月 / 年"}</th>
              <th>{"売上"}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.payments.map((p) => (
              <tr>
                <td className="text-center">{`${p.month} / ${p.year}`}</td>
                <td className="text-center">{`税込：${Number(
                  p.priceCount + p.priceCount / 10
                ).toLocaleString()}　(${p.priceCount.toLocaleString()})`}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/ec/sales/show`,
                      aboutProps: {
                        year: p.year,
                        month: p.month,
                      },
                    }}
                  >
                    <Button name="詳細" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  getSales = () => {
    axios(`${envs.api_server}/ec/payments`, {
      auth: {
        username: envs.basic_user,
        password: envs.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status != 200) {
          alert("何らかのトラブルが発生しています。");
          return;
        }
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
        console.log(results.data);
        this.setState({ payments: results.data.payments });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

module.exports = ECSales;
