const React = require("react");
const urls = require("../env");
const axios = require("axios");
const Button = require("../components/button");
const { Link } = require("react-router-dom");

class GymNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gymData: {},
      nearStationsData: [...Array(3).keys()].map(i => {
        return { id: i };
      }),
      workingTimesData: ["月", "火", "水", "木", "金", "土", "日"].map((day, idx) => {
        return { id: idx, day_of_week: day };
      }),
      gymPhotosData: [...Array(10).keys()].map(i => {
        return { id: i, top_page: false };
      }),
      trainerProfileData: {},
      appealPointData: {},
      waysData: [...Array(15).keys()].map(i => {
        return { id: i };
      }),
      imageFile: null,
    };
  }

  componentDidMount() {}

  render() {
    const { gymData } = this.state;

    if (!gymData) return null;
    return (
      <div>
        <h2>ジムの新規追加</h2>
        <div className="form-card">
          <form>
            <div className="mt-4">
              <label>
                名前
                <input
                  placeholder="例:武蔵小山本店"
                  value={gymData.name}
                  onChange={event => this.onChangeGym(event.target.value, "name")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                キー *ホームページのドメインに相当します（/gyms/musashikoyama）
                <input
                  placeholder="musashikoyama"
                  value={gymData.slug}
                  onChange={event => this.onChangeGym(event.target.value, "slug")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                郵便番号
                <input
                  placeholder="152-0002"
                  value={gymData.post_number}
                  onChange={event => this.onChangeGym(event.target.value, "post_number")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                住所
                <input
                  placeholder="東京都目黒区目黒本町4-2-8"
                  value={gymData.address_one}
                  onChange={event => this.onChangeGym(event.target.value, "address_one")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                住所（建物名・階）
                <input
                  placeholder="クーカイテラス 1階"
                  value={gymData.address_two}
                  onChange={event => this.onChangeGym(event.target.value, "address_two")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                電話番号
                <input
                  placeholder="03-6451-3959"
                  value={gymData.phone_number}
                  onChange={event => this.onChangeGym(event.target.value, "phone_number")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                Google Map Link
                <input
                  placeholder="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.968294415999!2d139.6955001822847!3d35.622219318434176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b1688bf3283%3A0xd24dc614d5632ac3!2z44GL44Gf44GO44KK5aG-IOatpuiUteWwj-WxseacrOW6lw!5e0!3m2!1sja!2sjp!4v1576396600136!5m2!1sja!2sjp"
                  value={gymData.map_link}
                  onChange={event => this.onChangeGym(event.target.value, "map_link")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                Line URL
                <input
                  placeholder="https://lin.ee/uekQJ6W"
                  value={gymData.line_link}
                  onChange={event => this.onChangeGym(event.target.value, "line_link")}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                Instagram URL
                <input
                  placeholder="katagirijuku_akasaka (アカウント名を入力してください)"
                  value={gymData.insta_link}
                  onChange={event => this.onChangeGym(event.target.value, "insta_link")}
                />
              </label>
            </div>

            <div className="mt-4">
              <label>
                ヘッダー画像
                {gymData.hp_header_image ? (
                  <img style={{ width: "20%" }} src={gymData.hp_header_image} />
                ) : (
                  <h4>登録されていません</h4>
                )}
                <input type="file" onChange={e => this.onChangeUploadImage(e, "hp_header_image")} />
                <button
                  className="btn"
                  onClick={e => this.onUpload(e, "hp_header_image")}
                  type="submit"
                >
                  Upload
                </button>
                <br />
                <br />
              </label>
            </div>

            <div className="mt-4">
              <label>
                ヘッダー画像（スマートフォン向け）
                {gymData.hp_header_sm_image ? (
                  <img style={{ width: "20%" }} src={gymData.hp_header_sm_image} />
                ) : (
                  <h4>登録されていません</h4>
                )}
                <input
                  type="file"
                  onChange={e => this.onChangeUploadImage(e, "hp_header_sm_image")}
                />
                <button
                  className="btn"
                  onClick={e => this.onUpload(e, "hp_header_sm_image")}
                  type="submit"
                >
                  Upload
                </button>
                <br />
                <br />
              </label>
            </div>

            {this.renderInputsForWorkingTimes()}
            {this.renderInputsForNearStations()}
            {this.renderInputsForGymPhotos()}
            {this.renderInputsForTrainerProfile()}
            {this.renderInputsForAppealPoints()}
            {this.renderInputsForWays()}

            <div className="mt-12 flex justify-center">
              <button className="btn" onClick={e => this.onClickBtn(e)}>
                作成
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderInputsForWorkingTimes = () => {
    const { workingTimesData } = this.state;
    return (
      <>
        <h2>営業時間</h2>
        <div>
          　
          {["月", "火", "水", "木", "金", "土", "日"].map((day, idx) => {
            const wt = workingTimesData.filter(time => time.day_of_week == day)[0];
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                {day}
                <br />
                <label>
                  開始時間
                  <input
                    placeholder="9"
                    value={wt.open_time}
                    onChange={event =>
                      this.onChangeWorkingTime(event.target.value, wt.id, "open_time")
                    }
                  />
                </label>
                <label>
                  終了時間
                  <input
                    placeholder="20"
                    value={wt.close_time}
                    onChange={event =>
                      this.onChangeWorkingTime(event.target.value, wt.id, "close_time")
                    }
                  />
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  renderInputsForNearStations = () => {
    const { nearStationsData } = this.state;
    return (
      <>
        <h2>最寄り駅</h2>
        <div>
          {nearStationsData?.map((ns, idx) => {
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                <label>
                  駅名
                  <input
                    placeholder="武蔵小山駅"
                    value={ns.name}
                    onChange={event => this.onChangeNearStation(event.target.value, ns.id, "name")}
                  />
                </label>
                <label>
                  徒歩分時間
                  <input
                    placeholder="4"
                    value={ns.minites}
                    onChange={event =>
                      this.onChangeNearStation(event.target.value, ns.id, "minites")
                    }
                  />
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  renderInputsForGymPhotos = () => {
    const { gymPhotosData } = this.state;
    return (
      <>
        <h2>写真</h2>
        <div>
          {gymPhotosData?.map((gp, idx) => {
            const checkStyle = !!gp.top_page
              ? {
                  width: 10,
                  height: 10,
                  borderColor: "#0171bd",
                  borderWidth: 3,
                }
              : { width: 10, height: 10 };
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                <label>
                  画像
                  {gp.url ? (
                    <img style={{ width: "20%" }} src={gp.url} />
                  ) : (
                    <h4>登録されていません</h4>
                  )}
                  <input type="file" onChange={e => this.onChangeUploadImage(e, "gym_photos")} />
                  <button
                    className="btn"
                    onClick={e => this.onUpload(e, "gym_photos", gp.id)}
                    type="submit"
                  >
                    Upload
                  </button>
                  <br />
                  <br />
                </label>
                <label>
                  トップページに表示する
                  <input
                    type="checkbox"
                    style={checkStyle}
                    checked={!!gp.top_page}
                    value={!!gp.top_page}
                    onChange={e => this.onChangeGymPhoto(!gp.top_page, gp.id, "top_page")}
                  />
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  renderInputsForTrainerProfile = () => {
    const { trainerProfileData } = this.state;
    return (
      <>
        <h2>トレーナー</h2>
        {trainerProfileData ? (
          <div className="mt-4">
            <label>
              名前
              <input
                placeholder="片桐太郎"
                value={trainerProfileData.name}
                onChange={event => this.onChangeTrainerProfile(event.target.value, "name")}
              />
            </label>
            <br />
            <label>
              メッセージ
              <br />
              <textarea
                className="mt-4 blog-title"
                placeholder="初めまして、片桐太郎です。"
                value={trainerProfileData.introduction}
                onChange={event => this.onChangeTrainerProfile(event.target.value, "introduction")}
              />
            </label>
            <br />
            {/* <label>
                  ユーザーID
                  <input
                    placeholder="1"
                    value={trainerProfileData.user_id}
                    onChange={(event) =>
                      this.onChangeTrainerProfile(event.target.value, "user_id")
                    }
                  />
                </label> */}
            <label>
              画像
              {trainerProfileData.img ? (
                <img style={{ width: "20%" }} src={trainerProfileData.img} />
              ) : (
                <h4>登録されていません</h4>
              )}
              <input type="file" onChange={e => this.onChangeUploadImage(e, "trainer_profile")} />
              <button
                className="btn"
                onClick={e => this.onUpload(e, "trainer_profile")}
                type="submit"
              >
                Upload
              </button>
              <br />
              <br />
            </label>
          </div>
        ) : null}
      </>
    );
  };

  renderInputsForAppealPoints = () => {
    const { appealPointData } = this.state;
    return (
      <>
        <h2>おすすめポイント</h2>
        <div className="mt-4">
          <label>
            タイトル
            <input
              className="mt-4 blog-title"
              placeholder="完全個室だから人目を気にせずトレーニングできる！"
              value={appealPointData.title}
              onChange={event => this.onChangeAppealPoint(event.target.value, "title")}
            />
          </label>
          <br />
          <label>
            本文
            <br />
            <textarea
              className="mt-4  blog-title"
              placeholder="かたぎり塾大塚店は、完全個室・貸切のパーソナルトレーニングジムです。"
              value={appealPointData.text}
              onChange={event => this.onChangeAppealPoint(event.target.value, "text")}
            />
          </label>
          <br />
          <label>
            画像
            {appealPointData.img ? (
              <img style={{ width: "20%" }} src={appealPointData.img} />
            ) : (
              <h4>登録されていません</h4>
            )}
            <input
              className="mt-4"
              type="file"
              onChange={e => this.onChangeUploadImage(e, "appeal_point")}
            />
            <button
              className="btn mt-4 centering"
              onClick={e => this.onUpload(e, "appeal_point")}
              type="button"
            >
              アップロード
            </button>
            <br />
            <br />
          </label>
        </div>
      </>
    );
  };

  renderInputsForWays = () => {
    const { waysData } = this.state;
    return (
      <>
        <h2>道順</h2>
        <div>
          {waysData?.map((way, idx) => {
            return (
              <div className="mt-4" key={idx} style={{ marginTop: 30 }}>
                <label>
                  画像
                  {way.image ? (
                    <img style={{ width: "20%" }} src={way.image} />
                  ) : (
                    <h4>登録されていません</h4>
                  )}
                  <input type="file" onChange={e => this.onChangeUploadImage(e, "way")} />
                  <button
                    className="btn"
                    onClick={e => this.onUpload(e, "way", way.id)}
                    type="submit"
                  >
                    Upload
                  </button>
                  <br />
                  <br />
                </label>
                <label>
                  表示順
                  <input
                    placeholder="1"
                    value={way.number}
                    onChange={event => this.onChangeWay(event.target.value, way.id, "number")}
                  />
                </label>
                <label>
                  説明文
                  <input
                    placeholder="武蔵小山駅　西口を出ます。"
                    value={way.text}
                    onChange={event => this.onChangeWay(event.target.value, way.id, "text")}
                  />
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  onClickBtn = async e => {
    e.preventDefault();
    const {
      gymData,
      nearStationsData,
      workingTimesData,
      gymPhotosData,
      trainerProfileData,
      appealPointData,
      waysData,
    } = this.state;

    if (!gymData) return;

    console.log(JSON.stringify(workingTimesData));
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms_new`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({
        gym: gymData,
        near_stations: nearStationsData,
        working_times: workingTimesData,
        gym_photos: gymPhotosData,
        trainer_profile: trainerProfileData,
        appeal_point: appealPointData,
        ways: waysData,
      }),
    })
      .then(results => {
        if (results.status === 201) {
          alert("作成が完了しました");
          return;
        } else {
          alert("サーバー側でエラーが発生しました");
        }
      })
      .catch(error => {
        console.log(error);
      });
    setTimeout(() => this.props.history.goBack(), 1000);
  };

  onUpload = (e, type, ref_id = null) => {
    e.preventDefault();
    const formData = new FormData();
    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
    console.log(this.state.imageFile);
    formData.append(type, this.state.imageFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
    };

    axios
      .post(`${urls.api_server}/controller/gyms/image_upload/${type}`, formData, config)
      .then(response => {
        alert("アップロードが完了しました  url: " + response.data.image_url);
        this.onUploaedImage(response.data.image_url, type, ref_id);
      })
      .catch(error => {
        console.log(error);
      });
  };

  onChangeUploadImage = (e, type) => {
    this.setState({ imageFile: e.target.files[0] });
  };

  onUploaedImage = (val, type, ref_id = null) => {
    switch (type) {
      case "hp_header_image":
        this.onChangeGym(val, "hp_header_image");
        break;
      case "hp_header_sm_image":
        this.onChangeGym(val, "hp_header_sm_image");
        break;
      case "gym_photos":
        this.onChangeGymPhoto(val, ref_id, "url");
        break;
      case "trainer_profile":
        this.onChangeTrainerProfile(val, "img");
        break;
      case "appeal_point":
        this.onChangeAppealPoint(val, "img");
      case "way":
        this.onChangeWay(val, ref_id, "image");
        break;
      default:
    }

    this.setState({
      file: null,
    });
  };

  onChangeGym = (val, field) => {
    const { gymData } = this.state;
    gymData[field] = val;
    this.setState({ gymData });
  };

  onChangeGymPhoto = (val, id, field) => {
    const { gymPhotosData } = this.state;
    let updateGymPhotosData = gymPhotosData.map(gp => {
      if (gp.id == id) {
        gp[field] = val;
      }
      return gp;
    });

    this.setState({ gymPhotosData: updateGymPhotosData });
  };

  onChangeNearStation = (val, id, field) => {
    const { nearStationsData } = this.state;
    let updateNearStationsData = nearStationsData.map(ns => {
      if (ns.id == id) {
        ns[field] = val;
      }
      return ns;
    });

    this.setState({ nearStationsData: updateNearStationsData });
  };

  onChangeWorkingTime = (val, id, field) => {
    const { workingTimesData } = this.state;
    let updateWorkingTimesData = workingTimesData.map(wt => {
      if (wt.id == id) {
        wt[field] = val;
      }
      return wt;
    });

    this.setState({ workingTimesData: updateWorkingTimesData });
  };

  onChangeTrainerProfile = (val, field) => {
    const { trainerProfileData } = this.state;
    trainerProfileData[field] = val;
    this.setState({ trainerProfileData });
  };

  onChangeAppealPoint = (val, field) => {
    const { appealPointData } = this.state;
    appealPointData[field] = val;
    this.setState({ appealPointData });
  };

  onChangeWay = (val, id, field) => {
    const { waysData } = this.state;
    let updateWaysData = waysData.map(way => {
      if (way.id == id) {
        way[field] = val;
      }
      return way;
    });

    this.setState({ waysData: updateWaysData });
  };
}

module.exports = GymNew;
