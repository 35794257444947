const React = require("react");
const urls = require("../env");
const axios = require("axios");
const { Link } = require("react-router-dom");
const Button = require("../components/button");

class Sales extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    this.state = {
      gymsOfPayments: null,
      gyms: [],
      searchByGym: "全店舗",
      searchByMonth: `${today.getFullYear()}-${today.getMonth() + 1}`,
    };
  }
  componentDidMount() {
    this.getSales();
    this.get_gyms();
  }
  render() {
    return (
      <>
        <h2>店舗売上のサマリー</h2>

        <table className="w-2/3">
          <thead>
            <tr>
              <th>{this.renderTHForGymName()}</th>
              <th>{this.renderTHForMonth()}</th>
              <th>{this.renderTHForSales()}</th>
              <th>{""}</th>
            </tr>
          </thead>
          {this.renderPayments()}
        </table>
      </>
    );
  }

  renderPayments = () => {
    const { gymsOfPayments } = this.state;
    if (!gymsOfPayments || gymsOfPayments.length == 0) return this.renderException();

    return (
      <tbody>
        {gymsOfPayments.map(oneGym =>
          oneGym.map(oneMonthPayment => {
            const { totalPrice } = oneMonthPayment;
            const totalPriceWithTax = totalPrice + totalPrice / 10;
            return (
              <tr>
                <td>{oneMonthPayment.gymName}</td>
                <td>{`${oneMonthPayment.month} / ${oneMonthPayment.year}`}</td>
                <td>{`税込：${Number(totalPriceWithTax).toLocaleString()}　(${totalPrice})`}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/sales/show`,
                      state: {
                        gym: oneMonthPayment.gymName,
                        year: oneMonthPayment.year,
                        month: oneMonthPayment.month,
                        payments: oneMonthPayment.IndividualPayments,
                      },
                    }}
                  >
                    <Button name="詳細" />
                  </Link>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    );
  };

  renderException = () => {
    const { gymsOfPayments } = this.state;
    const text = !gymsOfPayments ? "読み込み中..." : "データが見つかりませんでした。";
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          height: "50px",
          paddingTop: "15px",
        }}
      >
        {text}
      </div>
    );
  };

  getSales = () => {
    const authority = localStorage.getItem("authority");
    const user_gym_ids = localStorage.getItem("user_gym_ids");
    const user_id = localStorage.getItem("user_id");
    const { searchByMonth } = this.state;

    let filterGymIds;

    if (authority === "trainer") {
      filterGymIds = [user_gym_ids];
      this.callApiGetSales(filterGymIds, []);
    } else if (authority === "owner") {
      filterGymIds = user_gym_ids;
      axios({
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        method: "GET",
        url: `${urls.api_server}/controller/owners/${user_id}/gym_ids`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(results => {
          if (results.status != 200) {
            return;
          }
          filterGymIds = results.data.gymIds;
          this.callApiGetSales(filterGymIds, []);
          return;
        })
        .catch(error => {
          return;
        });
    } else if (authority === "manager") {
      const year = searchByMonth.substr(0, 4);
      const month = searchByMonth.substr(5, 6);
      const beginningDate = new Date(year, month - 1);
      const endDate = new Date(beginningDate.getFullYear(), beginningDate.getMonth() + 1, 0);
      let filterMonths = [
        `${beginningDate.getFullYear()}/${beginningDate.getMonth() + 1}/01`,
        `${endDate.getFullYear()}/${endDate.getMonth() + 1}/${endDate.getDate()}`,
      ];

      filterMonths = filterMonths.map(month => {
        return new Date(month);
      });

      this.callApiGetSales([], filterMonths);
    }
  };

  callApiGetSales = (flterGymIds = [], filterMonths = []) => {
    axios(`${urls.api_server}/controller/sales`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      params: {
        gym_ids: flterGymIds,
        months: filterMonths,
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        const { gymsOfPayments } = results.data;
        this.setState({ gymsOfPayments });
      })
      .catch(error => {
        console.log(error);
      });
  };

  get_gyms = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
        if (localStorage.getItem("authority") != "manager") {
          this.setState({
            gyms: [
              results.data.gyms.find(gym => gym.id == Number(localStorage.getItem("user_gym_ids"))),
            ],
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderTHForGymName = () => {
    if (localStorage.getItem("authority") != "manager") return "店舗名";
    const { gyms } = this.state;
    return (
      <div className="centering">
        <label>
          店舗名
          <select
            className="select-btn"
            onChange={async event => {
              const { value } = event.target;
              await this.setState({ gymsOfPayments: null, searchByGym: value });
              return this.filterList();
            }}
          >
            <option className="center-text">全店舗</option>
            {gyms.map(gym => (
              <option key={gym.id}>{gym.name}</option>
            ))}
          </select>
        </label>
      </div>
    );
  };

  renderTHForMonth = () => {
    if (localStorage.getItem("authority") != "manager") return "月 / 年";

    return (
      <div className="centering">
        <label>
          月 / 年
          <input
            type="month"
            onChange={async event => {
              const { value } = event.target;
              await this.setState({ gymsOfPayments: null, searchByMonth: value });
              return this.filterList();
            }}
            value={this.state.searchByMonth}
          ></input>
        </label>
      </div>
    );
  };

  renderTHForSales = () => {
    let sumSales = 0;
    const { gymsOfPayments } = this.state;
    if (gymsOfPayments) {
      gymsOfPayments.map(oneGym => {
        oneGym.map(oneMonthPayment => {
          const { totalPrice } = oneMonthPayment;
          sumSales += totalPrice;
        });
      });
    }
    const sumSalseWithTax = sumSales * 1.1;
    return (
      <div className="centering">
        売上
        <br />
        {`合計：${Number(sumSalseWithTax).toLocaleString()}　(${Number(
          sumSales
        ).toLocaleString()})`}
      </div>
    );
  };

  filterList = () => {
    const { searchByGym, searchByMonth } = this.state;
    let filterGymIds = [];
    let filterMonths = [];

    if (searchByGym != "全店舗") {
      const targetGym = this.state.gyms.find(gym => gym.name == searchByGym);
      filterGymIds = [targetGym.id];
    }

    if (!!searchByMonth) {
      const year = searchByMonth.substr(0, 4);
      const month = searchByMonth.substr(5, 6);
      const beginningDate = new Date(year, month - 1);
      const endDate = new Date(beginningDate.getFullYear(), beginningDate.getMonth() + 1, 0);
      filterMonths = [
        `${beginningDate.getFullYear()}/${beginningDate.getMonth() + 1}/01`,
        `${endDate.getFullYear()}/${endDate.getMonth() + 1}/${endDate.getDate()}`,
      ];

      filterMonths = filterMonths.map(month => {
        return new Date(month);
      });
    }
    this.callApiGetSales(filterGymIds, filterMonths);
  };
}

module.exports = Sales;
