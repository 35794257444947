const marked = require("marked");

const replaceHTMLTextForStyle = HTMLText => {
  return (
    HTMLText
      // add orange highlight by <mark></mark>
      .replace(/&lt;mark&gt;/gi, "<mark style='background-color: #FFEFD5; color: #718096' >")
      .replace(/&lt;\/mark&gt;/gi, "</mark>")
      // add brank by <br>
      .replace(/&lt;br&gt;/gi, " ")
      // add caption by <caption>
      .replace(
        /&lt;caption&gt;/gi,
        "<span style='color: #AAA; display: block; text-align: center;' >"
      )
      .replace(/&lt;\/caption&gt;/gi, "</span>")
  );
};

const renderMDText = planeText => {
  if (!planeText) return { __html: "" };
  // const source = interviewText.replace(/\r?\n/gi, '\nreplaced_text ')
  // const parsedSouce = marked(source, { sanitize: true }).replace(/replaced_text/g, '')
  // const __html = marked(parsedSouce)
  const __html = replaceHTMLTextForStyle(marked(planeText, { sanitize: true }));
  return { __html };
};

module.exports = {
  renderMDText,
};
