const React = require("react");
const { renderMDText } = require("../helper/render_md_text");
const marked = require("marked");
const axios = require("axios");
const urls = require("../env");

class GymFeatureEdit extends React.Component {
  constructor(props) {
    super(props);
    const gymFeaturesId = this.props.match.params.id;
    this.state = {
      gymFeaturesId: 0,
      gymFeaturesSlug: "",
      gymFeaturesTitle: "",
      gymFeaturesText: null,
      gymFeaturesThumb: null,
      gymSlug: "",
      gymName: "",
      thumbFile: null,
      textImageFile: null,
      localStorageKeyForText: `gym_features_text_${gymFeaturesId}`,
      localStorageKeyForThumb: `gym_features_thumb_${gymFeaturesId}`,
    };
  }

  componentDidMount() {
    marked.setOptions({ gfm: true, breaks: true });
    this.fetchTrainerInfo();
  }

  fetchTrainerInfo = () => {
    const gymFeaturesId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/gym_features/${gymFeaturesId}`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        const { id, slug, title, text, thumb, gym } = results.data;

        this.setStateTextThumbValue({ text, thumb });
        this.setState({
          gymFeaturesId: id,
          gymFeaturesSlug: slug,
          gymFeaturesTitle: title,
          gymName: gym.name,
          gymSlug: gym.slug,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  setStateTextThumbValue = (valuesFromAPI = {}) => {
    const { localStorageKeyForText, localStorageKeyForThumb } = this.state;
    const { text, thumb } = valuesFromAPI;
    let gymFeaturesText = localStorage.getItem(localStorageKeyForText);
    let gymFeaturesThumb = localStorage.getItem(localStorageKeyForThumb);
    if (!!gymFeaturesText && !!gymFeaturesThumb) {
      return this.setState({ gymFeaturesText, gymFeaturesThumb });
    } else if (!!gymFeaturesText) {
      return this.setState({
        gymFeaturesText,
        gymFeaturesThumb: thumb,
      });
    } else if (!!gymFeaturesThumb) {
      return this.setState({
        gymFeaturesText: text,
        gymFeaturesThumb,
      });
    }
    this.setState({
      gymFeaturesText: text,
      gymFeaturesThumb: thumb,
    });
  };

  onChangeThumbFile = e => {
    this.setState({ thumbFile: e.target.files[0] });
  };
  onChangeThumb = e => {
    this.setState({ gymFeaturesThumb: e.target.value });
  };
  onChangeTextImageFile = e => {
    this.setState({ textImageFile: e.target.files[0] });
  };
  onChangeText = e => {
    const text = e.target.value;
    this.setText(text);
  };
  onChangeTitle = e => {
    const title = e.target.value;
    this.setState({ gymFeaturesTitle: title });
  };
  onChangeSlug = e => {
    const slug = e.target.value;
    this.setState({ gymFeaturesSlug: slug });
  };
  mouseOver = () => this.setState({ hover: true });
  mouseOut = () => this.setState({ hover: false });

  setText = text => {
    const { localStorageKeyForText } = this.state;
    localStorage.setItem(localStorageKeyForText, text);
    this.setState({ gymFeaturesText: text });
  };

  onImageUpload = (e, type) => {
    this.imageUpload(type);
    return e.preventDefault();
  };

  imageUpload = type => {
    let formKey, formValue;
    const { textImageFile, thumbFile } = this.state;
    switch (type) {
      case "textImage":
        formKey = "text_image";
        formValue = textImageFile;
        break;
      case "thumb":
      default:
        formKey = "thumb";
        formValue = thumbFile;
        break;
    }

    const formData = new FormData();
    formData.append(formKey, formValue);
    const config = {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${urls.api_server}/controller/gym_features/image_upload/${formKey}`, formData, config)
      .then(response => {
        this.onUploaedImage(response.data.image_url, type);
        alert("アップロードが完了しました  url: " + response.data.image_url);
      })
      .catch(error => {
        alert("アップロードに失敗しました" + error);
        console.log(error);
      });
  };

  onUploaedImage = (val, type) => {
    switch (type) {
      case "textImage":
        let { gymFeaturesText } = this.state;
        gymFeaturesText += `\n \n![画像タイトル](${val}) \n`;
        this.setText(gymFeaturesText);
        break;
      case "thumb":
      default:
        localStorage.setItem(this.state.localStorageKeyForThumb, val);
        this.setState({ gymFeaturesThumb: val });
        break;
    }
  };

  onSubmit = async e => {
    e.preventDefault();
    const { gymFeaturesSlug, gymFeaturesTitle, gymFeaturesText, gymFeaturesThumb } = this.state;

    const gymFeaturesId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/gym_features/${gymFeaturesId}`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "PATCH",
      mode: "cors",
      data: JSON.stringify({
        text: gymFeaturesText,
        thumb: gymFeaturesThumb,
        title: gymFeaturesTitle,
        slug: gymFeaturesSlug,
      }),
    })
      .then(results => {
        if (results.status == 201) {
          alert("変更が完了しました。");
          this.clearLocalStorage();
        }
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
      })
      .catch(error => {
        console.log(error);
      });
  };

  clearLocalStorage = () => {
    const { localStorageKeyForText, localStorageKeyForThumb } = this.state;
    localStorage.removeItem(localStorageKeyForText);
    localStorage.removeItem(localStorageKeyForThumb);
  };

  render() {
    const {
      gymFeaturesTitle,
      gymFeaturesText,
      gymFeaturesThumb,
      gymFeaturesSlug,
      gymSlug,
      gymName,
    } = this.state;

    const link = `https://katagirijuku.jp/gyms/${gymSlug}/${gymFeaturesSlug}`;

    return (
      <>
        <h2>
          {gymName}：{gymFeaturesTitle} の記事
        </h2>
        <div className="mt-12 flex justify-center">
          記事リンク：
          <a className="blog-link" href={link}>
            {link}
          </a>
          <br />
        </div>
        <div className="form-card">
          <form className="blog" style={{ width: "100%" }}>
            <div className="mt-4 flex">
              <label>
                タイトル
                <input
                  style={{ marginTop: 10 }}
                  className="blog-title"
                  placeholder="記事のタイトル"
                  defaultValue={gymFeaturesTitle}
                  onChange={e => this.onChangeTitle(e)}
                />
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                スラグ
                <input
                  style={{ marginTop: 10 }}
                  className="blog-title"
                  placeholder={`URL (https://katagirijuku.jp/gyms/${gymSlug}/〇〇)`}
                  defaultValue={gymFeaturesSlug}
                  onChange={e => this.onChangeSlug(e)}
                />
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                サムネイル画像
                <div
                  className="preview"
                  onMouseOver={this.mouseOver.bind(this)}
                  onMouseOut={this.mouseOut.bind(this)}
                >
                  ◆
                  {this.state.hover ? (
                    <img className="preview-image" src={(() => gymFeaturesThumb)()} />
                  ) : null}
                </div>
                <input
                  style={{ marginTop: 10 }}
                  className="blog-thumbnail"
                  placeholder="記事のサムネイル画像（https://~~）"
                  defaultValue={gymFeaturesThumb}
                  onChange={e => this.onChangeThumb(e)}
                />
              </label>
              <label className="mt-4 flex" style={{ flexDirection: "row", marginLeft: 30 }}>
                <input className="mt-4" type="file" onChange={e => this.onChangeThumbFile(e)} />
                <button
                  type="button"
                  className="btn mt-4 centering"
                  onClick={e => this.onImageUpload(e, "thumb")}
                >
                  アップロード
                </button>
                <br />
                <br />
              </label>
            </div>

            <div className="mt-4 flex" style={{ flexDirection: "row", marginTop: 20 }}>
              <label style={{ flex: 1, maxWidth: "50%" }}>
                記事本文（
                <a
                  className="blog-link"
                  href="https://media.katagirijuku.jp/health/how-to-markdown"
                >
                  書き方
                </a>
                ）<br />
                <textarea
                  // className="blog-content"
                  style={{
                    width: "100%",
                    height: 1000,
                    marginTop: 10,
                  }}
                  placeholder="記事本文（マークダウンで記述）"
                  defaultValue={gymFeaturesText}
                  onChange={this.onChangeText}
                ></textarea>
                <label className="mt-4 flex" style={{ flexDirection: "row", marginLeft: 30 }}>
                  <input
                    className="mt-4"
                    type="file"
                    onChange={e => this.onChangeTextImageFile(e)}
                  />
                  <button
                    type="button"
                    className="btn mt-4 centering"
                    onClick={e => this.onImageUpload(e, "textImage")}
                  >
                    画像を挿入
                  </button>
                  <br />
                  <br />
                </label>
              </label>
              <label style={{ flex: 1, paddingLeft: 20, maxWidth: "50%" }}>
                プレビュー
                <div
                  style={{
                    marginTop: 20,
                  }}
                  className={"markdownText"}
                  dangerouslySetInnerHTML={renderMDText(gymFeaturesText)}
                />
              </label>
            </div>
            <br />
            <div className="mt-12 flex justify-center">{this.renderSubmitButton()}</div>
          </form>
        </div>
      </>
    );
  }

  renderSubmitButton = () => {
    const { gymFeaturesText, gymFeaturesThumb, gymFeaturesTitle, gymFeaturesSlug } = this.state;
    if (!!gymFeaturesText && !!gymFeaturesThumb && !!gymFeaturesTitle && !!gymFeaturesSlug) {
      return (
        <button className="btn" type="submit" onClick={e => this.onSubmit(e)}>
          この内容で投稿する
        </button>
      );
    }

    let NoInputValue = "";
    if (!gymFeaturesTitle) NoInputValue += "記事タイトル ";
    if (!gymFeaturesSlug) NoInputValue += "スラグ ";
    if (!gymFeaturesText) NoInputValue += "記事本文 ";
    if (!gymFeaturesThumb) NoInputValue += "サムネイル画像 ";
    const alertMessage = `${NoInputValue} を入力してください`;

    return (
      <button type="button" className="btn-off" onClick={() => alert(alertMessage)}>
        この内容で投稿する
      </button>
    );
  };
}

module.exports = GymFeatureEdit;
