const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeCreateReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      plan_ticket: 0,
      omni_ticket: 0,
    };
  }

  componentDidMount() {
    const userId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/users/${userId}/tickets`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then(results => {
        if (results.status != 200) {
          alert("何らかのエラーが生じています。開発者にご連絡ください。");
          return;
        }
        this.setState({
          name: results.data.name,
          plan_ticket: results.data.plan_ticket,
          omni_ticket: results.data.omni_ticket,
        });
        return;
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { name, plan_ticket, omni_ticket } = this.state;
    return (
      <>
        <div className="w-64 mx-auto mt-8">
          <h2 className="mb-8">{name}</h2>
          <label>
            プランチケット（半角入力！）
            <input
              className="blog-slug"
              value={plan_ticket}
              onChange={e => this.onChangePlanTicket(e)}
            />
          </label>
          <label>
            オムニチケット（半角入力！）
            <input
              className="blog-slug"
              value={omni_ticket}
              onChange={e => this.onChangeOmniTicket(e)}
            />
          </label>
          <button className="btn mt-4 ml-20" onClick={e => this.onClickBtn(e)}>
            <div className="text-sm text-white">保存</div>
          </button>
        </div>
      </>
    );
  }

  onChangePlanTicket = e => {
    this.setState({ plan_ticket: e.target.value });
  };

  onChangeOmniTicket = e => {
    this.setState({ omni_ticket: e.target.value });
  };

  onClickBtn = async e => {
    e.preventDefault();
    if (window.confirm("本当に保存しますか？")) {
      const userId = this.props.match.params.id;

      axios(`${urls.api_server}/controller/users/${userId}/tickets`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        data: JSON.stringify({
          plan_ticket: this.state.plan_ticket,
          omni_ticket: this.state.omni_ticket,
        }),
      })
        .then(results => {
          if (results.status == 200) {
            alert("変更が完了しました。");
            return;
          }
          alert("変更が失敗しました。開発者にご連絡ください。");
          return;
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
}
module.exports = TraineeCreateReservation;
