const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TrainerShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      trainerId: 0,
      trainerName: "",
      trainerEmail: "",
      trainerImg: "",
      gymId: 0,
      gyms: [],
    };
  }
  componentDidMount() {
    this.fetchTrainerInfo();
    this.fetchGyms();
  }

  render() {
    const {
      value,
      trainerId,
      trainerName,
      trainerEmail,
      trainerImg,
      gymId,
      gyms,
    } = this.state;
    return (
      <>
        <div className="flex flex-row items-center justify-between mx-auto mt-20 w-3/12">
          <img
            className="rounded-full border-solid border-4 border-gray-600 w-40"
            src={trainerImg}
          />
          <h2 className="mt-0">{trainerName}</h2>
        </div>
        <div className="mx-auto pt-8 mt-8 w-6/12">
          {gyms?.map((gym) => {
            if (value == gym.id) {
              return (
                <button
                  key={gym.id}
                  className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-orange-600 rounded-lg shadow-md"
                  onClick={(e) => {
                    this.onClickBtn(e, gym.id);
                  }}
                >
                  <div className="text-lg text-white text-center mx-auto">{`${gym.name}`}</div>
                </button>
              );
            }
            return (
              <button
                key={gym.id}
                className="flex flex-row justify-between items-center w-full h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md"
                onClick={(e) => {
                  this.onClickBtn(e, gym.id);
                }}
              >
                <div className="text-lg text-gray-500 text-center mx-auto">{`${gym.name}`}</div>
              </button>
            );
          })}
          <button
            className="block mx-auto mt-12 items-center w-24 h-12 px-2 mb-4 mx-auto border-solid border-2 border-gray-500 rounded-lg"
            onClick={(e) => {
              this.onClickSendBtn(e);
            }}
          >
            <div className="text-lg text-gray-500 text-center mx-auto">
              保存
            </div>
          </button>
        </div>
      </>
    );
  }

  onClickSendBtn = (e) => {
    e.preventDefault();
    const { trainerId, value } = this.state;
    axios(`${urls.api_server}/controller/trainers/${trainerId}/change_gym`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({ new_gym_id: value }),
    })
      .then((results) => {
        if (results.status == 201) {
          alert("変更が完了しました。");
        }
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickBtn = async (e, gymId) => {
    e.preventDefault();
    this.setState({ value: gymId });
  };

  fetchTrainerInfo = () => {
    const userId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/trainers/${userId}`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        this.setState({
          value: results.data.gymId,
          trainerId: results.data.id,
          trainerName: results.data.name,
          trainerEmail: results.data.email,
          trainerImg: results.data.img,
          gymId: results.data.gymId,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchGyms = () => {
    axios(`${urls.api_server}/controller/gyms`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        this.setState({ gyms: results.data.gyms });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

module.exports = TrainerShow;
