const React = require("react");
const axios = require("axios");
const urls = require("../env");
class TraineeMenuCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: {
        body_weight: 0,
        body_fat: 0,
        muscle_weight: 0,
        offal_fat: 0,
        comment: "頑張りましたね！",
        date: "",
      },
      menus: [
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
        {
          name: "",
          number: 0,
          sets: [
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
            { weight: 0, times: 0 },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <>
        <br />
        <h2 className="centering">トレーニングメニュー作成</h2>
        <br />
        <div className="w-full centering mx-auto">
          <form className="w-2/3">
            <div className="mt-4">体重(*半角数字)</div>
            <div className="flex flex-row items-center">
              <input
                type="text"
                className="w-full"
                value={this.state.records.body_weight}
                onChange={(event) => this.onChangeRecordsBodyWeight(event)}
              />
              <div className="ml-2 font-medium">kg</div>
            </div>
            <div className="mt-4">体脂肪率(*半角数字)</div>
            <div className="flex flex-row items-center">
              <input
                type="text"
                className="w-full"
                value={this.state.records.body_fat}
                onChange={(event) => this.onChangeRecordsBodyFat(event)}
              />
              <div className="ml-2 font-medium">%</div>
            </div>
            <div className="mt-4">筋肉量(*半角数字)</div>
            <div className="flex flex-row items-center">
              <input
                type="text"
                className="w-full"
                value={this.state.records.muscle_weight}
                onChange={(event) => this.onChangeRecordsMuscleWeight(event)}
              />
              <div className="ml-2 font-medium">kg</div>
            </div>
            <div className="mt-4">内臓脂肪レベル(*半角数字)</div>
            <input
              type="text"
              className="w-full"
              value={this.state.records.offal_fat}
              onChange={(event) => this.onChangeRecordsOffalFat(event)}
            />
            {[1, 2, 3, 4, 5, 6, 7].map((num) => (
              <div className="ml-4">
                <div className="mt-8 mb-2 text-lg font-medium underline">{`◼︎ ${num}種目`}</div>
                <input
                  type="text"
                  className="w-full"
                  value={this.state.menus[num - 1].name}
                  onChange={(event) => this.onChangeMenusName(num, event)}
                />
                <div className="text-xs mt-2 mb-1">
                  （*重量kg、回数は、半角数字で入力）
                </div>
                {[1, 2, 3, 4, 5, 6].map((setNum) => (
                  <div className="">
                    <br />
                    <div className="font-medium ml-2 mb-1 text-gray-700">{`第${setNum}セット`}</div>
                    <div className="flex flex-row items-center mb-1">
                      <input
                        type="text"
                        value={
                          this.state.menus[num - 1].sets[setNum - 1].weight
                        }
                        className="w-16 mx-2"
                        onChange={(event) =>
                          this.onChangeMenusSetsWeight(num, setNum, event)
                        }
                      />
                      <div>kg</div>
                      <input
                        type="text"
                        value={this.state.menus[num - 1].sets[setNum - 1].times}
                        className="w-16 mx-2"
                        onChange={(event) =>
                          this.onChangeMenusSetsTimes(num, setNum, event)
                        }
                      />
                      <div>回</div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="mt-4">トレーナーのコメント</div>
            <textarea
              className="w-full"
              value={this.state.records.comment}
              onChange={(event) => this.onChangeRecordsComment(event)}
            ></textarea>
            <button
              className="block bg-orange-600 w-56 py-3 rounded mx-auto my-5 text-white text-center"
              onClick={(e) => this.onClickSaveBtn(e)}
            >
              保存
            </button>
          </form>
        </div>
      </>
    );
  }

  onChangeRecordsBodyWeight = async (e) => {
    let newRecord = this.state.records;
    newRecord.body_weight = e.target.value;
    this.setState({ records: newRecord });
  };

  onChangeRecordsBodyFat = async (e) => {
    let newRecord = this.state.records;
    newRecord.body_fat = e.target.value;
    this.setState({ records: newRecord });
  };

  onChangeRecordsMuscleWeight = async (e) => {
    let newRecord = this.state.records;
    newRecord.muscle_weight = e.target.value;
    this.setState({ records: newRecord });
  };

  onChangeRecordsOffalFat = async (e) => {
    let newRecord = this.state.records;
    newRecord.offal_fat = e.target.value;
    this.setState({ records: newRecord });
  };

  onChangeRecordsComment = async (e) => {
    let newRecord = this.state.records;
    newRecord.comment = e.target.value;
    this.setState({ records: newRecord });
  };

  onChangeMenusName = async (num, e) => {
    let newMenus = this.state.menus;
    newMenus[num - 1].name = e.target.value;
    this.setState({ menus: newMenus });
  };

  onChangeMenusSetsWeight = async (num, setNum, e) => {
    let newMenus = this.state.menus;
    newMenus[num - 1].sets[setNum - 1].weight = e.target.value;
    this.setState({ menus: newMenus });
  };

  onChangeMenusSetsTimes = async (num, setNum, e) => {
    let newMenus = this.state.menus;
    newMenus[num - 1].sets[setNum - 1].times = e.target.value;
    this.setState({ menus: newMenus });
  };

  onClickSaveBtn = (e) => {
    e.preventDefault();
    const trainerId = localStorage.getItem("user_id");
    const traineeId = this.props.match.params.id;
    let today = new Date();
    const m =
      String(today.getMonth() + 1).length == 1
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const d =
      String(today.getDate()).length == 1
        ? "0" + today.getDate()
        : today.getDate();
    const date = `${today.getFullYear()}-${m}-${d}`;
    console.log(this.state.records);
    console.log(this.state.menus);
    axios(
      `${urls.api_server}/controller/users/${trainerId}/training_record/${traineeId}`,
      {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        data: JSON.stringify({
          trainer_id: trainerId,
          trainee_id: traineeId,
          records: this.state.records,
          menus: this.state.menus,
          date: date,
        }),
      }
    )
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        alert("トレーニング記録の作成が完了しました。");
        return;
      })
      .catch((error) => {
        alert("トレーニング記録の作成が失敗しました。");
        console.log(error);
      });
  };
}
module.exports = TraineeMenuCreate;
