const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gyms: [],
      name: "",
      email: "",
      gym_id: 0,
    };
  }

  componentDidMount() {
    this.get_gyms();
  }

  get_gyms = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
        if (localStorage.getItem("authority") != "manager") {
          this.setState({
            gyms: [
              results.data.gyms.find(gym => gym.id == Number(localStorage.getItem("user_gym_ids"))),
            ],
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderSearchForm = () => {
    // if (localStorage.getItem("authority") != "manager") return null;

    let { gyms } = this.state;

    return (
      <div className="centering">
        <label>
          店舗
          <select
            className="select-btn"
            onChange={async event => {
              const selectedIndex = event.target.options.selectedIndex;
              console.log(event.target.options[selectedIndex].getAttribute("data-key"));
              await this.setState({
                gym_id: event.target.options[selectedIndex].getAttribute("data-key"),
              });
            }}
          >
            <option className="center-text">※ 所属店舗を選択</option>
            {gyms.map(gym => (
              <option key={gym.id} data-key={gym.id}>
                {gym.name}
              </option>
            ))}
          </select>
        </label>
      </div>
    );
  };

  render() {
    return (
      <>
        <h2>ユーザー登録 {this.state.gym_id}</h2>
        <div className="form-card">
          <form>
            <div className="mt-4">
              <label>
                名前
                <input
                  placeholder="片桐太郎"
                  value={this.state.name}
                  onChange={this.onChangeName}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                メールアドレス
                <input
                  placeholder="test@example.com"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                />
              </label>
            </div>
            {this.renderSearchForm()}
            <div className="mt-12 flex justify-center">
              <button className="btn" onClick={e => this.onClickBtn(e)}>
                新規追加
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  onClickBtn = e => {
    e.preventDefault();
    const { name, email, gym_id } = this.state;
    if (name == "") {
      alert("名前を入力してください");
      return;
    }
    if (gym_id == 0) {
      alert("所属店舗を選択してください");
      return;
    }
    const regex = new RegExp(
      /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/
    );
    if (!regex.test(email)) {
      alert("使用できない文字が含まれているため登録できません");
      return;
    }
    axios(`${urls.api_server}/controller/trainee`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({
        name: name,
        email: email,
        gym_id: gym_id,
      }),
    })
      .then(results => {
        if (results.status == 201) {
          alert("塾生を登録しました。");
          window.location.href = "/records";
          return;
        }
        alert("塾生を登録できませんでした。少し経ってから再度お試しください。");
        return;
      })
      .catch(error => {
        if (error.response.status == 405) {
          alert("このメールアドレスはすでに使われています。");
          return;
        }
        console.log(error);
      });
  };

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };
  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };
}

module.exports = TraineeAdd;
