const React = require("react");
const axios = require("axios");
const urls = require("../env");
const Button = require("../components/button");
const { Link } = require("react-router-dom");

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gyms: [],
      hpData: {},
      ownerGymIDs: [],
    };
  }
  async componentDidMount() {
    await this.get_gym_ids_of_owner();
    await this.get_gyms();
    await this.get_hp_news();
  }
  render() {
    let { hpData, gyms } = this.state;
    return (
      <>
        {(() => {
          if (localStorage.getItem("authority") === "manager") {
            return (
              <>
                <h2 className="">ホームページ情報の編集</h2>
                <table className="w-2/3">
                  <tbody>
                    <tr>
                      <td>News テキスト</td>
                      <td>{hpData.news_text}</td>
                    </tr>
                    <tr>
                      <td>News リンク</td>
                      <td>{hpData.news_link}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <Link
                  to={{
                    pathname: `/hp_edit`,
                  }}
                >
                  <Button name="編集する" />
                </Link>
                <h2 className="mb-10">ジムの新規追加</h2>
                <Link
                  to={{
                    pathname: `/gym_new`,
                  }}
                >
                  <Button name="追加する" />
                </Link>
              </>
            );
          }
        })()}
        {(() => {
          if (
            localStorage.getItem("authority") === "manager" ||
            localStorage.getItem("authority") === "owner"
          ) {
            return (
              <>
                <h2 className="mt-12">ジムの一覧</h2>
                <table className="w-2/3">
                  <thead>
                    <tr>
                      <th>{"ID"}</th>
                      <th>{"店舗名"}</th>
                      <th>{"HP表示状態"}</th>
                      <th>{"郵便番号"}</th>
                      <th>{"住所"}</th>
                      <th>{""}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gyms.map(gym => (
                      <tr key={gym.id}>
                        <td>{gym.id}</td>
                        <td>{gym.name}</td>
                        <td>
                          {gym.hp_visible ? (
                            <div className="mt-4 flex justify-center">
                              <div className="btn-on">表示中</div>
                            </div>
                          ) : (
                            <div className="mt-4 flex justify-center">
                              <div className="btn-off">非表示</div>
                            </div>
                          )}
                        </td>
                        <td>{gym.post_number}</td>
                        <td>{`${gym.address_one} ${gym.address_two}`}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/gym_show/${gym.id}`,
                            }}
                          >
                            <Button name="編集する" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            );
          }
        })()}
      </>
    );
  }
  get_gym_ids_of_owner = () => {
    const user_id = localStorage.getItem("user_id");
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/controller/owners/${user_id}/gym_ids`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status != 200) {
          return;
        }
        this.setState({ ownerGymIDs: results.data.gymIds });
        return;
      })
      .catch(error => {
        return;
      });
  };

  get_gyms = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(async results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
        if (localStorage.getItem("authority") == "owner") {
          // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          // オーナーの場合に、そのオーナーのジムIDsを取得、そのIDsのジムだけをstateに入れる。
          const user_id = localStorage.getItem("user_id");
          axios({
            auth: {
              username: urls.basic_user,
              password: urls.basic_pass,
            },
            method: "GET",
            url: `${urls.api_server}/controller/owners/${user_id}/gym_ids`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then(results => {
              if (results.status != 200) {
                return;
              }
              const ownerGymIds = results.data.gymIds;
              const gyms = this.state.gyms;

              let ownerGyms = [];
              ownerGymIds.map(ownerGymId => {
                const gym = gyms.filter(g => g.id == ownerGymId);
                ownerGyms.push(gym[0]);
                return;
              });

              this.setState({ gyms: ownerGyms });
              return;
            })
            .catch(error => {
              return;
            });
          // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  get_hp_news = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/api/hp_news`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        const hp_news = results.data?.home_page;
        this.setState({
          hpData: hp_news,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}

module.exports = Settings;
