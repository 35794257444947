const React = require("react");
const axios = require("axios");
const urls = require("../env");
const { Redirect } = require('react-router-dom');


class BlogAdd extends React.Component {
  constructor (props) {
    const now = new Date();
    super(props);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.mouseOver2 = this.mouseOver2.bind(this);
    this.mouseOut2 = this.mouseOut2.bind(this);
    this.mouseOver3 = this.mouseOver3.bind(this);
    this.mouseOut3 = this.mouseOut3.bind(this);
    if (props.location.aboutProps && !localStorage.getItem('new/title')) {
      if (localStorage.getItem('new')) {
        this.state = {
          redirect: true
        }
      } else {
        localStorage.setItem('new',"new");
        localStorage.setItem('new/published', 1);
        localStorage.setItem('new/unlisted', 0);
        this.state = {
          title: "",
          description: "",
          slug: "",
          category: "-",
          author: localStorage.getItem("user_id"),
          published: 1,
          unlisted: 0,
          post_date: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
          edit_date: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
          thumbnail: "https://i.gyazo.com/151bd11c97ce40ed1eccf960c0d1e5ca.png",
          sns_card: "https://i.gyazo.com/2cc4ee09805a9186add78a2b0e01b79c.png",
          content: "markdown",
          banner: "https://media.katagirijuku.jp/blog-image/banner-covid19.png",
          banner_link: "",
          tags: "",
          title_count: 0,
          description_count: 0,
          redirect: false,
          slugs: props.location.aboutProps.slugs,
          slug_check: "スラグは入力必須です",
          hover: false,
          hover2: false,
          hover3: false,
          reload: false
        }
        console.log(this.state.slugs);
        localStorage.setItem('new/slugs', this.state.slugs);
      }
    } else {
      let title_length = localStorage.getItem('new/title') ? localStorage.getItem('new/title').length : 0;
      let description_length = localStorage.getItem('new/description') ? localStorage.getItem('new/description').length : 0;
      let slug_check_text = localStorage.getItem('new/slugs').replace("[","").replace("]","").replace(/\"/g,"").split(",").indexOf(localStorage.getItem('new/slug')) == -1 ? "使用可能なスラグです" : "このスラグは使用できません"
      slug_check_text = (localStorage.getItem('new/slug') == (""||null)) ? "スラグは入力必須です" : slug_check_text
      this.state = {
        title: localStorage.getItem('new/title'),
        description: localStorage.getItem('new/description'),
        slug: localStorage.getItem('new/slug'),
        category: localStorage.getItem('new/category'),
        author: 1,
        published: Number(localStorage.getItem('new/published')),
        unlisted: Number(localStorage.getItem('new/unlisted')),
        post_date: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
        edit_date: `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`,
        thumbnail: localStorage.getItem('new/thumbnail'),
        sns_card: localStorage.getItem('new/sns_card'),
        content: localStorage.getItem('new/content'),
        banner: localStorage.getItem('new/banner'),
        banner_link: localStorage.getItem('new/banner_link'),
        tags: localStorage.getItem('new/tags'),
        title_count: title_length,
        description_count: description_length,
        redirect: false,
        slugs: localStorage.getItem('new/slugs'),
        slug_check: slug_check_text,
        hover: false,
        hover2: false,
        hover3: false,
        reload: false
      }
    }
  }

  renderRedirect = () => {
    if (this.state.reload) {
      // window.location.reload();
    }
    if (this.state.redirect) {
      localStorage.removeItem('new')
      return <Redirect to='/blogs' />
    }
  }

  render() {
    // if (localStorage.getItem("new/slugs")!=null) this.setState({reload: true});
    return (
      <>
        {this.renderRedirect()}
        <h2>記事の投稿</h2>
        <div className="form-card">
          <form className="blog">
            <div className="mt-4 flex">
              <label>
                タイトル（文字数：{this.state.title_count}/34）
                <input
                  className="blog-title"
                  placeholder="記事のタイトル"
                  defaultValue={this.state.title}
                  onChange={this.onChangeTitle}
                  onKeyUp={e => this.handleCountChangeTitle(this.state.title, e.target.value.length)}
                />
              </label>
              　
              <label>
                カテゴリ（<span className="slug-check">{(()=>((this.state.category=="-")||(this.state.category==null))?"選択してください":"OK!")()}</span>）
                <select
                  className="blog-category blog-select-btn"
                  defaultValue={this.state.category}
                  onChange={this.onChangeCategory}
                >
                  <option value="-">-</option>
                  <option value="workout">workout</option>
                  <option value="diet">diet</option>
                  <option value="personal-training">personal-training</option>
                  <option value="stretch">stretch</option>
                  <option value="health">health</option>
                </select>
              </label>
              　
              <label>
                スラグ（<span className="slug-check">{this.state.slug_check}</span>）
                <input
                  className="blog-slug"
                  placeholder="記事のパス（URL）"
                  defaultValue={this.state.slug}
                  onChange={this.onChangeSlug}
                  onKeyUp={() => this.handleCountChangeSlug(this.state.slug, this.state.slugs)}
                />
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                タグ（<span className="slug-check">{String(this.state.tags).split(",").map(tag=>` #${tag} `)}</span>）
                <input
                  className="blog-tags"
                  placeholder="記事につけるタグ（カンマ区切り｜例：塾長の解説,綺麗なダイエット）"
                  defaultValue={this.state.tags}
                  onChange={this.onChangeTags}
                  onKeyUp={this.handleCountChangeTags(this.state.tags)}
                />
              </label>
              　
              <label>
                投稿日
                <input
                  className="blog-post-date"
                  placeholder="投稿日"
                  defaultValue={this.state.post_date}
                  onChange={this.onChangePostDate}
                />
              </label>
              　
              <label>
                更新日
                <input
                  className="blog-edit-date"
                  placeholder="更新日"
                  defaultValue={this.state.edit_date}
                  onChange={this.onChangeEditDate}
                />
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                サムネイル画像　<div className="preview" onMouseOver={this.mouseOver.bind(this)} onMouseOut={this.mouseOut.bind(this)}>◆{this.state.hover ? (<img className="preview-image" src={(()=>this.state.thumbnail)()}/>) : null}</div>
                <input
                  className="blog-thumbnail"
                  placeholder="記事のサムネイル画像（https://~~）"
                  defaultValue={this.state.thumbnail}
                  onChange={this.onChangeThumbnail}
                  onKeyUp={this.handleCountChangeThumbnail(this.state.thumbnail)}
                />
              </label>
              　
              <label>
                SNSシェア画像　<div className="preview" onMouseOver={this.mouseOver2.bind(this)} onMouseOut={this.mouseOut2.bind(this)}>◆{this.state.hover2 ? (<img className="preview-image" src={(()=>this.state.sns_card)()}/>) : null}</div>
                <input
                  className="blog-sns-card"
                  placeholder="SNSにシェアした時の画像（https://~~）"
                  defaultValue={this.state.sns_card}
                  onChange={this.onChangeSNSCard}
                  onKeyUp={this.handleCountChangeSnsCard(this.state.sns_card)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                サマリー（文字数：{this.state.description_count}/130）
                <input
                  className="blog-description"
                  placeholder="記事の説明（SEO意識）"
                  defaultValue={this.state.description}
                  onChange={this.onChangeDescription}
                  onKeyUp={e => this.handleCountChangeDescription(this.state.description, e.target.value.length)}
                />
              </label>
            </div>
            <div className="mt-4">
              <label>
                記事の内容（<a className="blog-link" href="https://media.katagirijuku.jp/health/how-to-markdown">書き方</a>）<br/>
                <textarea
                  className="blog-content"
                  placeholder="記事本文（マークダウンで記述）"
                  defaultValue={this.state.content}
                  onChange={this.onChangeContent}
                  onKeyUp={this.handleCountChangeContent(this.state.content)}
                ></textarea>
              </label>
            </div>
            <div className="mt-4 flex">
              <label>
                バナー画像　<div className="preview" onMouseOver={this.mouseOver3.bind(this)} onMouseOut={this.mouseOut3.bind(this)}>◆{this.state.hover3 ? (<img className="preview-image" src={(()=>this.state.banner)()}/>) : null}</div>
                <input
                  className="blog-banner"
                  placeholder="バナー画像（https://~~）"
                  defaultValue={this.state.banner}
                  onChange={this.onChangeBanner}
                  onKeyUp={this.handleCountChangeBanner(this.state.banner)}
                />
              </label>
              　
              <label>
                バナー遷移先ページ
                <input
                  className="blog-banner-link"
                  placeholder="バナーをクリックした時の飛び先（https://~~）"
                  defaultValue={this.state.banner_link}
                  onChange={this.onChangeBannerLink}
                  onKeyUp={this.handleCountChangeBannerLink(this.state.banner_link)}
                />
              </label>
            </div>
            <br />
            <div className="mt-4 flex">
              { (() => {
                if (this.state.published) {
                  return (
                    <button className="btn-on blog-publish" onClick={ e => this.onClickReversePublish(e) }>
                      非公開状態に変更する（現在は「公開」で設定中です）
                    </button>
                  )
                } else {
                  return (
                    <button className="btn-off blog-publish" onClick={ e => this.onClickReversePublish(e) }>
                      公開状態に変更する（現在は「非公開」で設定中です）
                    </button>
                  )
                }
              })()}
              　
              {(() => {
                if (this.state.unlisted) {
                  return (
                    <button className="btn-on blog-unlist" onClick={ e => this.onClickReverseUnlist(e) }>
                      一般公開に変更する（現在ブログに掲載されない設定です）
                    </button>
                  )
                } else {
                  return (
                    <button className="btn-off blog-unlist" onClick={ e => this.onClickReverseUnlist(e) }>
                      限定公開に変更する（現在ブログに掲載される設定です）
                    </button>
                  )
                }
              })()}
            </div>
            {(() => {
              if (this.state.title!="" && this.state.title!=null && this.state.description!="" && this.state.content!="" && this.state.slug_check=="使用可能なスラグです" && this.state.category!="-" && this.state.category!=null && this.state.slug!=null) {
                return (
                  <div className="mt-12 flex justify-center">
                    <button className="btn" onClick={ e => this.onClickBtn(e) }>
                      この内容で投稿する
                    </button>
                  </div>
                )
              }})()
            }
          </form>
        </div>
      </>
    );
  }

  checkAvailableSlug = (slug, slugs) => {
    slug==""
    ? this.setState({ slug_check: "スラグは入力必須です"})
    : this.setState({ slug_check: String(slugs).replace("[","").replace("]","").replace(/\"/g,"").split(",").indexOf(slug) == -1 ? "使用可能なスラグです" : "このスラグは使用できません"});
  }

  handleCountChangeTitle = (text, length) => {
    this.setState({title_count: length});
    localStorage.setItem('new/title', text);
  }
  handleCountChangeDescription = (text, length) => {
    this.setState({description_count: length});
    localStorage.setItem('new/description', text);
  }
  handleCountChangeSlug = (slug, slugs) => {
    localStorage.setItem('new/slug', slug);
    this.checkAvailableSlug(slug, slugs)
  }
  handleCountChangeTags = text => localStorage.setItem('new/tags', text);
  handleCountChangeContent = text => localStorage.setItem('new/content', text);
  handleCountChangeThumbnail = text => localStorage.setItem('new/thumbnail', text);
  handleCountChangeSnsCard = text => localStorage.setItem('new/sns_card', text);
  handleCountChangeBanner = text => localStorage.setItem('new/banner', text);
  handleCountChangeBannerLink = text => localStorage.setItem('new/banner_link', text);

  mouseOver = () => this.setState({hover: true});
  mouseOut = () => this.setState({hover: false});
  mouseOver2 = () => this.setState({hover2: true});
  mouseOut2 = () => this.setState({hover2: false});
  mouseOver3 = () => this.setState({hover3: true});
  mouseOut3 = () => this.setState({hover3: false});

  onClickBtn = async e => {
    e.preventDefault();
    const { title, category, slug, tags, post_date, edit_date, thumbnail, sns_card, description, content, banner, banner_link, published, unlisted, author } = this.state;
    this.clearLocalStorage();
    if (title == "") return;
    axios(`${urls.api_server}/api/blogs/new`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      data: JSON.stringify({ title, category, slug, tags, post_date, edit_date, thumbnail, sns_card, description, content, banner, banner_link, published, unlisted, author }),
    }).then(results=>{
      if (results.data.status==201) this.setState({ redirect: true });
    });
  }

  onClickReversePublish = async e => {
    e.preventDefault();
    let published_update = this.state.published ? 0 : 1;
    await this.setState({ published: published_update });
    localStorage.setItem('new/published', this.state.published);
  }

  onClickReverseUnlist = async e => {
    e.preventDefault();
    let unlisted_update = this.state.unlisted ? 0 : 1;
    await this.setState({ unlisted: unlisted_update });
    localStorage.setItem('new/unlisted', this.state.unlisted);
  }

  onChangeTitle = e => this.setState({ title: e.target.value });
  onChangeCategory = e => {
    this.setState({ category: e.target.value });
    localStorage.setItem('new/category', e.target.value);
  }
  onChangeSlug = e => this.setState({ slug: e.target.value });
  onChangeDescription = e => this.setState({ description: e.target.value });
  onChangeTags = e => this.setState({ tags: e.target.value });
  // onChangePostDate = e => this.setState({ post_date: e.target.value });
  // onChangeEditDate = e => this.setState({ edit_date: e.target.value });
  onChangeThumbnail = e => this.setState({ thumbnail: e.target.value });
  onChangeSNSCard = e => this.setState({ sns_card: e.target.value });
  onChangeBanner = e => this.setState({ banner: e.target.value });
  onChangeBannerLink = e => this.setState({ banner_link: e.target.value });
  onChangeContent = e => this.setState({ content: e.target.value });

  clearLocalStorage = () => {
    localStorage.removeItem('new/title')
    localStorage.removeItem('new/description')
    localStorage.removeItem('new/slug')
    localStorage.removeItem('new/slugs')
    localStorage.removeItem('new/category')
    localStorage.removeItem('new/published')
    localStorage.removeItem('new/unlisted')
    localStorage.removeItem('new/post_date')
    localStorage.removeItem('new/thumbnail')
    localStorage.removeItem('new/sns_card')
    localStorage.removeItem('new/content')
    localStorage.removeItem('new/banner')
    localStorage.removeItem('new/banner_link')
    localStorage.removeItem('new/tags')
  }
}

module.exports = BlogAdd;
