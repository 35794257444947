const React = require("react");
const axios = require("axios");
const urls = require("../env");

const returnModifiedDate = date => {
  if (date == "") return "";
  const dates = date.split("-");
  return `${dates[0]}年${dates[1]}月${dates[2]}日`;
};

class TraineeMenuShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: {
        id: 1,
        date: "2000/01/01",
        body_weight: 0,
        body_fat: 0,
        muscle_weight: 0,
        offal_fat: 0,
        message: "",
        training_menus: [
          {
            name: "",
            order: 1,
            sets: [{ weight: 0, times: 0 }]
          },
          {
            name: "",
            order: 2,
            sets: [{ weight: 0, times: 0 }]
          }
        ]
      }
    };
  }

  componentDidMount() {
    this.fetchMenuDetail();
  }

  render() {
    const menu = this.state.menu;
    return (
      <>
        <div className="w-64 mx-auto mt-8">
          <h2>{returnModifiedDate(menu.date)}</h2><br/>
          <br/>
          <form>
          <div className="ml-2 mt-8 mb-8">
            <div>{`体重：${menu.body_weight}kg`}</div>
            <div>{`体脂肪率：${menu.body_fat}%`}</div>
            <div>{`筋肉量：${menu.muscle_weight}kg`}</div>
            <div>{`内臓脂肪レベル：${menu.offal_fat}`}</div>
          </div>
          {menu.training_menus.map(trainingMenu => (
            <div className="mt-6">
              <div className="font-bold underline text-sm text-center mb-2">
                {trainingMenu.name}
              </div>
              {trainingMenu.sets.map(set => {
                if (parseInt(set.weight)*parseInt(set.times)!=0)
                return (
                  <div className="flex flex-row justify-between mx-4">
                    <div>{`重量：${set.weight}kg`}</div>
                    <div>{`回数：${set.times}回`}</div>
                  </div>
                );
              })}
            </div>
          ))}
          <div className="mt-12">
          <div className="font-bold underline text-sm text-center mb-2">
            コメント
          </div>
          {menu.message}</div>
        </form>
        </div>
      </>
    );
  }

  fetchMenuDetail = async () => {
    axios(
      `${urls.api_server}/api/training_records/${this.props.match.params.menuId}`,
      {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        method: "GET"
      }
    ).then((results) => {
      if (results.status === 404) {
        console.log("404");
        return;
      }
      this.setState({ menu: results.data.training_record });
      console.log(this.state.menu)
      return;
    })
    .catch((error) => {
      console.log(error);
    });
  };
}
module.exports = TraineeMenuShow;
