const React = require("react");
const axios = require("axios");
const urls = require("../env");

class TraineeQuitSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
    };
  }

  componentDidMount() {
    this.fetchSubscriptions();
  }

  render() {
    return (
      <>
        <h2>月額登録を削除</h2>
        <div className="w-64 mx-auto mt-8">
          {this.state.subscriptions?.map((subscription) => {
            return (
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-between items-center w-32 h-12 px-2 mb-4 bg-gray-300 rounded-lg shadow-md">
                  <div className="mx-auto">{`${subscription.item.name}`}</div>
                </div>
                <button
                  className="bg-orange-600 w-20 p-3 mb-4 rounded mx-auto text-white text-center"
                  onClick={() =>
                    this.onClickDeleteSubscription(subscription.id)
                  }
                >
                  <div className="text-white">削除</div>
                </button>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  fetchSubscriptions = () => {
    const userId = this.props.match.params.id;
    axios(`${urls.api_server}/controller/users/${userId}/subscriptions`, {
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        this.setState({ subscriptions: results.data.subscriptions });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickDeleteSubscription = async (id) => {
    if (window.confirm("本当に削除しますか？")) {
      axios(`${urls.api_server}/controller/subscriptions/${id}/delete`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "GET",
      })
        .then((results) => {
          if (results.status === 404) {
            console.log("404");
            return;
          }
          console.log(results);
          alert("削除が完了しました。");
          return;
        })
        .catch((error) => {
          alert("削除が失敗しました。");
          console.log(error);
        });
    }
  };
}
module.exports = TraineeQuitSubscription;
