const React = require("react");
const envs = require("../env");
const axios = require("axios");
const { Link } = require("react-router-dom");
const Button = require("../components/button");

class ECSalesShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
    };
  }
  componentDidMount() {
    this.getSales();
  }
  render() {
    const { year, month } = this.props.location.aboutProps;
    const { payments } = this.state;
    return (
      <>
        <h2>{`${year}年 ${month}月の売上`}</h2>
        <table className="w-2/3">
          <thead>
            <tr>
              <th>{"商品（個数）"}</th>
              <th>{"金額（税込）"}</th>
              <th>{"決済の成否"}</th>
              <th>{"ユーザーID/名前"}</th>
              <th>{"日付"}</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((p) => (
              <tr>
                <td className="text-center">{`${p.ec_item.name}（${p.count}）`}</td>
                <td className="text-center">{`${Number(
                  p.ec_item.price + p.ec_item.price / 10
                ).toLocaleString()}`}</td>
                <td className="text-center">{`${p.status}`}</td>
                <td className="text-center">{`${p.user.id} / ${p.user.name}`}</td>
                <td className="text-center">{`${
                  p.createdAt.split("T")[0]
                }`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  getSales = () => {
    const { year, month } = this.props.location.aboutProps;
    axios(`${envs.api_server}/ec/payments_month`, {
      auth: {
        username: envs.basic_user,
        password: envs.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      data: JSON.stringify({ year, month }),
    })
      .then((results) => {
        if (results.status != 200) {
          alert("何らかのトラブルが発生しています。");
          return;
        }
        this.setState({ payments: results.data.payments });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

module.exports = ECSalesShow;
