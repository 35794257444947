const React = require("react");
const axios = require("axios");
const urls = require("../env");
class TraineeCreateReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: 0,
      time: 0,
      plan_ticket: 0,
      omni_ticket: 0,
    };
  }

  componentDidMount() {
    this.getTickets();
  }

  render() {
    return <>{this.renderContent()}</>;
  }

  getTickets = () => {
    const userId = this.props.match.params.id;
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/controller/users/${userId}/tickets`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(results => {
        this.setState({
          plan_ticket: results.data.plan_ticket,
          omni_ticket: results.data.omni_ticket,
        });
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderContent = () => {
    if (this.state.plan_ticket == 0 && this.state.omni_ticket == 0) {
      return this.renderAlertMessage();
    } else {
      return this.renderReservationForm();
    }
  };

  renderAlertMessage = () => {
    return (
      <div className="w-84 mx-auto mt-8">
        <p>チケットが不足しているため予約できません。</p>
        <div class="m-3 text-center">
          <button
            class="px-3 py-2 bg-orange-500 text-white rounded hover:bg--600"
            onClick={() => this.props.history.goBack()}
          >
            戻る
          </button>
        </div>
      </div>
    );
  };

  renderReservationForm = () => {
    const { year, month, day, hour } = this.state;
    const reservationTimes = this.ReservableTime();
    return (
      <div className="w-64 mx-auto mt-8">
        <div className="mt-4">
          予約日時
          <label>
            <input
              class="w-full px-2 py-1 h-10 text-gray-700 bg-gray-100 rounded mb-3 border-2"
              type="date"
              defaultValue={this.state.time}
              onChange={e => this.onChangeDate(e)}
            ></input>
          </label>
        </div>
        <div className="mt-4">
          時間
          <label>
            <select
              class="w-full px-2 py-1 h-10 text-gray-700 bg-gray-100 rounded mb-3 border-2"
              defaultValue={this.state.time}
              onChange={e => this.onChangeTime(e)}
            >
              {reservationTimes.map(reservation => (
                <option value={reservation.value}>{reservation.display}</option>
              ))}
            </select>
          </label>
        </div>
        <button className="btn mt-4 ml-20" onClick={e => this.onClickBtn(e)}>
          <div className="text-sm text-white">予約を作成</div>
        </button>
      </div>
    );
  };

  ReservableTime = () => {
    const reservationTimes = [
      { value: 7, display: "07:00" },
      { value: 8, display: "08:00" },
      { value: 9, display: "09:00" },
      { value: 10, display: "10:00" },
      { value: 11, display: "11:00" },
      { value: 12, display: "12:00" },
      { value: 13, display: "13:00" },
      { value: 14, display: "14:00" },
      { value: 15, display: "15:00" },
      { value: 16, display: "16:00" },
      { value: 17, display: "17:00" },
      { value: 18, display: "18:00" },
      { value: 19, display: "19:00" },
      { value: 20, display: "20:00" },
      { value: 21, display: "21:00" },
      { value: 22, display: "22:00" },
      { value: 23, display: "23:00" },
    ];
    return reservationTimes;
  };

  onChangeDate = e => {
    this.setState({ date: e.target.value });
  };
  onChangeTime = e => {
    this.setState({ time: e.target.value });
  };

  onClickBtn = async e => {
    e.preventDefault();
    if (window.confirm("本当に予約を作成しますか？")) {
      const userId = this.props.match.params.id;
      let { date, time } = this.state;
      if (date == "") {
        return alert("予約日時を入力してください");
      }
      if (time == "") {
        return alert("予約時間を入力してください");
      }
      date = date.replace(/-/g, "/");

      axios(`${urls.api_server}/controller/user/${userId}/reservation_new`, {
        auth: {
          username: urls.basic_user,
          password: urls.basic_pass,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        data: JSON.stringify({
          date: date,
          time: time,
        }),
      })
        .then(results => {
          console.log(results);
          if (results.status == 201) {
            alert(
              `${results.data.newReservation.date}の${results.data.newReservation.time}時~ 予約を完了しました。`
            );
            return;
          }
          return;
        })
        .catch(error => {
          if (error.response.status == 402) {
            return alert("すでにお客様の予約が入っています。");
          } else if (error.response.status == 404) {
            return alert("チケットが不足しています。");
          } else if (error.response.status == 405) {
            return alert("営業時間外です。");
          } else if (
            error.response.status == 406 ||
            error.response.status == 407 ||
            error.response.status == 408
          ) {
            return alert("この時間は予約ができません。");
          } else
            return alert(
              "予約が失敗しました。もう一度お試しいただきうまくいかなければ、開発者にご連絡ください"
            );
        });
    }
  };
}
module.exports = TraineeCreateReservation;
