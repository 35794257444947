const React = require("react");
const axios = require("axios");
const envs = require("../env");
const { Link } = require("react-router-dom");
const Button = require("../components/button");

class ECUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  async componentDidMount() {
    await this.get_ec_users();
  }

  render() {
    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% XX2");
    console.log(this.state.users);

    return (
      <>
        <h2 className="mb-10">ECのユーザー一覧</h2>
        <table>
          <thead>
            <tr>
              <th>{"氏名"}</th>
              <th>{"メールアドレス"}</th>
              <th>{"〒"}</th>
              <th>{"住所"}</th>
              <th>{"定期"}</th>
              {/* <th>{"LTV"}</th> */}
            </tr>
          </thead>
          <tbody>
            {this.state.users.map((u) => (
              <tr>
                <td>{u.name}</td>
                <td>{u.email}</td>
                <td>{u.postal_code}</td>
                <td>{u.prefecture + " " + u.address}</td>
                <td>
                  {u.ec_subscriptions.map((s) => (
                    <p>{s.ec_item.name + " (" + s.number + ")"}</p>
                  ))}
                </td>
                {/* <td>
                  {}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  get_ec_users = async () => {
    axios(`${envs.api_server}/ec/users`, {
      auth: {
        username: envs.basic_user,
        password: envs.basic_pass,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
    })
      .then((results) => {
        if (results.status != 200) {
          console.log("not 200");
          alert("何らかのエラーが生じました。");
          return;
        }
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% XX1");
        console.log(results.data.users);
        this.setState({ users: results.data.users });
      })
      .catch((error) => {
        console.log(error);
        alert("何らかのエラーが生じました。");
      });
  };
}

module.exports = ECUsers;
