require("react-data-grid/dist/react-data-grid.css");
const React = require("react");
const axios = require("axios");
const urls = require("../env");
const ReactDataGrid = require("react-data-grid");
const Select = require("react-select");
const { Link } = require("react-router-dom");
const Button = require("../components/button");
const Modal = require("react-modal");
const Paper = require('@material-ui/core/Paper');
const { Grid, Table, TableHeaderRow } = require('@devexpress/dx-react-grid-material-ui');

const columns = [
  { name: 'id', title: 'ID' },
  { name: 'product', title: 'Product' },
  { name: 'owner', title: 'Owner' },
];
const rows = [
  { id: 0, product: 'DevExtreme', owner: 'DevExpress' },
  { id: 1, product: 'DevExtreme Reactive', owner: 'DevExpress' },
];
class Management extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gyms: [],
      gyms_option: [
				{ value: 6, label: "武蔵小山本店" },
				{ value: 7, label: "西荻窪店" },
				{ value: 8, label: "武蔵小山ANNEX店" },
			],
      hpData: {},
      columns: [
        {
					key: "day1",
					name: `カラム１`,
					width: 220,
				},
				{
					key: "day2",
					name: `カラム２`,
					width: 220,
				},
      ],
      rows: [
        {
					time: "9時〜",
					day1: "×",
					day2: "×",
					isScrolling: false,
				},
      ],
      modal_form_visible: false
    };
  }
  componentDidMount() {
    this.get_gyms();
    this.get_hp_news();
  }
  openModal = (e) => {
		this.setState({ modal_form_visible: true });
	};
  closeModal = () => {
		this.setState({ modal_form_visible: false });
  };
  reserveTrial = async (e) => {
		e.preventDefault();
	};
  render() {
    Modal.setAppElement("#root");
    const customStyles = {
			content: {
				top: "60%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				width: "80%",
				height: "100%",
				transform: "translate(-50%, -50%)",
			},
		};
    return (
      <>
        {(() => {
          if (
            localStorage.getItem("authority") === "manager"
          ) {
            return (
              <Paper>
                <Grid
                  rows={rows}
                  columns={columns}
                >
                  <Table />
                  <TableHeaderRow />
                </Grid>
              </Paper>
            );
          }
        })()}
      </>
    );
  }
  get_gyms = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "POST",
      url: `${urls.api_server}/controller/gyms`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        this.setState({ gyms: results.data.gyms });
        if (localStorage.getItem("authority") == "owner") {
          // 赤堀さんの特別設定条件if↓
          if (localStorage.getItem("user_id") == "249") {
            this.setState({
              gyms: this.state.gyms.filter(
                (gym) => gym.id == 13 || gym.id == 14
              ),
            });
            return;
          }

          this.setState({
            gyms: [
              this.state.gyms.find(
                (gym) => gym.id == Number(localStorage.getItem("user_gym_ids"))
              ),
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get_hp_news = () => {
    axios({
      auth: {
        username: urls.basic_user,
        password: urls.basic_pass,
      },
      method: "GET",
      url: `${urls.api_server}/api/hp_news`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((results) => {
        if (results.status === 404) {
          console.log("404");
          return;
        }
        console.log(results);
        const hp_news = results.data?.home_page;
        this.setState({
          hpData: hp_news,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

module.exports = Management;